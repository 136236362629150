import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { NEW_CONSULT_STORAGE_KEY } from '../../constants/consultConstants.js';
import { replaceNewConsult } from '../../redux/ducks/new-consult/newConsult.js';
import NewConsultPage from './NewConsultPage.js';
import NewPaymentPage from '../newpaymentpage/NewPaymentPage.js';
import { Title, MetaDescription } from '../common/Head/Head.js';
import Loader from '../common/loader/Loader.js';
import LoginModalContainer from '../common/login-modal/LoginModalContainer.js';

function NewConsultHistory({ routeId, location }) {
  const dispatch = useDispatch();
  const [isHistoryLoaded, setHistoryLoaded] = useState(false);
  const { isLoginStatusObtained } = useSelector(
    state => ({
      isLoginStatusObtained: state.user.isLoggedIn !== null,
    }),
    shallowEqual
  );

  useEffect(() => {
    const newConsultHistory = JSON.parse(
      sessionStorage.getItem(NEW_CONSULT_STORAGE_KEY)
    );

    if (newConsultHistory) {
      dispatch(replaceNewConsult(newConsultHistory.newConsult));
    }

    setHistoryLoaded(true);
  }, [dispatch]);

  if (!isHistoryLoaded) {
    return null;
  }

  let Component;
  switch (routeId) {
    case 'new-consultation':
    case 'basic-details':
    case 'basic-details-alias':
      Component = NewConsultPage;
      break;
    case 'new-payment':
      Component = NewPaymentPage;
      break;
  }

  return (
    <Fragment>
      <Title>
        Ask a Doctor Online and Get Answers 24X7 | Ask a Doctor Free | Practo
      </Title>
      <MetaDescription>
        Consult privately with high quality doctors. Get an opinion within 1
        hour
      </MetaDescription>
      <LoginModalContainer />
      {isLoginStatusObtained ? (
        <Component location={location} routeId={routeId} />
      ) : (
        <Loader type="overlay" />
      )}
    </Fragment>
  );
}

NewConsultHistory.propTypes = {
  routeId: PropTypes.string,
  location: PropTypes.object,
};

export default NewConsultHistory;
