import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import './modal.scss';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import ReactDOM from 'react-dom';
import { isDeviceIOS } from '../../../utilities/domHelpers';

class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.el = document.createElement('div');
    this.ref = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    disableBodyScroll(this.ref.current, {
      allowTouchMove: () => {
        if (isDeviceIOS()) {
          return true;
        }
      },
    });
    this.modalRoot = document.getElementById('modal-root');
    this.modalRoot.appendChild(this.el);
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    enableBodyScroll(this.ref.current);
    this.modalRoot.removeChild(this.el);
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside() {
    if (this.ref && !this.ref.current.contains(event.target)) {
      this.props.onClose();
    }
  }

  render() {
    const {
      onClose,
      children,
      header,
      className,
      description,
      prePayment,
    } = this.props;
    const modalClass = classnames('modal', className);
    const modalContentClass = classnames({
      'modal-content': 'modal-content',
      prePayment: prePayment,
    });
    const modalContent = (
      <div className={modalClass}>
        <div className={modalContentClass} ref={this.ref}>
          <i className="icon-ic_cross" onClick={onClose} />
          <header>
            <h4 className="modal-content__heading">{header}</h4>
            {description && (
              <p className="modal-content__description">{description}</p>
            )}
          </header>
          {children}
        </div>
      </div>
    );

    return ReactDOM.createPortal(modalContent, this.el);
  }
}

Modal.defaultProps = {
  onClose: () => {},
};

Modal.propTypes = {
  onClose: PropTypes.func,
  children: PropTypes.any,
  header: PropTypes.string.isRequired,
  description: PropTypes.string,
  className: PropTypes.string,
  prePayment: PropTypes.string,
};

export default Modal;
