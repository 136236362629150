import React from 'react';
import PropTypes from 'prop-types';
import Link from '../../common/link/Link.js';
import {
  SectionHeading,
  SectionWrapper,
  SectionParagraph,
} from '../../common/section/Section.js';
import HorizontalCardsList from '../horizontal-cards-list/HorizontalCardsList.js';
import TopSpecialityCard from './TopSpecialityCard.js';
import DeviceContainer from '../../common/DeviceContainer.js';
import TrackOnceInViewContainer from '../../common/TrackOnceInViewContainer.js';
import { PEL_OBJECTS, PEL_ACTIONS } from '../../../constants/PELConstants.js';
import './topSpecialityCards.scss';

function TopSpecialityCards({
  problemAreasById,
  topSpecialityData,
  sendPELEventAction,
}) {
  const cards = topSpecialityData
    .filter(topSpeciality =>
      problemAreasById.hasOwnProperty(topSpeciality.problemAreaId)
    )
    .map((topSpeciality, index) => {
      const problemArea = problemAreasById[topSpeciality.problemAreaId];

      const objectContext = {
        'Card Title': problemArea.name,
        'Card Position': index + 1,
        'Problem Area Id': topSpeciality.problemAreaId,
      };
      const viewTrackingParams = {
        object: PEL_OBJECTS.consultHomeCard,
        action: PEL_ACTIONS.viewed,
        objectContext,
      };

      return (
        <TrackOnceInViewContainer key={index} {...viewTrackingParams}>
          <TopSpecialityCard
            heading={problemArea.name}
            currencySymbol={problemArea.fee_split.currency_symbol}
            price={problemArea.fee_split.projected_fee}
            imageName={topSpeciality.imageName}
            imageAlt={topSpeciality.imageAlt}
            problemAreaId={topSpeciality.problemAreaId}
            onClick={() => {
              sendPELEventAction(
                PEL_OBJECTS.consultHomeCard,
                PEL_ACTIONS.interacted,
                objectContext
              );
            }}
          />
        </TrackOnceInViewContainer>
      );
    });

  return (
    <SectionWrapper className="top-speciality-cards" type="horizontal-scroll">
      <SectionHeading
        className="top-speciality-cards__heading"
        type="horizontal-scroll"
      >
        25+ Specialities
      </SectionHeading>
      <SectionParagraph>
        Consult with top doctors across specialities
      </SectionParagraph>
      <Link
        className="top-speciality-cards__button"
        href="/consult/direct/new_consultation"
      >
        See all Specialities
      </Link>
      <DeviceContainer>
        {device => (
          <HorizontalCardsList
            type={device.isAmp ? 'amp' : device.isDesktop ? 'slider' : null}
            sliderConfig={{ name: 'top-specialities-slider', perView: 6 }}
          >
            {cards}
          </HorizontalCardsList>
        )}
      </DeviceContainer>
    </SectionWrapper>
  );
}

TopSpecialityCards.propTypes = {
  problemAreasById: PropTypes.object.isRequired,
  topSpecialityData: PropTypes.array.isRequired,
  sendPELEventAction: PropTypes.func.isRequired,
};

export default TopSpecialityCards;
