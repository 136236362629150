import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import useValidator from '../../../hooks/useValidator';
import useFormErrorHandler from '../../../hooks/useFormErrorHandler';
import './input.scss';
import { scrollRefIntoView } from '../../../utilities/utilities';

function Input({
  type,
  name,
  placeholder,
  fixedText,
  value,
  onChange,
  disabled,
  validation,
  message,
  onValidationChange,
  ...restProps
}) {
  const inputRef = useRef(null);
  const [isValid, error] = useValidator(value, validation);
  const [isErrorVisible, eventHandlers] = useFormErrorHandler(isValid);

  useEffect(() => {
    onValidationChange && onValidationChange(isValid);
  }, [isValid, onValidationChange]);

  const handleChange = event => {
    onChange(event.target.value);
    eventHandlers.onChange && eventHandlers.onChange(event);
  };

  const [isFocused, setIsFocused] = useState(false);

  const className = classnames('input-group', {
    'input-group--active': isFocused || value.length > 0,
    'input-group--with-fixed-text': fixedText,
    'input-group--disabled': disabled,
  });

  return (
    <div className={className}>
      <label htmlFor={name}>{placeholder}</label>
      {fixedText && (
        <span className="input-group__fixed-text">{fixedText}</span>
      )}
      <input
        ref={inputRef}
        disabled={disabled}
        onFocus={() => {
          setIsFocused(true);
          scrollRefIntoView(inputRef, { block: 'center' });
        }}
        onBlur={event => {
          setIsFocused(false);
          eventHandlers.onBlur && eventHandlers.onBlur(event);
        }}
        id={name}
        type={type}
        value={value}
        onChange={handleChange}
        {...restProps}
      />
      {message && <p className="input-group__message">{message}</p>}
      <p className="input-group__error">{isErrorVisible && error}</p>
    </div>
  );
}

Input.propTypes = {
  type: PropTypes.oneOf(['text', 'tel']),
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  fixedText: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  validation: PropTypes.object,
  message: PropTypes.string,
  onValidationChange: PropTypes.func,
};

export default Input;
