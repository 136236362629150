import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './button.scss';

const Button = ({ type, children, className, ...props }) => (
  <button className={classnames('button', type, className)} {...props}>
    {children}
  </button>
);

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any.isRequired,
  type: PropTypes.oneOf(['primary', 'secondary', 'secondary-black']),
};

export default Button;
