import consultRequestCreator from '../../consultRequestCreator';
import urlConstants from '../../../constants/urlConstants.js';

export function fetchSeoRecentQuestions({
  problemAreaIds,
  perProblemAreaLimit,
}) {
  return consultRequestCreator
    .get(urlConstants.RECENT_QUESTIONS, {
      params: {
        problem_area_ids: problemAreaIds.join(','),
        per_problem_area_limit: perProblemAreaLimit,
      },
    })
    .then(({ data }) => (data && data.length ? data : null))
    .catch(() => null);
}
