import { all, call, put, takeLatest } from 'redux-saga/effects';
import { LOAD_USERS_INIT, LOAD_USERS_INIT_SUCCESS } from './usersInit.js';
import * as usersInitApi from './usersInitApi.js';

export function* fetchUsersInit() {
  const response = yield call(usersInitApi.fetchUsersInit);
  yield put({
    type: LOAD_USERS_INIT_SUCCESS,
    payload: response,
  });
}

export default function* defaultSaga() {
  yield all([takeLatest(LOAD_USERS_INIT, fetchUsersInit)]);
}
