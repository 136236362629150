// This file will expose
// - secrets <object>: <from secrets/client>
// - <all configs merged - from env and base>

let clientConfig = {};

try {
  clientConfig = require('./secrets/client.js');
} catch (e) {
  console.error(
    '[Config Error] Missing `client` secret config. Check if `config/secrets/client.js` exists. Please run `npm run setup:config` for resolution.\nIf you are running this application for the first time run `npm run setup`\n'
  );
  throw e;
}

module.exports = clientConfig;
