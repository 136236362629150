import React from 'react';
import { connect } from 'react-redux';
import Notifications from 'react-notification-system-redux';
import PropTypes from 'prop-types';
import './toast.scss';

const topOffset = 20;

const style = {
  Wrapper: {},
  Containers: {
    tr: {
      top: `${topOffset}px`,
      right: '10%',
      left: '10%',
    },
  },

  NotificationItem: {
    DefaultStyle: {
      margin: '10px',
      zIndex: 90,
      borderTop: '0',
      backgroundColor: 'white',
      boxShadow: `0 1px 2px 1px rgba(180, 180, 189, 0.45)`,
      lineHeight: 1.25,
    },

    info: {
      color: '#14bef0',
    },

    success: {
      color: '#01A400',
    },

    error: {
      color: '#FF2D00',
    },
  },
};

const Toast = props => {
  const { notifications } = props;
  return <Notifications notifications={notifications} style={style} />;
};

Toast.propTypes = {
  notifications: PropTypes.any,
};

const mapStateToProps = state => ({
  notifications: state.notifications,
});

export default connect(mapStateToProps)(Toast);
