export default function getFooterData() {
  return [
    {
      heading: 'Practo',
      listItems: [
        {
          title: 'About',
          href: '/company/about',
          target: '_blank',
        },
        {
          title: 'Blog',
          href: '//blog.practo.com',
          target: '_blank',
        },
        {
          title: 'Careers',
          href: '/company/careers',
          target: '_blank',
        },
        {
          title: 'Press',
          href: '/company/press',
          target: '_blank',
        },
        {
          title: 'Contact',
          href: '/company/contact',
          target: '_blank',
        },
      ],
    },
    {
      heading: 'For patients',
      listItems: [
        {
          title: 'Search for doctors',
          href: '/bangalore/doctors',
        },
        {
          title: 'Search for clinics',
          href: '/bangalore/clinics',
        },
        {
          title: 'Search for hospitals',
          href: '/bangalore/hospitals',
          target: '_blank',
        },
        {
          title: 'Book Diagnostic Tests',
          href: '/tests',
          target: '_blank',
        },
        {
          title: 'Read health articles',
          href: '/healthfeed',
          target: '_blank',
        },
        {
          title: 'Read about medicines',
          href: '/medicine-info',
          target: '_blank',
        },
        {
          title: 'Online doctor consultation',
          href: '/consult',
          target: '_blank',
        },
        {
          title: 'Order medicines',
          href: '/order',
        },
        {
          title: 'Practo Drive',
          href: '/drive',
          target: '_blank',
        },
        {
          title: 'Health app',
          href: '/health-app',
          target: '_blank',
        },
        {
          title: 'Book Full Body Checkups',
          href: '/health-checkup-packages/master',
          target: '_blank',
        },
        {
          title: 'Practo Plus',
          href: '/plus?utm_source=consult_ui',
          target: '_blank',
        },
      ],
    },
    [
      {
        heading: 'For doctors',
        listItems: [
          {
            title: 'Practo Consult',
            href: '/providers/doctors/consult',
            target: '_blank',
          },
          {
            title: 'Practo Health Feed',
            href: '/providers/doctors/health-feed',
            target: '_blank',
          },
          {
            title: 'Practo Profile',
            href: '/providers/doctors/profile',
            target: '_blank',
          },
          {
            title: 'Online Doctor Consultation',
            href: '/consult?product=fabric&attribution=Footer',
            target: '_blank',
          },
        ],
      },
      {
        heading: 'For clinics',
        listItems: [
          {
            title: 'Ray by Practo',
            href: '/providers/clinics/ray',
            target: '_blank',
          },
          {
            title: 'Practo Reach',
            href: '/providers/clinics/reach',
            target: '_blank',
          },
          {
            title: 'Ray Tab',
            href: '/providers/clinics/ray/features#rayTab',
            target: '_blank',
          },
          {
            title: 'Practo Pro',
            href: '/practo-pro-app',
            target: '_blank',
          },
        ],
      },
    ],
    [
      {
        heading: 'For hospitals',
        listItems: [
          {
            title: 'Insta by Practo',
            href: '/providers/hospitals/insta',
            target: '_blank',
            rel: 'nofollow',
          },
          {
            title: 'Qikwell by Practo',
            href: '/providers/hospitals/qikwell',
            target: '_blank',
            rel: 'nofollow',
          },
          {
            title: 'Practo Profile',
            href: '/providers/hospitals/profile',
            target: '_blank',
          },
          {
            title: 'Practo Reach',
            href: '/providers/hospitals/reach',
            target: '_blank',
          },
        ],
      },
      {
        heading: 'For Corporates',
        listItems: [
          {
            title: 'Wellness Plans',
            href: '/plus/corporate',
            target: '_blank',
          },
        ],
      },
    ],
    {
      heading: 'More',
      listItems: [
        {
          title: 'Help',
          href: '//help.practo.com',
          target: '_blank',
        },
        {
          title: 'Developers',
          href: '//developers.practo.com',
          target: '_blank',
        },
        {
          title: 'Privacy Policy',
          href: '/company/privacy',
          target: '_blank',
        },
        {
          title: 'Terms & Conditions',
          href: '/company/terms',
          target: '_blank',
        },
        {
          title: 'Subscribers',
          href: '/company/subscribers',
          target: '_blank',
        },
        {
          title: 'Healthcare directory',
          href: '/sitemap',
          target: '_blank',
        },
        {
          title: 'Practo Health Wiki',
          href: '/health-wiki',
          target: '_blank',
        },
      ],
    },
    {
      heading: 'Social',
      listItems: [
        {
          title: 'Facebook',
          href: '//www.facebook.com/practo',
          target: '_blank',
          rel: 'nofollow',
        },
        {
          title: 'Twitter',
          href: '//twitter.com/Practo',
          target: '_blank',
          rel: 'nofollow',
        },
        {
          title: 'LinkedIn',
          href: '//www.linkedin.com/company/practo-technologies-pvt-ltd',
          target: '_blank',
          rel: 'nofollow',
        },
        {
          title: 'YouTube',
          href: '//www.youtube.com/user/PractoSupport',
          target: '_blank',
          rel: 'nofollow',
        },
        {
          title: 'GitHub',
          href: '//github.com/practo',
          target: '_blank',
          rel: 'nofollow',
        },
      ],
    },
  ];
}
