import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import DoctorCardSmall from '../../doctor-card/DoctorCardSmall.js';
import PaymentSection from '../payment-section/PaymentSection.js';

function SelectedDoctorContainer() {
  const { doctorProfile } = useSelector(
    state => ({
      doctorProfile: state.doctorProfile.data,
    }),
    shallowEqual
  );

  if (!doctorProfile) {
    return null;
  }

  const { speciality } = doctorProfile;

  return (
    <PaymentSection title="SELECTED DOCTOR FOR CHAT">
      <DoctorCardSmall {...doctorProfile} specialities={speciality} />
    </PaymentSection>
  );
}

export default SelectedDoctorContainer;
