import { all, call, takeLatest, put } from 'redux-saga/effects';
import {
  FETCH_BANNER_PROPS,
  FETCH_BANNER_PROPS_LOADING,
  FETCH_BANNER_PROPS_SUCCESS,
  FETCH_BANNER_PROPS_FAILURE,
} from './bannerProps.js';
import { fetchBannerProps } from './bannerPropsApi.js';

export function* loadBannerProps() {
  yield put({
    type: FETCH_BANNER_PROPS_LOADING,
  });
  try {
    const urls = yield call(fetchBannerProps);
    yield put({
      type: FETCH_BANNER_PROPS_SUCCESS,
      payload: {
        urls,
      },
    });
  } catch (err) {
    yield put({
      type: FETCH_BANNER_PROPS_FAILURE,
      payload: { urls: [] },
    });
  }
}

export default function* bannerPropsSaga() {
  yield all([takeLatest(FETCH_BANNER_PROPS, loadBannerProps)]);
}
