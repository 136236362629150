import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import FeeSplit from './FeeSplit';

function FeeSplitContainer() {
  const feeSplit = useSelector(
    state => ({
      currencySymbol: state.payments.currencySymbol,
      fee: state.payments.fee,
      couponAmount: state.payments.couponAmount,
      healthCashAmount: state.payments.healthCashAmount,
      showHealthCash: state.featureMap.show_health_cash,
      practoWalletBalanceNote: state.payments.practoWalletBalanceNote,
    }),
    shallowEqual
  );

  return <FeeSplit {...feeSplit} />;
}

export default FeeSplitContainer;
