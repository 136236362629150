import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import './coupons.scss';
import CouponButton from './CouponButton';
import AppliedCoupon from './AppliedCoupon';

const CouponsSection = props => {
  return (
    <Fragment>
      {!props.appliedCouponCode ? (
        <CouponButton openCouponModal={props.openCouponModal} />
      ) : (
        <AppliedCoupon couponCode={props.appliedCouponCode} />
      )}
      {props.children}
    </Fragment>
  );
};

CouponsSection.propTypes = {
  appliedCouponCode: PropTypes.string,
  openCouponModal: PropTypes.func,
  children: PropTypes.any,
};

export default CouponsSection;
