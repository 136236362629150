import React from 'react';
import classnames from 'classnames';
import {
  SectionHeading,
  SectionParagraph,
  SectionWrapper,
} from '../section/Section.js';
import PropTypes from 'prop-types';
import { getImageUrl } from '../../../utilities/assetsHelper.js';
import LazyImg from '../../common/LazyImg.js';
import './howItWorksSection.scss';

function HowItWorksSection({ heading, paragraph, steps }) {
  const mainHeadingClass = classnames('how-it-works-main-heading', {
    'align-center': !paragraph,
  });

  return (
    <SectionWrapper>
      <SectionHeading className={mainHeadingClass}>{heading}</SectionHeading>

      {paragraph && <SectionParagraph>{paragraph}</SectionParagraph>}

      <div className="how-it-works-section">
        {steps.map((step, index) => (
          <div key={index} className="each-value-prop">
            <div className="each-value-prop-image">
              {step.img ? (
                <LazyImg
                  width="52"
                  alt={step.heading}
                  src={getImageUrl(step.img)}
                />
              ) : (
                <i className={step.icon} />
              )}
            </div>
            <div className="content">
              {step.heading && <h3 className="heading">{step.heading}</h3>}
              <p className="description">{step.description}</p>
            </div>
          </div>
        ))}
      </div>
    </SectionWrapper>
  );
}

HowItWorksSection.propTypes = {
  heading: PropTypes.string,
  paragraph: PropTypes.string,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      image_url: PropTypes.string,
      icon: PropTypes.string,
      heading: PropTypes.string,
      description: PropTypes.string,
    })
  ),
};

export default HowItWorksSection;
