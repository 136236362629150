import { all, put, takeLatest, take } from 'redux-saga/effects';
import cookies from 'js-cookie';

import {
  INITIATE_OTP_LOGIN,
  SHOW_OTP_LOGIN,
  INITIATE_LOGIN,
  SHOW_LOGIN,
  INITIATE_REGISTER,
  SHOW_REGISTER,
  NOTIFY_LOGIN_SUCCESSFUL,
  showConsultLogin,
} from './loginModal.js';
import { LOAD_USER_SUCCESS } from '../user/user.js';

export function isLoggedInAccounts() {
  return !!cookies.get('sso');
}

export function* initiateOtpLogin(action) {
  if (isLoggedInAccounts()) {
    yield put(showConsultLogin());
  } else {
    yield put({
      type: SHOW_OTP_LOGIN,
      payload: action.payload,
    });
  }

  if (action.payload.onDone) {
    yield take(NOTIFY_LOGIN_SUCCESSFUL);
    yield take(LOAD_USER_SUCCESS);
    action.payload.onDone();
  }
}

export function* initiateLogin() {
  if (isLoggedInAccounts()) {
    yield put(showConsultLogin());
  } else {
    yield put({
      type: SHOW_LOGIN,
    });
  }
}

export function* initiateRegister() {
  if (isLoggedInAccounts()) {
    yield put(showConsultLogin());
  } else {
    yield put({
      type: SHOW_REGISTER,
    });
  }
}

export default function* loginModalSaga() {
  yield all([
    takeLatest(INITIATE_OTP_LOGIN, initiateOtpLogin),
    takeLatest(INITIATE_LOGIN, initiateLogin),
    takeLatest(INITIATE_REGISTER, initiateRegister),
  ]);
}
