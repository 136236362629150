export const defaultHomepageConstants = {
  healthConcern: {
    heading: 'Still delaying your health concerns ?',
    description: `Connect with India's top doctors online`,
  },
  howItWorks: {
    heading: 'How it works',
    steps: [
      {
        icon: 'icon-ic_cursor',
        description: 'Select a speciality or symptom',
      },
      {
        icon: 'icon-ic_chat',
        description: 'Audio/ video call with a verified doctor',
      },
      {
        icon: 'icon-ic_prescription',
        description: 'Get a digital prescription & a free follow-up',
      },
    ],
  },
  consultExperienceVideo: {
    heading: 'Experience online doctor consultations',
    videos: [
      {
        title: '#HelloDoctor Consult a doctor online from home',
        youtubeID: 'QbUqZ3_9DKs',
      },
      {
        title: '#HelloDoctor Consult a doctor online from home',
        youtubeID: '4cT6GE5g8aw',
      },
      {
        title: 'Video Consult with Top Doctors Online',
        youtubeID: 'l-sZUQ-pQjs',
      },
      {
        title: 'Video Consult with Top Doctors Online',
        youtubeID: 'gCONPv6a1mE',
      },
    ],
  },
};

export const BenefitsSection = {
  heading: 'Benefits of Online Consultation',
  list: [
    {
      title: 'Consult Top Doctors 24x7',
      description:
        'Connect instantly with a 24x7 specialist or choose to video visit a particular doctor.',
    },
    {
      title: 'Convenient and Easy',
      description:
        'Start an instant consultation within 2 minutes or do video consultation at the scheduled time.',
    },
    {
      title: '100% Safe Consultations',
      description:
        'Be assured that your online consultation will be fully private and secured.',
    },
    {
      title: 'Similar Clinic Experience',
      description:
        'Experience clinic-like consultation through a video call with the doctor. Video consultation is available only on the Practo app.',
    },
    {
      title: 'Free Follow-up',
      description:
        'Get a valid digital prescription and a 7-day, free follow-up for further clarifications.',
    },
  ],
};
