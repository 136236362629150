/**
 * Some browsers may not have 'sessionStorage' property on window while others
 * may throw exceptions like quota exceeded exception/SecurityError exception
 * while accessing it or during 'setItem' operation
 */
export function hasSessionStorageSupport() {
  const testKey = 'TEST_SESSION_STORAGE_SUPPORT';
  try {
    window.sessionStorage.setItem(testKey, true);
    window.sessionStorage.removeItem(testKey, true);
    return true;
  } catch (error) {
    return false;
  }
}

export function scrollToBottom() {
  window.scrollTo(0, document.body.scrollHeight);
}

export function isDeviceIOS() {
  return (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
}
