import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Link from '../../common/link/Link.js';
import {
  SectionHeading,
  SectionParagraph,
} from '../../common/section/Section.js';
import SolidBackground from '../../common/solid-background/SolidBackground.js';
import './healthConcern.scss';

function HealthConcern({ heading, description }) {
  const url = `/consult/direct/new_consultation`;
  const mainHeadingClass = classnames('heading', {
    'align-center': !description,
  });

  const descriptionClass = classnames('description', {
    'align-center': !description,
  });

  return (
    <SolidBackground color="black">
      <div className="health-concern-wrapper desktop-wrapper">
        <div className="health-concern-section">
          <SectionHeading className={mainHeadingClass}>
            {heading}
          </SectionHeading>

          {description && (
            <SectionParagraph className={descriptionClass}>
              {description}
            </SectionParagraph>
          )}
        </div>
        <div className="health-concern-wrapper__cta">
          <Link className="primary-button cta" href={url} tagName="a">
            Consult Now
          </Link>
        </div>
      </div>
    </SolidBackground>
  );
}

HealthConcern.propTypes = {
  heading: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default HealthConcern;
