export const LOAD_RECENT_CHATS = 'LOAD_RECENT_CHATS';
export const LOAD_RECENT_CHATS_SUCCESS = 'LOAD_RECENT_CHATS_SUCCESS';
export const LOAD_RECENT_CHATS_FAILURE = 'LOAD_RECENT_CHATS_FAILURE';

export const loadRecentChats = () => ({
  type: LOAD_RECENT_CHATS,
});

const initialState = [];

export default function recentChatsReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_RECENT_CHATS_SUCCESS:
      return action.payload;

    case LOAD_RECENT_CHATS_FAILURE:
      return initialState;

    default:
      return state;
  }
}
