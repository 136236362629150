import consultRequestCreator from '../../consultRequestCreator';
import urlConstants from '../../../constants/urlConstants';
import { subscriptionPlansMap } from '../../../constants/consultConstants.js';

export const fetchDoctorProfile = function (
  doctorAccountId,
  subscriptionPlanId = subscriptionPlansMap.paid
) {
  return consultRequestCreator
    .get(urlConstants.DOCTOR_PROFILE, {
      params: {
        doctor_account_id: doctorAccountId,
        subscription_plan_id: subscriptionPlanId,
      },
    })
    .then(response => response.data.doctors[0]);
};
