import { call, put, takeLatest } from 'redux-saga/effects';
import { fetchSeoRecentQuestions } from './seoApi.js';

import { LOAD_SEO_RECENT_QUESTIONS, updateSeoRecentQuestions } from './seo.js';

export function* loadSeoRecentQuestions(action) {
  const seoRecentQuestions = yield call(
    fetchSeoRecentQuestions,
    action.payload
  );
  yield put(updateSeoRecentQuestions(seoRecentQuestions));
}

export default function* seoSaga() {
  yield takeLatest(LOAD_SEO_RECENT_QUESTIONS, loadSeoRecentQuestions);
}
