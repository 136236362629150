import React from 'react';
import PropTypes from 'prop-types';
import Link from '../../common/link/Link.js';
import TrackOnceInViewContainer from '../../common/TrackOnceInViewContainer.js';
import StickyFooter from '../../common/sticky-footer/StickyFooter.js';
import './homeStickyFooter.scss';
import { MIN_CONSULTATION_FEE } from '../../../constants/consultConstants.js';

function HomeStickyFooter({
  isVisible,
  onCtaClick,
  viewTrackingParams,
  isAmp,
}) {
  const className = `home-sticky-footer ${
    isAmp ? 'home-sticky-footer-hide' : null
  }`;
  return (
    <React.Fragment>
      {isAmp ? (
        <React.Fragment>
          <amp-animation id="show_sticky_footer" layout="nodisplay">
            <script
              type="application/json"
              dangerouslySetInnerHTML={{
                __html: JSON.stringify([
                  {
                    duration: '1ms',
                    fill: 'both',
                    iterations: '1',
                    direction: 'alternate',
                    animations: [
                      {
                        selector: '.home-sticky-footer',
                        keyframes: [
                          {
                            opacity: '0.9',
                          },
                          {
                            opacity: '1',
                          },
                        ],
                      },
                    ],
                  },
                ]),
              }}
            />
          </amp-animation>
          <amp-animation id="hide_sticky_footer" layout="nodisplay">
            <script
              type="application/json"
              dangerouslySetInnerHTML={{
                __html: JSON.stringify([
                  {
                    duration: '1ms',
                    fill: 'both',
                    iterations: '1',
                    direction: 'alternate',
                    animations: [
                      {
                        selector: '.home-sticky-footer',
                        keyframes: [
                          {
                            opacity: '0.9',
                          },
                          {
                            opacity: '0',
                          },
                        ],
                      },
                    ],
                  },
                ]),
              }}
            />
          </amp-animation>
          <amp-position-observer
            intersection-ratios="0"
            target="HealthProblemCardsContainer"
            on="enter:hide_sticky_footer.start"
            layout="nodisplay"
          />
          <amp-position-observer
            intersection-ratios="0"
            target="OfferCardsContainer"
            on="enter:show_sticky_footer.start"
            layout="nodisplay"
          />
          <amp-position-observer
            intersection-ratios="0"
            target="ConsultationExperienceVideos"
            on="enter:show_sticky_footer.start;"
            layout="nodisplay"
          />
          <amp-position-observer
            intersection-ratios="0"
            target="BenefitsSection"
            on="enter:show_sticky_footer.start;"
            layout="nodisplay"
          />
          <amp-position-observer
            intersection-ratios="0"
            target="TestimonialCards"
            on="enter:hide_sticky_footer.start;"
            layout="nodisplay"
          />
        </React.Fragment>
      ) : null}
      <StickyFooter height="60px" className={className} isVisible={isVisible}>
        <TrackOnceInViewContainer {...viewTrackingParams}>
          <React.Fragment>
            <div className="value-props">
              <p>Private consultation + call</p>
              <p>Starts at just {MIN_CONSULTATION_FEE}</p>
            </div>
            <Link
              className="cta"
              type="primary-button"
              href="/consult/direct/new_consultation"
              onClick={onCtaClick}
            >
              Consult Now
            </Link>
          </React.Fragment>
        </TrackOnceInViewContainer>
      </StickyFooter>
    </React.Fragment>
  );
}

HomeStickyFooter.propTypes = {
  isAmp: PropTypes.bool,
  isVisible: PropTypes.bool,
  onCtaClick: PropTypes.func.isRequired,
  viewTrackingParams: PropTypes.object.isRequired,
};

export default HomeStickyFooter;
