import React from 'react';
import './loader.scss';
import classnames from 'classnames';
import PropTypes from 'prop-types';

function Loader(props) {
  const containerClassName = classnames('loader-container', props.type);

  return (
    <div className={containerClassName}>
      <div className="inline-loader">
        <svg className="loader-circular-svg" viewBox="25 25 50 50">
          <circle
            className="loader-circular-path"
            cx="50"
            cy="50"
            r="20"
            fill="none"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
        </svg>
      </div>
    </div>
  );
}

Loader.propTypes = {
  type: PropTypes.oneOf(['overlay', 'inline']),
};

export default Loader;
