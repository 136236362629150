import { selectNewConsultState, selectUserState } from '../selectors.js';
export const SELECT_PROBLEM_AREA = 'NEW_CONSULT/SELECT_PROBLEM_AREA';
export const UPDATE_SEARCH_QUERY = 'NEW_CONSULT/UPDATE_SEARCH_QUERY';
export const SELECT_SPECIALITY = 'SELECT_SPECIALITY';
export const SELECT_SYMPTOM = 'SELECT_SYMPTOM';
export const SEARCH_SELECT = 'SEARCH_SELECT';
export const UPDATE_USER_NAME = 'NEW_CONSULT/UPDATE_USER_NAME';
export const UPDATE_USER_NAME_VALID = 'NEW_CONSULT/UPDATE_USER_NAME_VALID';
export const POPULATE_USER_NAME = 'NEW_CONSULT/POPULATE_USER_NAME';
export const UPDATE_USER_MOBILE = 'NEW_CONSULT/UPDATE_USER_MOBILE';
export const NOTIFY_LOGIN = 'NEW_CONSULT/NOTIFY_LOGIN';
export const UPDATE_SERVICE_DETAILS = 'NEW_CONSULT/UPDATE_SERVICE_DETAILS';
export const SET_ARE_ALL_SPECIALITIES_VISIBLE =
  'NEW_CONSULT/SET_ARE_ALL_SPECIALITIES_VISIBLE';
export const REPLACE_NEW_CONSULT = 'REPLACE_NEW_CONSULT';
export const FETCH_SELECTED_PROBLEM_AREA_IDS =
  'FETCH_SELECTED_PROBLEM_AREA_IDS';
export const FETCH_SELECTED_PROBLEM_AREA_IDS_SUCCESS =
  'FETCH_SELECTED_PROBLEM_AREA_IDS_SUCCESS';
export const RESET_NEW_CONSULT = 'RESET_NEW_CONSULT';

export const SELECTED_TYPES = {
  SYMPTOM: 'symptom',
  SPECIALITY: 'problem_area',
};

export const notifyLogin = onDone => ({
  type: NOTIFY_LOGIN,
  payload: {
    onDone,
  },
});

export const replaceNewConsult = newConsult => ({
  type: REPLACE_NEW_CONSULT,
  payload: {
    newConsult,
  },
});

export const fetchSelectedProblemAreaIds = () => ({
  type: FETCH_SELECTED_PROBLEM_AREA_IDS,
});

const initialState = {
  selectedProblemAreaId: null,
  userName: '',
  userNameValid: '',
  userMobile: '',
  serviceDetails: null,
  searchedQuery: null,
  selected: {
    id: null,
    type: null,
    name: null,
    imageUrl: null,
  },

  isProblemAreaPreselected: false,
  selectedProblemAreaIds: null,
  areAllSpecialitiesVisible: false,
};

export function checkFormValidity(state) {
  const myState = selectNewConsultState(state);
  const userState = selectUserState(state);
  if (myState.serviceDetails) {
    return !!(myState.userNameValid && userState.mobile);
  }

  return !!(
    myState.selectedProblemAreaId &&
    myState.userNameValid &&
    userState.mobile
  );
}

export default function newConsultReducer(state = initialState, action = {}) {
  switch (action.type) {
    case SELECT_PROBLEM_AREA:
      return {
        ...state,
        selectedProblemAreaId: action.payload.id,
      };

    case UPDATE_USER_NAME:
      return {
        ...state,
        userName: action.payload.userName,
      };

    case UPDATE_USER_NAME_VALID:
      return {
        ...state,
        userNameValid: action.payload.isValid,
      };

    case POPULATE_USER_NAME:
      return {
        ...state,
        userName: state.userName || action.payload.userNameToPopulate,
      };

    case UPDATE_USER_MOBILE:
      return {
        ...state,
        userMobile: action.payload.userMobile,
      };

    case UPDATE_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: action.payload.searchQuery,
      };

    case UPDATE_SERVICE_DETAILS:
      return {
        ...state,
        serviceDetails: action.payload.serviceDetails,
      };

    case SELECT_SYMPTOM: {
      const { id, name, imageUrl } = action.payload;
      return {
        ...state,
        selectedProblemAreaIds: null,
        selectedProblemAreaId: null,
        selected: {
          type: SELECTED_TYPES.SYMPTOM,
          id,
          name,
          imageUrl,
        },
      };
    }

    case SELECT_SPECIALITY: {
      const { id, isProblemAreaPreselected } = action.payload;
      return {
        ...state,
        isProblemAreaPreselected: isProblemAreaPreselected,
        selectedProblemAreaIds: [id],
        selectedProblemAreaId: id,
        selected: {
          type: SELECTED_TYPES.SPECIALITY,
          id,
        },
      };
    }

    case SEARCH_SELECT: {
      return {
        ...state,
        selected: action.payload.selected,
        selectedProblemAreaIds: null,
        selectedProblemAreaId: null,
      };
    }

    case FETCH_SELECTED_PROBLEM_AREA_IDS_SUCCESS:
      return {
        ...state,
        selectedProblemAreaIds: action.payload,
        selectedProblemAreaId: action.payload[0],
      };

    case SET_ARE_ALL_SPECIALITIES_VISIBLE:
      return {
        ...state,
        areAllSpecialitiesVisible: action.payload.areAllSpecialitiesVisible,
      };

    case REPLACE_NEW_CONSULT:
      return {
        ...initialState,
        ...action.payload.newConsult,
      };

    default:
      return { ...state };
  }
}

export function selectProblemArea(id) {
  return {
    type: SELECT_PROBLEM_AREA,
    payload: {
      id,
    },
  };
}

export const updateUserName = userName => ({
  type: UPDATE_USER_NAME,
  payload: {
    userName,
  },
});

export const updateUserNameValid = isValid => {
  return {
    type: UPDATE_USER_NAME_VALID,
    payload: {
      isValid,
    },
  };
};

export const populateUserName = (userNameToPopulate = '') => ({
  type: POPULATE_USER_NAME,
  payload: {
    userNameToPopulate,
  },
});

export const updateUserMobile = userMobile => ({
  type: UPDATE_USER_MOBILE,
  payload: {
    userMobile,
  },
});

export const updateServiceDetails = serviceDetails => ({
  type: UPDATE_SERVICE_DETAILS,
  payload: {
    serviceDetails,
  },
});

export const updateSearchQuery = searchQuery => ({
  type: UPDATE_SEARCH_QUERY,
  payload: {
    searchQuery,
  },
});

export const selectSymptom = (id, name, imageUrl) => ({
  type: SELECT_SYMPTOM,
  payload: {
    id,
    name,
    imageUrl,
  },
});

export const selectSpeciality = (id, isProblemAreaPreselected = false) => ({
  type: SELECT_SPECIALITY,
  payload: {
    id,
    isProblemAreaPreselected,
  },
});

export const searchSelect = selected => ({
  type: SEARCH_SELECT,
  payload: {
    selected,
  },
});

export const resetNewConsult = () => ({
  type: RESET_NEW_CONSULT,
});

export const setAreAllSpecialitiesVisible = areAllSpecialitiesVisible => ({
  type: SET_ARE_ALL_SPECIALITIES_VISIBLE,
  payload: {
    areAllSpecialitiesVisible,
  },
});
