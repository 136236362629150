import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import RadioCard from '../../common/radio-card/RadioCard.js';
import './specialityCards.scss';

export default class SpecialityCards extends PureComponent {
  render() {
    const limitedProblemAreas = this.props.problemAreas.filter(
      (problemArea, index) =>
        this.props.limit ? index < this.props.limit : true
    );
    return (
      <div className="speciality-cards">
        {limitedProblemAreas.map(problemArea => {
          return (
            <RadioCard
              key={problemArea.name}
              checked={this.props.selectedId === problemArea.id}
              title={problemArea.name}
              subtitle={
                problemArea.fee_split.currency_symbol +
                problemArea.fee_split.projected_fee
              }
              imageUrl={problemArea.image_url}
              onClick={() =>
                this.props.onClick && this.props.onClick(problemArea)
              }
              enableForm={this.props.enableForm}
              onChange={() => this.props.onChange(problemArea)}
            />
          );
        })}
      </div>
    );
  }
}

SpecialityCards.propTypes = {
  problemAreas: PropTypes.array,
  limit: PropTypes.number,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  enableForm: PropTypes.bool,
  selectedId: PropTypes.number,
};
