import React from 'react';
import PropTypes from 'prop-types';
import RadioCard from '../common/radio-card/RadioCard.js';

export default function SymptomsCardSection({ symptoms, selectSymptom }) {
  return (
    <div className="speciality-cards">
      {symptoms.map(symptom => {
        return (
          <RadioCard
            key={symptom.name}
            title={symptom.name}
            subtitle={symptom.fee_range}
            imageUrl={symptom.image_url}
            onClick={() => selectSymptom(symptom)}
            alignCenter={true}
          />
        );
      })}
    </div>
  );
}

SymptomsCardSection.propTypes = {
  symptoms: PropTypes.array.isRequired,
  selectSymptom: PropTypes.func.isRequired,
};
