import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getImageUrl } from '../../utilities/assetsHelper.js';
import './doctorCard.scss';
import LazyImg from '../common/LazyImg.js';
import { scrollRefIntoView } from '../../utilities/utilities.js';

function DoctorCardLarge({
  name,
  profile_picture,
  specialities,
  years_of_experience,
  consults_done,
  qualifications,
  badges,
  autoScroll,
}) {
  const ref = useRef(null);

  useEffect(() => {
    if (autoScroll) {
      // Since cards render as a horizontally flexed element
      // the scroll should center align in that direction
      scrollRefIntoView(ref, {
        inline: 'center',
      });
    }
  }, [autoScroll]);

  return (
    <article
      className="doctor-card doctor-card--large"
      ref={autoScroll ? ref : null}
    >
      <header>
        <div className="doctor-card__left">
          <figure className="doctor-card__profile-picture">
            <LazyImg
              alt={name}
              src={profile_picture || getImageUrl('doctor-profile-v1.png')}
            />
          </figure>
        </div>

        <div className="doctor-card__content ellipsis">
          <h4 className="doctor-card__name ellipsis">{name}</h4>
          <p className="ellipsis">{specialities}</p>
        </div>
      </header>

      <DoctorDetailGroup heading="EXPERIENCE">
        {years_of_experience > 0 && (
          <p>
            <span className="bold">{years_of_experience}</span> year
            {years_of_experience > 1 ? 's' : ''} experience
          </p>
        )}
        {consults_done > 0 && (
          <p>
            <span className="bold">{consults_done}</span> online consultations
            completed
          </p>
        )}
      </DoctorDetailGroup>

      {qualifications && (
        <DoctorDetailGroup heading="QUALIFICATION">
          <p>{qualifications}</p>
        </DoctorDetailGroup>
      )}

      {badges.length > 0 && (
        <DoctorDetailGroup heading="DOCTOR BADGES">
          <div className="doctor-card__badges">
            {badges.map(({ image_url, title, value }) => (
              <div key={title} className="doctor-card__badges__each">
                <figure>
                  <img src={image_url} alt={title} />
                  <figcaption>{value}</figcaption>
                </figure>
                <label>{title}</label>
              </div>
            ))}
          </div>
        </DoctorDetailGroup>
      )}
    </article>
  );
}

function DoctorDetailGroup({ heading, children }) {
  return (
    <div className="doctor-detail-group">
      <h5 className="doctor-detail-group__heading">{heading}</h5>
      {children}
    </div>
  );
}

DoctorDetailGroup.propTypes = {
  heading: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
};

DoctorCardLarge.propTypes = {
  name: PropTypes.string.isRequired,
  specialities: PropTypes.string.isRequired,
  profile_picture: PropTypes.string,
  years_of_experience: PropTypes.number,
  consults_done: PropTypes.string,
  qualifications: PropTypes.string,
  badges: PropTypes.arrayOf(
    PropTypes.shape({
      image_url: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    })
  ),
  autoScroll: PropTypes.bool,
};

export default React.memo(DoctorCardLarge);
