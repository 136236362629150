import React from 'react';
import { connect } from 'react-redux';
import TopSpecialityCards from './TopSpecialityCards.js';
import { sendPELEventAction } from '../../../redux/ducks/analytics/analyticsSaga.js';

class TopSpecialityCardsContainer extends React.Component {
  render() {
    return (
      <TopSpecialityCards
        topSpecialityData={this.getTopSpecialityData()}
        {...this.props}
      />
    );
  }

  getTopSpecialityData() {
    return [
      {
        problemAreaId: 16,
        imageName: 'top-speciality-gynaecologist.svg',
        imageAlt: 'Online Gynecologist',
      },
      {
        problemAreaId: 14,
        imageName: 'top-speciality-sexology.svg',
        imageAlt: 'Online Sexologist',
      },
      {
        problemAreaId: 22,
        imageName: 'top-speciality-gp.svg',
        imageAlt: 'Online General Physician',
      },
      {
        problemAreaId: 5,
        imageName: 'top-speciality-dermatologist.svg',
        imageAlt: 'Online Dermatologist',
      },
      {
        problemAreaId: 2,
        imageName: 'top-speciality-psychiatric.svg',
        imageAlt: 'Online Psychiatrist',
      },
      {
        problemAreaId: 10,
        imageName: 'top-speciality-stomach.svg',
        imageAlt: 'Online Gastroenterologist',
      },
      {
        problemAreaId: 17,
        imageName: 'top-speciality-pediatric.svg',
        imageAlt: 'Online Pediatrician',
      },
      {
        problemAreaId: 9,
        imageName: 'top-speciality-kidney.svg',
        imageAlt: 'Online Kidney Specialist',
      },
    ];
  }
}

const mapStateToProps = state => {
  return {
    problemAreasById: state.problemAreas.problemAreasById,
  };
};

const mapDispatchToProps = dispatch => ({
  sendPELEventAction: (...args) => dispatch(sendPELEventAction(...args)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TopSpecialityCardsContainer);
