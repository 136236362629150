import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './section.scss';

export function SectionWrapper({ type, children, className, testid }) {
  const sectionWrapperClass = classnames(
    'homepage-section-wrapper',
    'desktop-wrapper',
    type,
    className
  );

  return (
    <div className={sectionWrapperClass} data-testid={testid}>
      {children}
    </div>
  );
}

SectionWrapper.propTypes = {
  type: PropTypes.oneOf(['horizontal-scroll']),
  children: PropTypes.any,
  testid: PropTypes.string,
  className: PropTypes.string,
};

export function SectionHeading({ type, children, className, testid }) {
  const sectionHeadingClass = classnames(
    'homepage-section-heading',
    type,
    className
  );

  return (
    <h2 className={sectionHeadingClass} data-testid={testid}>
      {children}
    </h2>
  );
}

SectionHeading.propTypes = {
  type: PropTypes.oneOf(['horizontal-scroll']),
  children: PropTypes.any,
  testid: PropTypes.string,
  className: PropTypes.string,
};

export function SectionParagraph({ type, children, className, testid }) {
  const sectionParagraphClass = classnames(
    'homepage-section-paragraph',
    type,
    className
  );

  return (
    <p className={sectionParagraphClass} data-testid={testid}>
      {children}
    </p>
  );
}

SectionParagraph.propTypes = {
  type: PropTypes.oneOf(['horizontal-scroll']),
  children: PropTypes.any,
  testid: PropTypes.string,
  className: PropTypes.string,
};
