import queryString from 'query-string';

const attributeFromSession = (function () {
  const attributionKey = 'marketplace-attribution';

  return {
    get: function () {
      return JSON.parse(sessionStorage.getItem(attributionKey));
    },
    set: function (attributionObj) {
      if (!attributionObj) {
        return;
      }
      sessionStorage.setItem(attributionKey, JSON.stringify(attributionObj));
    },
  };
})();

function getMarketplaceAttribution() {
  const attributionFromUrl = getAttributionFromUrl();
  const attributionInSession = attributeFromSession.get();
  const hasAttributionChanged = attributionFromUrl !== attributionInSession;

  if (!attributionInSession || hasAttributionChanged) {
    attributeFromSession.set(attributionFromUrl);
  }

  return {
    joined: attributeFromSession.get(),
  };
}

function getAttributionFromUrl() {
  const queryParams = queryString.parse(window.location.search);

  const attributesInOrder = [
    'landing_page',
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_content',
    'utm_term',
    'action_source',
    'referrer',
    'query_type',
    'query_value',
    'listing_type',
    'location_type',
    'location_value',
    'query_value_id',
    'location_value_id',
    'book_button_click_source',
  ];

  // To avoid "NULL | NULL ..."
  let nonNullableValueCount = 0;

  const pipedAttribution = attributesInOrder
    .reduce((attributeValues, attribute) => {
      const attributeValue = queryParams[attribute] || 'NULL';
      queryParams[attribute] && nonNullableValueCount++;

      return attributeValues.concat(attributeValue);
    }, [])
    .join('|');

  return nonNullableValueCount > 0 ? pipedAttribution : '';
}

export default {
  get: getMarketplaceAttribution,
};
