import { all, call, takeLatest, put, select } from 'redux-saga/effects';
import Notifications from 'react-notification-system-redux';
import { NEW_CONSULT_STORAGE_KEY } from '../../../constants/consultConstants.js';

import {
  NOTIFY_LOGIN,
  FETCH_SELECTED_PROBLEM_AREA_IDS,
  FETCH_SELECTED_PROBLEM_AREA_IDS_SUCCESS,
  RESET_NEW_CONSULT,
} from './newConsult.js';
import { initiateOtpLogin } from '../login-modal/loginModal.js';
import { selectNewConsultState } from '../selectors.js';
import { fetchProblemAreasForQuery } from './newConsultApi.js';

export function* fetchSelectedProblemAreaIds() {
  const { selected } = yield select(selectNewConsultState);
  const response = yield call(
    fetchProblemAreasForQuery,
    selected.id,
    selected.type
  );
  yield put({
    type: FETCH_SELECTED_PROBLEM_AREA_IDS_SUCCESS,
    payload: response,
  });
}

export function* notifyLogin(action) {
  yield put(
    Notifications.info({
      message:
        'You have been logged out. Please Login to continue this consultation.',
      dismissible: false,
    })
  );
  const newConsultState = yield select(selectNewConsultState);
  yield put(
    initiateOtpLogin({
      name: newConsultState.userName,
      mobile: newConsultState.userMobile,
      onDone: action.payload.onDone,
    })
  );
}

export function* resetNewConsultStorage() {
  yield window.sessionStorage.removeItem(NEW_CONSULT_STORAGE_KEY);
}

export default function* newConsultSaga() {
  yield all([
    takeLatest(NOTIFY_LOGIN, notifyLogin),
    takeLatest(FETCH_SELECTED_PROBLEM_AREA_IDS, fetchSelectedProblemAreaIds),
    takeLatest(RESET_NEW_CONSULT, resetNewConsultStorage),
  ]);
}
