import consultRequestCreator from '../../consultRequestCreator';
import urlConstants from '../../../constants/urlConstants';

export function fetchAvailableDoctors(problemAreaId, limit, offset) {
  return consultRequestCreator
    .get(urlConstants.AVAILABLE_DOCTORS, {
      params: { limit, offset, problem_area_ids: problemAreaId },
    })
    .then(response => response.data);
}
