import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cookies from 'js-cookie';
import VisibilitySensor from 'react-visibility-sensor';

import FirstFold from './first-fold/FirstFold.js';
// import('./first-fold/FirstFold.js')
import VideoCardsContainer from './video-cards/VideoCardsContainer';
import HealthProblemCardsContainer from './health-problem-cards/HealthProblemCardsContainer.js';
import OfferCardsContainer from './offer-cards/OfferCardsContainer.js';
import HowItWorksSection from '../common/how-it-works-section/HowItWorksSection.js';
import TrustMetricsSection from './trust-metrics/TrustMetricsSection.js';
import TopSpecialityCardsContainer from './top-speciality-cards/TopSpecialityCardsContainer.js';
import TestimonialCards from '../common/testimonial-cards/TestimonialCards.js';
import FaqSection from '../common/faq-section/FaqSection.js';
import AppDownloadSection from './app-download-section/AppDownloadSection.js';
import SeoSection from '../common/seo-section/SeoSection.js';
import Footer from '../common/footer/Footer.js';
import NavWrapper from '../common/Nav/NavWrapper.js';
import LoginModalContainer from '../common/login-modal/LoginModalContainer.js';
import HomeStickyFooterContainer from './home-sticky-footer/HomeStickyFooterContainer.js';
import TrackOnceInViewContainer from '../common/TrackOnceInViewContainer.js';
import OurDoctors from './our-doctors/OurDoctors';
import HealthConcern from './health-concern-section/HealthConcern.js';
import HealthQueries from './health-queries/HealthQueries';
import Benefits from './benefits/Benefits';
import {
  PEL_OBJECTS,
  PEL_ACTIONS,
  PEL_EXPERIMENTS,
} from '../../constants/PELConstants.js';
import { sendPELEventAction } from '../../redux/ducks/analytics/analyticsSaga.js';
import { createExperimentTrackingParams } from '../../utilities/analytics/PELUtils.js';
import { Title, MetaDescription } from '../common/Head/Head.js';
import { fetchProblemAreas } from '../../redux/ducks/problem-areas/problemAreas';
import { fetchAvailableDoctors } from '../../redux/ducks/available-doctors/availableDoctors';
import { loadSeoRecentQuestions } from '../../redux/ducks/seo/seo.js';
import CanonicalLink from '../common/Head/CanonicalLink.js';
import { AMP_HOMEPAGE_URL } from '../../routes.js';
// import CoronaLink from '../common/CoronaLink.js';
import { defaultHomepageConstants } from '../../constants/homepageConstants.js';

import DeviceContainer from '../common/DeviceContainer.js';

const CTA_ENABLED_SECTION = [
  'OfferCardsContainer',
  'HowItWorksSection',
  'TrustMetricsSection',
  'TestimonialCards',
  'FaqSection',
  'BenefitsSection',
  'ConsultationExperienceVideos',
];

const PROBLEM_AREA_ID_FOR_OUR_DOCTORS = [2, 16, 5, 14, 18, 6, 28, 8];

class Homepage extends React.Component {
  static serverFetch(store) {
    store.dispatch(fetchProblemAreas());
    store.dispatch(loadSeoRecentQuestions());
    store.dispatch(
      fetchAvailableDoctors(PROBLEM_AREA_ID_FOR_OUR_DOCTORS, 8, 0)
    );
  }

  constructor(props) {
    super(props);
    this.renderSections = this.renderSections.bind(this);
    this.getSections = this.getSections.bind(this);
    this.handleStickyFooterVisibility = this.handleStickyFooterVisibility.bind(
      this
    );
    this.redirectIfUserHasConsultations = this.redirectIfUserHasConsultations.bind(
      this
    );
    this.getSectionObjectContext = this.getSectionObjectContext.bind(this);
    this.sectionObjectContext = {};

    this.state = {
      isStickyFooterVisible: false,
    };
  }

  componentDidMount() {
    this.props.sendPELEventAction(
      PEL_OBJECTS.consultHome,
      PEL_ACTIONS.viewed,
      createExperimentTrackingParams(
        PEL_EXPERIMENTS.consultHomepage.name,
        PEL_EXPERIMENTS.consultHomepage.group
      )
    );

    this.redirectIfUserHasConsultations();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.directConsultationCount !== this.props.directConsultationCount
    ) {
      this.redirectIfUserHasConsultations();
    }
  }

  redirectIfUserHasConsultations() {
    const newConsultVersion = cookies.get('consult_new_consultation_version');
    if (this.props.directConsultationCount > 0) {
      window.location.replace(
        newConsultVersion === 'new'
          ? '/consult/direct/new_consultation'
          : '/consult/direct/chat'
      );
    }
  }

  getSections() {
    return [
      <FirstFold
        key="FirstFold"
        sectionType="Core"
        onCtaClick={() => {
          this.props.sendPELEventAction(
            PEL_OBJECTS.consultHomeCard,
            PEL_ACTIONS.interacted,
            this.getSectionObjectContext('Core')
          );
        }}
      />,
      <TopSpecialityCardsContainer
        key="TopSpecialityCardsContainer"
        sectionType="Specialities"
        getSectionObjectContext={this.getSectionObjectContext}
      />,
      <HealthProblemCardsContainer
        key="HealthProblemCardsContainer"
        sectionType="Symptom"
        getSectionObjectContext={this.getSectionObjectContext}
      />,
      <OfferCardsContainer
        key="OfferCardsContainer"
        sectionType="Offers"
        getSectionObjectContext={this.getSectionObjectContext}
      />,
      <OurDoctors
        key="OurDoctors"
        sectionType="OurDoctors"
        doctors={this.props.ourDoctors}
        getSectionObjectContext={this.getSectionObjectContext}
      />,
      <HowItWorksSection
        key="HowItWorksSection"
        sectionType="How it works"
        {...defaultHomepageConstants.howItWorks}
        getSectionObjectContext={this.getSectionObjectContext}
      />,
      <TrustMetricsSection
        key="TrustMetricsSection"
        sectionType="Trust Metrics"
        getSectionObjectContext={this.getSectionObjectContext}
      />,
      <Benefits key="BenefitsSection" sectionType="Benefits" />,
      <VideoCardsContainer
        key="ConsultationExperienceVideos"
        sectionType="ConsultationExperienceVideos"
        {...defaultHomepageConstants.consultExperienceVideo}
      />,
      <DeviceContainer
        key="TestimonialCards"
        sectionType="TestimonialCardsSection"
      >
        {device => (
          <TestimonialCards type={device.isDesktop ? 'slider' : null} />
        )}
      </DeviceContainer>,
      <FaqSection
        key="FaqSection"
        sectionType="FAQ"
        getSectionObjectContext={this.getSectionObjectContext}
      />,
      <HealthQueries
        key="HealthQueriesSection"
        sectionType="Health Queries"
        recentQuestions={this.props.recentQuestions}
      />,
      <HealthConcern
        key="HealthConcernSection"
        sectionType="HealthConcern"
        {...defaultHomepageConstants.healthConcern}
        getSectionObjectContext={this.getSectionObjectContext}
      />,
      <AppDownloadSection
        key="AppDownloadSection"
        sectionType="App Install"
        getSectionObjectContext={this.getSectionObjectContext}
      />,
      <SeoSection
        key="SeoSection"
        sectionType="SEO"
        recentQuestions={this.props.recentQuestions}
        getSectionObjectContext={this.getSectionObjectContext}
      />,
    ];
  }

  getSectionObjectContext(sectionType) {
    return this.sectionObjectContext[sectionType] || {};
  }

  renderSections() {
    return this.getSections().map((section, index) => {
      const sectionType = section.props.sectionType;
      const thisObjectContext = {
        'Section Position': index + 1,
        'Section Type': sectionType,
      };

      this.sectionObjectContext[sectionType] = thisObjectContext;

      return (
        <VisibilitySensor
          key={sectionType}
          onChange={this.handleStickyFooterVisibility.bind(null, section.key)}
        >
          <TrackOnceInViewContainer
            key={section.key}
            object={PEL_OBJECTS.consultHomeSection}
            action={PEL_ACTIONS.viewed}
            objectContext={thisObjectContext}
          >
            <div id={section.key}>{section}</div>
          </TrackOnceInViewContainer>
        </VisibilitySensor>
      );
    });
  }

  handleStickyFooterVisibility(section, isVisible) {
    if (isVisible && CTA_ENABLED_SECTION.indexOf(section) > -1) {
      this.setState({
        isStickyFooterVisible: true,
      });
    } else if (CTA_ENABLED_SECTION.indexOf(section) === -1) {
      this.setState({
        isStickyFooterVisible: false,
      });
    }
  }

  render() {
    return (
      <div className="homepage">
        <Title>
          Online Doctor Consultation | Ask Top Doctor&apos;s Advice 24*7 |
          Practo
        </Title>
        <MetaDescription>
          Online Doctor Consultations with 20000+ Doctors through chat, call &
          get answers to all your medical issues online. Talk to a doctor Online
          24X7 from anywhere in India & abroad only on Practo *5 minute response
          time *verified doctors *private and confidential
        </MetaDescription>

        <CanonicalLink url={AMP_HOMEPAGE_URL} />
        <LoginModalContainer key="LoginModalContainer" />
        <NavWrapper key="NavContainer" routeId={this.props.routeId} />
        {this.renderSections()}
        <Footer key="Footer" />
        <HomeStickyFooterContainer
          isVisible={this.state.isStickyFooterVisible}
        />

        {/* <CoronaLink utm="consult_homepage" /> */}
      </div>
    );
  }
}

Homepage.propTypes = {
  directConsultationCount: PropTypes.number,
  routeId: PropTypes.string,
  sendPELEventAction: PropTypes.func.isRequired,
  recentQuestions: PropTypes.array,
  ourDoctors: PropTypes.array,
};

const mapStateToProps = state => {
  return {
    directConsultationCount: state.user.direct_consultation_count,
    recentQuestions: state.seo.recentQuestions,
    ourDoctors: state.availableDoctors.doctors,
  };
};

const mapDispatchToProps = dispatch => ({
  sendPELEventAction: (...args) => dispatch(sendPELEventAction(...args)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Homepage);
