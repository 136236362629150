import cookies from 'js-cookie';
import {
  getProfileToken,
  getUserProfile,
  getAppSource,
} from './thirdPartyAppUtils';

function getConsultAuthData() {
  const cookieData = cookies.get('consult_auth_data_v2');
  const profile_token = getProfileToken();

  if (profile_token) {
    return {
      SOURCE: getAppSource(),
      PROFILE_TOKEN: profile_token,
      PRACTO_ACCOUNT_ID: getUserProfile().id || '',
    };
  }

  if (!cookieData) {
    return {};
  }

  const parsedCookieData = JSON.parse(cookieData);
  return {
    CONSULT_TOKEN: parsedCookieData.consult_token,
    PRACTO_ACCOUNT_ID: parsedCookieData.practo_account_id,
  };
}

export default getConsultAuthData;
