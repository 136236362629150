import React from 'react';
import SolidBackground from '../../common/solid-background/SolidBackground.js';
import './trustMetricsSection.scss';

function TrustMetricsSection() {
  return (
    <SolidBackground color="black">
      <div className="trust-metrics-section desktop-wrapper">
        <div className="each-metric">
          <p className="metric-count">2,00,000+</p>
          <p className="metric-name">Happy Users</p>
        </div>

        <div className="each-metric">
          <p className="metric-count">20,000+</p>
          <p className="metric-name">Verified Doctors</p>
        </div>

        <div className="each-metric">
          <p className="metric-count">25+</p>
          <p className="metric-name">Specialities</p>
        </div>

        <div className="each-metric">
          <p className="metric-count">
            4.5 / <sub>5</sub>
          </p>
          <p className="metric-name">App Rating</p>
        </div>
      </div>
    </SolidBackground>
  );
}

export default TrustMetricsSection;
