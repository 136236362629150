import React from 'react';
import PropTypes from 'prop-types';

import './solidBackground.scss';

function SolidBackground({ color, children }) {
  return <div className={color}>{children}</div>;
}

SolidBackground.propTypes = {
  color: PropTypes.oneOf(['pink', 'purple', 'grey', 'dark-blue', 'black']),
  children: PropTypes.any,
};

export default SolidBackground;
