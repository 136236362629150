import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import OnlineDoctorsCard from './OnlineDoctorsCard.js';
import OnlineDoctorsStrip from './OnlineDoctorsStrip.js';
import { fetchOnlineDoctors } from '../../../redux/ducks/online-doctors/onlineDoctors.js';

class OnlineDoctorsCardContainer extends React.Component {
  componentDidMount() {
    if (this.props.doctors.length === 0) {
      this.props.fetchOnlineDoctors();
    }
  }

  render() {
    if (this.props.doctors.length === 0) {
      return null;
    }

    switch (this.props.type) {
      case 'strip': {
        const DOCTORS_LIMIT = 3;
        const limitedDoctors = this.props.doctors.filter((doctor, index) => {
          return index < DOCTORS_LIMIT;
        });

        return <OnlineDoctorsStrip {...this.props} doctors={limitedDoctors} />;
      }
      default:
        return <OnlineDoctorsCard {...this.props} />;
    }
  }
}

OnlineDoctorsCardContainer.propTypes = {
  doctors: PropTypes.array,
  type: PropTypes.string,
  fetchOnlineDoctors: PropTypes.func,
};

const mapStateToProps = state => ({
  additionalDoctorsCount: state.onlineDoctors.additionalDoctorsCount,
  description: state.onlineDoctors.description,
  doctors: state.onlineDoctors.doctors,
  heading: state.onlineDoctors.heading,
  showOnline: state.onlineDoctors.showOnline,
});

const mapDispatchToProps = dispatch => ({
  fetchOnlineDoctors: () => dispatch(fetchOnlineDoctors()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OnlineDoctorsCardContainer);
