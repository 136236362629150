import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import './searchBar.scss';

function SearchBar({ type, initialValue, onChange, onClick, autoFocus }) {
  // the input value is not controlled by the parent directly as the parent might choose to debounce the
  // input value in which case, the input functionality shouldn't be debounced i.e user typing & cross behaviour
  const [value, setValue] = useState(initialValue || '');
  const searchInputRef = useRef(null);

  useEffect(() => {
    if (autoFocus) {
      searchInputRef.current.focus();
    }
  }, [autoFocus]);

  useEffect(() => {
    onChange && onChange(value);
  }, [value, onChange]);

  function onCrossClick() {
    setValue('');
    onChange('');
    searchInputRef.current.focus();
  }

  return (
    <div className="search-bar" onClick={onClick}>
      <i className="icon-ic_search_system search-bar__icon-search" />
      {type === 'static' ? (
        <span className="search-bar__static-input">Eg. Cold, cough, fever</span>
      ) : (
        <input
          className="search-bar__input"
          type="text"
          value={value}
          onChange={event => setValue(event.target.value)}
          autoCapitalize="off"
          autoComplete="off"
          placeholder="Eg. Cold, cough, fever"
          ref={searchInputRef}
        />
      )}
      {value && (
        <i
          className="icon-ic_cross_solid search-bar__icon-cross"
          onClick={onCrossClick}
        />
      )}
    </div>
  );
}

SearchBar.propTypes = {
  type: PropTypes.oneOf(['static']),
  initialValue: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  autoFocus: PropTypes.bool,
};

export default SearchBar;
