import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '../../common/button/Button';
import Input from '../../common/input/Input';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  updateCoupon,
  fetchUserCoupons,
} from '../../../redux/ducks/payments/payments';
import AvailableCoupons from './AvailableCoupons';
import FullScreenModal from '../../common/modal/FullScreenModal.js';
import './CouponModal.scss';

const CouponModal = props => {
  const dispatch = useDispatch();

  const { couponErrMessage, userCoupons } = useSelector(
    state => ({
      couponErrMessage: state.payments.couponErrMessage,
      userCoupons: state.payments.userCoupons,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (props.isCouponModalOpen) {
      dispatch(fetchUserCoupons());
    }
  }, [dispatch, props.isCouponModalOpen]);

  return (
    <FullScreenModal
      isOpen={props.isCouponModalOpen}
      onRequestClose={props.closeCouponModal}
      contentLabel="Enter Coupon Code"
      className="coupon-modal"
    >
      <div className="coupon__form">
        <div className="coupon__header">
          <div className="coupon__heading">COUPON CODE</div>
          <i
            className="icon-ic_cross coupon__cross"
            onClick={props.closeCouponModal}
          />
        </div>
        <Input
          name="coupon-input"
          value={props.couponCode}
          onChange={code => dispatch(updateCoupon(code))}
          placeholder="Enter Coupon Code"
        />
        {couponErrMessage && (
          <div className="coupon__error-msg">{couponErrMessage}</div>
        )}
        <Button
          className="coupon-modal__apply-button"
          type="primary"
          onClick={() => props.applyCoupon()}
        >
          Apply
        </Button>
      </div>
      <AvailableCoupons userCoupons={userCoupons} />
    </FullScreenModal>
  );
};

CouponModal.propTypes = {
  isCouponModalOpen: PropTypes.bool,
  closeCouponModal: PropTypes.func,
  couponCode: PropTypes.string,
  applyCoupon: PropTypes.func,
};

export default CouponModal;
