import consultRequestCreator from '../../consultRequestCreator';
import urlConstants from '../../../constants/urlConstants.js';

export function fetchLoggedInUser() {
  return consultRequestCreator
    .get(urlConstants.LOGGED_IN_USER, { baseURL: null })
    .then(response => response.data);
}

export function fetchMicroAppUser() {
  return consultRequestCreator
    .get(urlConstants.MICROAPP_USER_PROFILE, { baseURL: null })
    .then(({ data }) => {
      const profile = {
        age: null,
        user_role: '',
        accountId: data.id,
        user_name: data.name,
        is_test_account: null,
        free_question_count: 0,
        direct_consultation_count: 0,
        ...data, // override above fields if present in payload data
      };

      // same user_profile can be used in consult-web session
      // so storing in session storage
      sessionStorage.setItem('user_profile', JSON.stringify(profile));
      return profile;
    });
}
