import { useState, useEffect } from 'react';

/*
  Ex:
  validation: {
    minlength: {
      value: PropTypes.number,
      error: PropTypes.string,
    },
    maxlength: {
      value: PropTypes.number,
      error: PropTypes.string,
    },
    pattern: {
      value: PropTypes.instanceOf(RegExp),
      error: PropTypes.string,
    },
  }
*/
function useValidator(value, validation) {
  const [error, setError] = useState(null);
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    if (!validation) {
      return;
    }

    function validateInput() {
      const cleanedInputValue = value.trim();
      let isInputValid = false;

      if (
        validation.minlength &&
        (!cleanedInputValue ||
          cleanedInputValue.length < validation.minlength.value)
      ) {
        setError(validation.minlength.error);
      } else if (
        validation.maxlength &&
        cleanedInputValue.length > validation.maxlength.value
      ) {
        setError(validation.maxlength.error);
      } else if (
        validation.pattern &&
        !validation.pattern.value.test(cleanedInputValue)
      ) {
        setError(validation.pattern.error);
      } else {
        setError(null);
        isInputValid = true;
      }

      setIsValid(isInputValid);
    }

    validateInput(value, validation);
  }, [value, validation]);

  if (!validation) {
    return [null, null];
  }

  return [isValid, error];
}

export default useValidator;
