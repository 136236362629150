import React from 'react';
import PropTypes from 'prop-types';
import ToolTip from '../../common/tooltip/ToolTip.js';
import './feeSplit.scss';

function FeeSplit({
  couponAmount,
  currencySymbol,
  showHealthCash,
  healthCashAmount,
  fee,
  practoWalletBalanceNote,
}) {
  const getHealthCashRow = () => {
    return healthCashAmount > 0 ? (
      <div className="each-row healthcash">
        <span className="amount">
          {' '}
          - {currencySymbol}
          {healthCashAmount}
        </span>
        <span className="text">
          HealthCash
          {practoWalletBalanceNote && (
            <ToolTip>{practoWalletBalanceNote}</ToolTip>
          )}
        </span>
      </div>
    ) : null;
  };

  const getCouponDiscountRow = () => {
    return couponAmount > 0 ? (
      <div className="each-row coupon">
        <span className="amount">
          {' '}
          - {currencySymbol}
          {couponAmount}
        </span>
        <div className="text">
          <span>Coupon Discount</span>
        </div>
      </div>
    ) : null;
  };

  return (
    <div className="payment-distribution">
      <div className="each-row">
        <span className="amount">
          {!currencySymbol ? '...' : currencySymbol + fee}
        </span>
        <span className="text">Consultation Fees</span>
      </div>
      {showHealthCash && getHealthCashRow()}
      {getCouponDiscountRow()}
    </div>
  );
}

FeeSplit.propTypes = {
  currencySymbol: PropTypes.string,
  fee: PropTypes.number,
  showHealthCash: PropTypes.bool,
  couponAmount: PropTypes.number,
  healthCashAmount: PropTypes.number,
  practoWalletBalanceNote: PropTypes.string,
};

export default FeeSplit;
