import React from 'react';

function InlineNav({ hideLogo }) {
  const inlineNavSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="28" height="16" viewBox="0 0 28 16"><g fill="none" fill-rule="evenodd"><path fill="#2FB7DE" d="M6 6a3 3 0 1 0-5.999-.001A3 3 0 0 0 6 6m22 0a3 3 0 1 0-5.999-.001A3 3 0 0 0 28 6"/><path fill="#2A3072" d="M14 9c-1.657 0-3-1.206-3-3s1.343-3 3-3 3 1.197 3 3-1.343 3-3 3zm.487-9c-1.503 0-2.574.577-3.377 1.35v-1H8V16h3.23v-5.111c.803.801 1.865 1.351 3.257 1.351C17.373 12.24 20 9.792 20 6.12S17.348 0 14.487 0z"/></g></svg>`;

  if (hideLogo) {
    return (
      <a
        href="https://www.practo.com"
        dangerouslySetInnerHTML={{ __html: inlineNavSvg }}
      />
    );
  } else {
    return '';
  }
}

export default InlineNav;
