import { call, put, select, takeLatest } from 'redux-saga/effects';
import { fetchLoggedInUser, fetchMicroAppUser } from './userApi.js';
import { selectFeatureMapState } from '../selectors';

import {
  LOAD_USER_SUCCESS,
  LOAD_USER_FAILURE,
  LOAD_USER,
  updateApiStatus,
} from './user.js';

export function* loadUser() {
  yield put(updateApiStatus('PENDING'));
  try {
    const featureMap = yield select(selectFeatureMapState);
    const fetchUserDetails = featureMap.app
      ? fetchMicroAppUser
      : fetchLoggedInUser;
    const loggedInUser = yield call(fetchUserDetails);

    yield put({
      type: LOAD_USER_SUCCESS,
      payload: loggedInUser,
    });
  } catch (error) {
    yield put({
      type: LOAD_USER_FAILURE,
    });
  }
}

export default function* userSaga() {
  yield takeLatest(LOAD_USER, loadUser);
}
