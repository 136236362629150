import React, { useState } from 'react';
import PropTypes from 'prop-types';
import VisibilitySensor from 'react-visibility-sensor';
import { useSelector, shallowEqual } from 'react-redux';
import { getImageUrl } from '../../utilities/assetsHelper.js';

const LOADING = {
  EAGER: 'eager',
  LAZY: 'lazy',
};

const LazyImg = ({ src, loading, layout, ...props }) => {
  const partialVisibilityOffset = -200;
  const loadingImage = getImageUrl('blank-background-v1.png');

  const { isAmp } = useSelector(
    state => ({ isAmp: state.device.isAmp }),
    shallowEqual
  );

  const [imageSrc, setImageSrc] = useState(loadingImage);
  const [isVisibilitySensorActive, setVisibilitySensor] = useState(true);

  const onChange = isVisible => {
    if (isVisible) {
      setImageSrc(src);
      setVisibilitySensor(false);
    }
  };

  if (isAmp) {
    return <amp-img {...{ src, layout, ...props }} />;
  }

  if (loading === LOADING.EAGER) {
    return <img {...{ src, ...props }} />;
  }

  return (
    <VisibilitySensor
      partialVisibility
      onChange={onChange}
      active={isVisibilitySensorActive}
      offset={{
        top: partialVisibilityOffset,
        left: partialVisibilityOffset,
      }}
    >
      <img src={imageSrc} {...props} />
    </VisibilitySensor>
  );
};

LazyImg.propTypes = {
  lazy: PropTypes.bool,
  src: PropTypes.string,
  alt: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  layout: PropTypes.string,
  loading: PropTypes.oneOf(Object.values(LOADING)),
};

export default LazyImg;
