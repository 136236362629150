import React from 'react';
import { getImageUrl } from '../../../utilities/assetsHelper.js';
import Link from '../../common/link/Link.js';
import DeviceContainer from '../../common/DeviceContainer.js';
import SendAppLinkContainer from './SendAppLinkContainer.js';
import SolidBackground from '../../common/solid-background/SolidBackground.js';
import LazyImg from '../../common/LazyImg.js';
import './appDownloadSection.scss';

function AppDownloadSection() {
  return (
    <DeviceContainer>
      {device => (
        <SolidBackground color="grey">
          <div className="app-download-section desktop-wrapper">
            <figure>
              {device.isDesktop ? (
                <LazyImg
                  alt="practo app download banner"
                  src={getImageUrl('app-download-banner-web.png')}
                  width="300"
                />
              ) : (
                <LazyImg
                  alt="practo app download banner"
                  src={getImageUrl('app-download-banner-mobile.png')}
                  width="204"
                  height="238"
                  layout="responsive"
                />
              )}
            </figure>
            <div className="content">
              <h2 className="heading">Download the Practo app</h2>
              <p className="description">Get ₹200 HealthCash</p>
              <ul>
                <li>
                  <i className="icon-ic_done_open_system" />
                  Video consult with Doctors
                </li>
                <li>
                  <i className="icon-ic_done_open_system" />
                  Live medicine order tracking
                </li>
                <li>
                  <i className="icon-ic_done_open_system" />
                  Exclusive healthcare packages
                </li>
                <li>
                  <i className="icon-ic_done_open_system" />
                  Ask free questions
                </li>
              </ul>
              {device.isDesktop ? (
                <React.Fragment>
                  <SendAppLinkContainer />
                  <div className="store-links">
                    <Link
                      href="https://app.appsflyer.com/com.practo.fabric?pid=web&c=consult_homepage_v2_app_section"
                      target="_blank"
                    >
                      <LazyImg
                        className="google-play-badge"
                        width="130"
                        height="40"
                        alt="google play store"
                        src={getImageUrl('google-play-badge.png')}
                      />
                    </Link>
                    <Link
                      href="https://app.appsflyer.com/id953772015?pid=web&c=consult_homepage_v2_app_section"
                      target="_blank"
                    >
                      <LazyImg
                        width="130"
                        height="40"
                        alt="app store"
                        src={getImageUrl('app-store-badge.png')}
                      />
                    </Link>
                  </div>
                </React.Fragment>
              ) : (
                <Link
                  className="download-app-button"
                  type="primary-button"
                  href="https://practo.onelink.me/1025359428?pid=mweb&c=consult_homepage_v2_app_section"
                  target="_blank"
                >
                  Download the app now
                </Link>
              )}
            </div>
          </div>
        </SolidBackground>
      )}
    </DeviceContainer>
  );
}

export default AppDownloadSection;
