import { connect } from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';
import LoginModal from './LoginModal.js';
import ConsultLoginModal from './ConsultLoginModal.js';
import { getProfileToken } from '../../../utilities/thirdPartyAppUtils';
import {
  showConsultLogin,
  closeLoginModal,
  notifyLoginSuccessful,
} from '../../../redux/ducks/login-modal/loginModal.js';
import {
  loadUser,
  updateUserLoggedIn,
} from '../../../redux/ducks/user/user.js';
import { loadConsultUserProfile } from '../../../redux/ducks/consult-user-profile/consultUserProfile.js';
import cookies from 'js-cookie';

const mapStateToProps = (state, ownProps) => {
  return {
    ...state.loginModal,
    ...ownProps,
  };
};

const mapDispatchToProps = dispatch => ({
  notifyLoginSuccessful: () => dispatch(notifyLoginSuccessful()),
  showConsultLogin: (...args) => dispatch(showConsultLogin(...args)),
  closeLoginModal: () => dispatch(closeLoginModal()),
  loadUser: () => dispatch(loadUser()),
  updateUserLoggedIn: (...args) => dispatch(updateUserLoggedIn(...args)),
  loadConsultUserProfile: (...args) =>
    dispatch(loadConsultUserProfile(...args)),
});

class LoginModalContainer extends React.Component {
  componentDidMount() {
    const isLoggedIntoMicroApp = getProfileToken();
    const isLoggedIntoAccounts = cookies.get('sso');
    const isLoggedIntoConsult = cookies.get('consult_auth_data_v2');

    if (isLoggedIntoMicroApp || (isLoggedIntoAccounts && isLoggedIntoConsult)) {
      this.props.loadUser();
      this.props.loadConsultUserProfile();
    } else if (isLoggedIntoAccounts && !isLoggedIntoConsult) {
      this.props.showConsultLogin(true);
    } else if (!isLoggedIntoAccounts) {
      this.props.updateUserLoggedIn(false);
    }
  }

  onLoginSuccess = () => {
    this.props.closeLoginModal();
    this.props.loadUser();
    this.props.loadConsultUserProfile();
    this.props.notifyLoginSuccessful();
  };

  renderModalByType = () => {
    switch (this.props.type) {
      case 'ACCOUNTS':
        return (
          <LoginModal
            type={this.props.subtype}
            name={this.props.name}
            mobile={this.props.mobile}
            onDone={this.props.showConsultLogin}
            closeModal={this.props.closeLoginModal}
          />
        );
      case 'CONSULT':
        return (
          <ConsultLoginModal
            onDone={this.onLoginSuccess}
            silent={this.props.silent}
          />
        );
    }
  };

  render() {
    return (
      <React.Fragment>
        {this.props.isVisible && this.renderModalByType()}
      </React.Fragment>
    );
  }
}

LoginModalContainer.propTypes = {
  type: PropTypes.string,
  subtype: PropTypes.string,
  name: PropTypes.string,
  mobile: PropTypes.string,
  showConsultLogin: PropTypes.func,
  closeLoginModal: PropTypes.func,
  isVisible: PropTypes.bool,
  updateUserLoggedIn: PropTypes.func,
  notifyLoginSuccessful: PropTypes.func,
  loadUser: PropTypes.func,
  silent: PropTypes.bool,
  loadConsultUserProfile: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginModalContainer);
