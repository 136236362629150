import React from 'react';
import PropTypes from 'prop-types';
import './onlineDoctorsCard.scss';

const OnlineDoctorsStrip = props => (
  <article className="instant-doctors-card strip">
    <div className="doctor-images">
      {props.doctors.map((picUrl, index) => (
        <span className="each-image" key={index}>
          <img alt="doctor" src={picUrl} />
        </span>
      ))}
      <span className="each-image count">
        +{props.additionalDoctorsCount} Doctors are online{' '}
        <span className="pulse" />
      </span>
    </div>
  </article>
);

OnlineDoctorsStrip.propTypes = {
  doctors: PropTypes.array,
  additionalDoctorsCount: PropTypes.number,
};

export default OnlineDoctorsStrip;
