import React from 'react';
import PropTypes from 'prop-types';

const SliderAmp = props => {
  return (
    <amp-carousel
      width={props.width}
      height={props.height}
      layout="fixed"
      type="carousel"
    >
      {props.slides}
    </amp-carousel>
  );
};

SliderAmp.propTypes = {
  slides: PropTypes.array,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default SliderAmp;
