import { featureMap } from './featureMapApi';
const LOAD_FEATURE_MAP = 'LOAD_FEATURE_MAP';

const initialState = {};

export default function featureMapReducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_FEATURE_MAP:
      return { ...action.payload };

    default:
      return { ...state };
  }
}

export const loadFeatureMap = userAgent => ({
  type: LOAD_FEATURE_MAP,
  payload: featureMap(userAgent),
});
