import React from 'react';
import './concernSection.scss';
import Link from '../../common/link/Link.js';
import PropTypes from 'prop-types';
import { getTopicPageUrlBySlug } from '../../../utilities/urlHelper';
import {
  SectionWrapper,
  SectionHeading,
  SectionParagraph,
} from '../../common/section/Section.js';
import SolidBackground from '../../common/solid-background/SolidBackground.js';

function ConcernSection({ heading, description, topics, isSemTargeted }) {
  let listTopics = topics;

  return (
    <SolidBackground color="dark-blue">
      <section className="concern">
        <SectionWrapper>
          <SectionHeading className="concern__heading">
            {heading}
          </SectionHeading>
          <SectionParagraph className="concern__description">
            {description}
          </SectionParagraph>

          <ul className="concern__list-section">
            {getTimeBasedDataSet(listTopics, 6).map(topic => (
              <Link
                style={{ border: 'none' }}
                key={topic.slug}
                className="concern__list-item"
                type="secondary-black-button"
                href={isSemTargeted ? null : getTopicPageUrlBySlug(topic.slug)}
                tagName={isSemTargeted ? 'span' : 'a'}
              >
                {topic.name}
              </Link>
            ))}
          </ul>
        </SectionWrapper>
      </section>
    </SolidBackground>
  );
}

/**
 * Return different set of topics every day
 *
 * For ex:
 * getTimeBasedDataSet([ 1, 2, 3, 4 ], 2)
 * Will return different set every other day out of
 * [1, 2] & [3, 4]
 */
function getTimeBasedDataSet(data, eachSetCount) {
  let currentDate = new Date().getDate();
  let topicsStartIndexes = [];
  for (let i = 0; i < data.length; i += eachSetCount) {
    topicsStartIndexes.push(i);
  }

  const topicsStartIndex =
    topicsStartIndexes[currentDate % topicsStartIndexes.length];

  return data.slice(topicsStartIndex, topicsStartIndex + eachSetCount);
}

ConcernSection.propTypes = {
  heading: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  topics: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    })
  ).isRequired,
  isSemTargeted: PropTypes.bool,
};

export default ConcernSection;
