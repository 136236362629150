import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import './stickyFooter.scss';

function StickyFooter({ isVisible, children, className, height }) {
  if (!isVisible) {
    return null;
  }

  return (
    <React.Fragment>
      <div className="sticky-footer-blank-space" style={{ height }} />
      <div
        className={classnames('sticky-footer', className)}
        style={{ height }}
      >
        {children}
      </div>
    </React.Fragment>
  );
}

StickyFooter.propTypes = {
  isVisible: PropTypes.bool,
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  height: PropTypes.string,
};

export default StickyFooter;
