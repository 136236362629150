import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import AvailableDoctors from './AvailableDoctors';
import { fetchAvailableDoctors } from '../../redux/ducks/available-doctors/availableDoctors';
import useTrackEvent from '../../hooks/useTrackEvent';
import { PEL_OBJECTS } from '../../constants/PELConstants';

function AvailableDoctorsContainer({
  problemAreaId,
  disableInfiniteScroll,
  disableCta,
  children,
}) {
  const dispatch = useDispatch();
  const availableDoctors = useSelector(
    state => state.availableDoctors,
    shallowEqual
  );
  const PER_PAGE_COUNT = 10;

  const loadMore = useCallback(
    (limit, offset) => {
      dispatch(fetchAvailableDoctors([problemAreaId], limit, offset));
    },
    [dispatch, problemAreaId]
  );

  const [
    trackAvailableDoctorsView,
    trackAvailableDoctorsInteracted,
  ] = useTrackEvent(PEL_OBJECTS.consultAsk, {
    'Page Name': 'Available Doctors',
  });

  // Load initial set & refresh on problem area id change
  useEffect(() => {
    if (availableDoctors.doctors.length === 0) {
      loadMore(PER_PAGE_COUNT, 0);
    }
    trackAvailableDoctorsView({ 'Problem Area Id': problemAreaId });
  }, [problemAreaId, loadMore, trackAvailableDoctorsView]);

  if (!problemAreaId) {
    return null;
  }

  if (availableDoctors.doctors.length === 0) {
    return null;
  }
  return (
    <div className="available-doctors">
      {children({
        heading: 'Consult with verified, top doctors',
        subtext: availableDoctors.subtext,
        cards: (
          <AvailableDoctors
            {...availableDoctors}
            loadMore={loadMore}
            disableCta={disableCta}
            perPageCount={PER_PAGE_COUNT}
            disableInfiniteScroll={disableInfiniteScroll}
            trackAvailableDoctorsInteracted={trackAvailableDoctorsInteracted}
          />
        ),
      })}
    </div>
  );
}

AvailableDoctorsContainer.propTypes = {
  children: PropTypes.func,
  heading: PropTypes.string,
  subtext: PropTypes.string,
  problemAreaId: PropTypes.number,
  disableInfiniteScroll: PropTypes.bool,
  disableCta: PropTypes.bool,
};

export default AvailableDoctorsContainer;
