import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as availableDoctorsApi from './availableDoctorsApi';
import {
  FETCH_AVAILABLE_DOCTORS_SUCCESS,
  FETCH_AVAILABLE_DOCTORS_FAILURE,
  FETCH_AVAILABLE_DOCTORS,
} from './availableDoctors';

export function* fetchAvailableDoctors(action) {
  try {
    const { problemAreaId, limit, offset } = action.payload;
    const response = yield call(
      availableDoctorsApi.fetchAvailableDoctors,
      problemAreaId,
      limit,
      offset
    );

    yield put({
      type: FETCH_AVAILABLE_DOCTORS_SUCCESS,
      payload: response,
    });
  } catch (err) {
    put({
      type: FETCH_AVAILABLE_DOCTORS_FAILURE,
    });
  }
}

export default function* availableDoctorsSaga() {
  yield all([takeLatest(FETCH_AVAILABLE_DOCTORS, fetchAvailableDoctors)]);
}
