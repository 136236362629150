import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {
  updateCoupon,
  applyCoupon,
} from '../../../redux/ducks/payments/payments';

const CouponCard = ({ coupon }) => {
  const dispatch = useDispatch();
  return (
    <div className="coupon-card">
      <div className="coupon-card__heading">{coupon.title}</div>
      <div className="coupon-card__sub-heading">{coupon.description}</div>
      <div className="coupon-card__details">
        <div className="coupon-card__coupon-code">{coupon.coupon_code}</div>
        <div
          className="coupon-card__use-coupon"
          onClick={() => {
            dispatch(updateCoupon(coupon.coupon_code));
            dispatch(applyCoupon(coupon.coupon_code));
          }}
        >
          USE OFFER
        </div>
      </div>
    </div>
  );
};

CouponCard.propTypes = {
  coupon: PropTypes.string,
};

export default CouponCard;
