import { call, put, takeLatest } from 'redux-saga/effects';
import { fetchSelfProfile } from './consultUserProfileApi.js';

import {
  LOAD_CONSULT_USER_PROFILE_SUCCESS,
  LOAD_CONSULT_USER_PROFILE_FAILURE,
  LOAD_CONSULT_USER_PROFILE,
  updateApiStatus,
} from './consultUserProfile.js';

export function* loadConsultUserProfile() {
  yield put(updateApiStatus('PENDING'));
  try {
    const response = yield call(fetchSelfProfile);
    yield put({
      type: LOAD_CONSULT_USER_PROFILE_SUCCESS,
      payload: response,
    });
  } catch (error) {
    yield put({
      type: LOAD_CONSULT_USER_PROFILE_FAILURE,
    });
  }
}

export default function* consultUserProfileSaga() {
  yield takeLatest(LOAD_CONSULT_USER_PROFILE, loadConsultUserProfile);
}
