export const PEL_ACTIONS = {
  viewed: 'Viewed',
  interacted: 'Interacted',
};

export const PEL_OBJECTS = {
  consultHome: 'Consult Home',
  consultHomeSection: 'Consult Home Section',
  consultHomeCard: 'Consult Home Card',
  consultAsk: 'Consult Ask',
};

export const PEL_EXPERIMENTS = {
  consultHomepage: {
    name: 'Consult Homepage',
    group: 'New',
  },
};

export const PEL_PAGE_NAMES = {
  doctorNotFound: 'Doctor Not Found',
};
