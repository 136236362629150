import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import VisibilitySensor from 'react-visibility-sensor';
import classnames from 'classnames';

function InfiniteScrollLoader({
  loadMore,
  perPageCount,
  loader,
  dataCount,
  className,
}) {
  const [offset, setOffset] = useState(0);
  const [isLoading, setLoading] = useState(false);

  // dataCount is use to determine if the data has been loaded
  // & the next set of data is allowed to load
  useEffect(() => {
    if (dataCount > offset) {
      setLoading(false);
      setOffset(offset + perPageCount);
    }
  }, [dataCount, offset, perPageCount]);

  function onVisibleChange(isVisible) {
    if (isVisible && !isLoading) {
      setLoading(true);
      loadMore(perPageCount, offset);
    }
  }

  const loaderClass = classnames(className);

  return (
    <div className={loaderClass}>
      <VisibilitySensor
        // This is required, because we want to let the mounting to finish
        // before we initiate a call for new data
        delayedCall={true}
        onChange={onVisibleChange}
      >
        {loader}
      </VisibilitySensor>
    </div>
  );
}

InfiniteScrollLoader.propTypes = {
  loadMore: PropTypes.func.isRequired,
  perPageCount: PropTypes.number.isRequired,
  loader: PropTypes.element.isRequired,
  dataCount: PropTypes.number.isRequired,
  className: PropTypes.string,
};

export default InfiniteScrollLoader;
