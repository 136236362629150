import React from 'react';
import { SectionHeading, SectionWrapper } from '../section/Section.js';
import PropTypes from 'prop-types';
import './testimonialCards.scss';
import DeviceContainer from '../DeviceContainer.js';
import TestimonialCard from './TestimonialCard.js';
import SliderWrapper from '../Slider/SliderWrapper.js';

function TestimonialCards({ type }) {
  const testimonialsData = getTestimonialsData();
  const cards = testimonialsData.map(
    ({ name, occupation, testimonial, imageName }, index) => {
      return (
        <TestimonialCard
          key={index}
          name={name}
          occupation={occupation}
          testimonial={testimonial}
          imageName={imageName}
        />
      );
    }
  );

  return (
    <SectionWrapper type="horizontal-scroll">
      <SectionHeading
        type="horizontal-scroll"
        className="testimonial-section-heading"
      >
        What our users say about their online consultation experience
      </SectionHeading>

      <DeviceContainer>
        {device => (
          <SliderWrapper
            slides={cards}
            name="testimonials-slider"
            perView={device.isDesktop ? 3 : 1}
            height="250"
            width="380"
            enableBullets={true}
            type={type}
          />
        )}
      </DeviceContainer>
    </SectionWrapper>
  );
}

function getTestimonialsData() {
  return [
    {
      name: 'Abhy',
      occupation: '33 yr old IT Professional',
      testimonial:
        'I was really sick and <strong>couldn’t get out of bed.</strong> I talked to the doctor through Practo and he shared a <strong>prescription in 10 minutes.</strong> I even got the <strong>medicines delivered on the same day</strong> through Practo, just a WOW experience.',
      imageName: 'testimonial-abhy-v1.png',
    },
    {
      name: 'Preeti',
      occupation: '26 yr old IT Professional',
      testimonial:
        'I was <strong>in pain</strong> and it was really <strong>late at night.</strong> I wanted help but couldn’t go to see the doctor due to lateness of the hour. Consulted online on Practo and <strong>doctor responded immediately.</strong>',
      imageName: 'testimonial-default-women-v1.png',
    },
    {
      name: 'Deepak',
      occupation: '29 yr old traveller',
      testimonial:
        'I was travelling to a <strong>remote place</strong> for scuba diving and <strong>injured myself.</strong> Tough to find doctors around, so did an <strong>online consultation</strong> on Practo and hey presto, I have my <strong>medicines.</strong> Disruptive. Convenient.',
      imageName: 'testimonial-deepak-v1.png',
    },
    {
      name: 'Anamika Bajpai',
      occupation: 'Anonymous',
      testimonial:
        'Excellent experience consulting on Practo. I could solve my health issue without going to a clinic! Highly recommended!',
      imageName: 'testimonial-anamika-v1.png',
    },
    {
      name: 'Maitreyi Purohit',
      occupation: 'Anonymous',
      testimonial:
        'I got answers to all my medical queries. I’ll definitely recommend online consultations on Practo to others.',
      imageName: 'testimonial-maitreyi-v1.png',
    },
    {
      name: 'Mr. Bismoy Murasing',
      occupation: 'Anonymous',
      testimonial:
        'The consultation on Practo was great and I’m very happy with the experience. Would certainly ask other people to consult online.',
      imageName: 'testimonial-bismoy-v1.png',
    },
  ];
}

TestimonialCards.propTypes = {
  type: PropTypes.string,
};

export default TestimonialCards;
