import React from 'react';
import Toast from './components/common/Toast/Toast';
import PropTypes from 'prop-types';

const BaseApp = ({ children }) => {
  return (
    <div>
      <Toast />
      {children}
    </div>
  );
};

BaseApp.propTypes = {
  children: PropTypes.any,
};

export default BaseApp;
