import consultRequestCreator from '../../consultRequestCreator';
import urlConstants from '../../../constants/urlConstants.js';

export function postAppLink(mobileNumber) {
  return consultRequestCreator.post(
    urlConstants.SEND_APP_LINK_SMS,
    { mobile_number: mobileNumber },
    { baseURL: null }
  );
}
