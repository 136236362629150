import React from 'react';
import PropTypes from 'prop-types';
import Link from '../../common/link/Link';
import { getQuestionPageUrlBySlug } from '../../../utilities/urlHelper.js';
import { parseTextFromTextNode } from '../../../utilities/utilities.js';

const HealthQuery = ({ slug, text, subject, view_count }) => (
  <div className="health-query">
    <div className="health-query__header">
      <b>{subject}</b>
      <div>{view_count} Views</div>
    </div>
    <p className="health-query__desc">
      <span
        dangerouslySetInnerHTML={{
          __html: parseTextFromTextNode(text).substring(0, 80).concat('... '),
        }}
      />
      <Link
        href={getQuestionPageUrlBySlug(slug)}
        className="health-query__link"
      >
        Read more
      </Link>
    </p>
  </div>
);

HealthQuery.propTypes = {
  slug: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  subject: PropTypes.string.isRequired,
  view_count: PropTypes.number.isRequired,
};

export default HealthQuery;
