import React from 'react';
import PropTypes from 'prop-types';
import { getImageUrl } from '../../../utilities/assetsHelper.js';
import Link from '../../common/link/Link.js';
import LazyImg from '../../common/LazyImg.js';

function TopSpecialityCard({
  currencySymbol,
  heading,
  price,
  problemAreaId,
  imageName,
  imageAlt,
  onClick,
}) {
  const url = `/consult/direct/new_consultation?id=${problemAreaId}`;

  return (
    <div className="top-speciality-card">
      <figure>
        <LazyImg
          width="70"
          height="70"
          layout="fixed"
          alt={imageAlt}
          src={getImageUrl(imageName)}
        />
      </figure>
      <div className="content">
        <h4 className="heading">{heading}</h4>
        <p className="price">
          {currencySymbol}
          {price}
        </p>
        <Link className="cta" onClick={onClick} href={url}>
          Consult now
          <span className="icon-ic_next_cheveron" />
        </Link>
      </div>
    </div>
  );
}

TopSpecialityCard.propTypes = {
  currencySymbol: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  problemAreaId: PropTypes.number.isRequired,
  imageName: PropTypes.string.isRequired,
  imageAlt: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default TopSpecialityCard;
