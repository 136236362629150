import consultRequestCreator from '../../redux/consultRequestCreator';
import urlConstants from '../../constants/urlConstants';
import attribution from '../../utilities/attribution';
import getConsultAuthData from '../../utilities/getConsultAuthData.js';

export function fetchIsTransactionPossible({
  problem_area_id,
  service_id,
  subscription_plan_id,
}) {
  return consultRequestCreator
    .get(
      attribution.appendToUrl(urlConstants.PRE_PAYMENT_TRANSACTION_POSSIBLE),
      {
        params: {
          problem_area_id,
          service_id,
          subscription_plan_id,
        },
      }
    )
    .then(response => response.data);
}

export function createConsultUser() {
  const consultAuthData = getConsultAuthData();
  const payload = {
    practo_account_id: consultAuthData.PRACTO_ACCOUNT_ID,
  };
  return consultRequestCreator
    .post(urlConstants.CREATE_CONSULT_USER, payload)
    .then(response => response.data);
}
