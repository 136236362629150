import React, { useEffect, forwardRef } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  updateSelectedCardIndex,
  fetchPaymentCards,
} from '../../../redux/ducks/payments/payments.js';
import PaymentCards from './PaymentCards.js';
import {
  getFlowProblemAreaId,
  getSubscriptionPlanId,
  getFlowDoctorAccountId,
  getServiceId,
} from '../../../utilities/consultHelper.js';

const PaymentCardsContainer = ({ isVisible }, ref) => {
  const dispatch = useDispatch();
  const {
    isLoggedIn,
    cards,
    selectedCardIndex,
    fee,
    flowProblemAreaId,
    subscriptionPlanId,
    serviceId,
    doctorAccountId,
    showPlusPlans,
    inMicroApp,
  } = useSelector(
    state => ({
      isLoggedIn: state.user.isLoggedIn,
      cards: state.payments.cards,
      selectedCardIndex: state.payments.selectedCardIndex,
      fee: !state.payments.currencySymbol
        ? '...'
        : state.payments.currencySymbol + state.payments.fee,
      flowProblemAreaId: getFlowProblemAreaId(state.newConsult),
      subscriptionPlanId: getSubscriptionPlanId(
        state.newConsult.serviceDetails
      ),
      serviceId: getServiceId(state.newConsult.serviceDetails),
      doctorAccountId: getFlowDoctorAccountId(state.newConsult),
      showPlusPlans: state.featureMap.show_plus_plans,
      inMicroApp: Boolean(state.featureMap.app),
    }),
    shallowEqual
  );

  useEffect(() => {
    if (isLoggedIn && flowProblemAreaId && subscriptionPlanId) {
      dispatch(
        fetchPaymentCards(
          flowProblemAreaId,
          subscriptionPlanId,
          serviceId,
          doctorAccountId
        )
      );
    }
  }, [
    isLoggedIn,
    flowProblemAreaId,
    subscriptionPlanId,
    serviceId,
    doctorAccountId,
    dispatch,
  ]);

  if (!isVisible || !isLoggedIn) {
    return null;
  }

  return (
    <div ref={ref}>
      <PaymentCards
        consultFee={fee}
        selectedCardIndex={selectedCardIndex}
        onChange={index => dispatch(updateSelectedCardIndex(index))}
        cards={showPlusPlans ? cards : cards.filter(x => x.type === 'consult')}
        inMicroApp={inMicroApp}
      />
    </div>
  );
};

PaymentCardsContainer.defaultProps = {
  isVisible: true,
};

PaymentCardsContainer.propTypes = {
  isVisible: PropTypes.bool,
};
/**
 * Removing, prop-types throws `is missing in props validation`.
 * prop -> 'isVisible' should always be passed, to render PaymentCardsContainer.
 */
export default forwardRef(PaymentCardsContainer);
