import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import persistState from './middlewares/persist-state/persistState.js';
import { NEW_CONSULT_STORAGE_KEY } from '../constants/consultConstants.js';
import { hasSessionStorageSupport } from '../utilities/domHelpers.js';
import ducks from './ducks';

export default function createAppStore(initialState = {}) {
  const sagaMiddleware = createSagaMiddleware();

  // Setup Enhancers
  const middlewares = [sagaMiddleware];
  let enhancers = [applyMiddleware(...middlewares)];
  const reduxDevtoolsEnhancer =
    typeof window !== 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION__ &&
    window.__REDUX_DEVTOOLS_EXTENSION__();
  if (reduxDevtoolsEnhancer) {
    enhancers.push(reduxDevtoolsEnhancer);
  }

  if (typeof window !== 'undefined' && hasSessionStorageSupport()) {
    enhancers.unshift(
      persistState(['newConsult'], {
        key: NEW_CONSULT_STORAGE_KEY,
      })
    );
  }

  const appStore = createStore(
    ducks.reducers,
    initialState,
    compose.apply(null, enhancers)
  );

  const runningSagas = sagaMiddleware.run(ducks.sagas);

  return {
    store: appStore,
    runningSagas,
  };
}
