import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import './link.scss';

function CustomLink({ type, children, className, tagName, ...props }) {
  const buttonClassName = classnames('link', type, className);
  const TagName = tagName ? tagName : props.href ? 'a' : Link;

  return (
    <TagName className={buttonClassName} {...props}>
      {children}
    </TagName>
  );
}

CustomLink.propTypes = {
  href: PropTypes.string,
  children: PropTypes.any,
  className: PropTypes.string,
  tagName: PropTypes.oneOf(['a', 'span']),
  type: PropTypes.oneOf([
    'primary',
    'primary-button',
    'secondary-button',
    'secondary-black-button',
  ]),
};

export default CustomLink;
