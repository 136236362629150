export const SEARCH = 'SEARCH';
export const SEARCH_SUCCESS = 'SEARCH_SUCCESS';
export const SEARCH_FAILURE = 'SEARCH_FAILURE';
export const RESET_SEARCH = 'RESET_SEARCH';
export const TOGGLE_SEARCH_MODAL = 'TOGGLE_SEARCH_MODAL';

const initialState = {
  query: '',
  results: [],
  apiStatus: null,
  searchModalIsOpen: false,
};

export default function searchReducer(state = initialState, action = {}) {
  switch (action.type) {
    case TOGGLE_SEARCH_MODAL:
      return {
        ...state,
        searchModalIsOpen: action.payload.isOpen,
      };
    case SEARCH:
      return {
        ...state,
        apiStatus: 'PENDING',
      };

    case SEARCH_SUCCESS:
      return {
        ...state,
        query: action.payload.query,
        results: action.payload.response,
        apiStatus: 'SUCCESS',
      };

    case SEARCH_FAILURE:
      return {
        ...initialState,
        apiStatus: 'FAILURE',
      };

    case RESET_SEARCH:
      return {
        ...initialState,
      };

    default:
      return { ...state };
  }
}

export function search(query) {
  return {
    type: SEARCH,
    payload: {
      query,
    },
  };
}

export function resetSearch() {
  return {
    type: RESET_SEARCH,
  };
}

export function toggleSearchModal(isOpen) {
  return {
    type: TOGGLE_SEARCH_MODAL,
    payload: {
      isOpen,
    },
  };
}
