import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import PaymentSection from '../payment-section/PaymentSection';
import { SELECTED_TYPES } from '../../../redux/ducks/new-consult/newConsult.js';
import './selectedFormValue.scss';

function SelectedFormValue() {
  const { searchQuery, selected } = useSelector(
    state => ({
      searchQuery: state.newConsult.searchQuery,
      selected: state.newConsult.selected,
    }),
    shallowEqual
  );

  if (selected.type !== SELECTED_TYPES.SYMPTOM) {
    return null;
  }

  return (
    <PaymentSection
      title={searchQuery ? 'SEARCHED SYMPTOM' : 'SELECTED SYMPTOM'}
    >
      <div className="selected-form-value">
        {selected.imageUrl && (
          <img src={selected.imageUrl} alt={selected.name} />
        )}
        <h4 className="selected-form-value__name">{selected.name}</h4>
      </div>
    </PaymentSection>
  );
}

export default SelectedFormValue;
