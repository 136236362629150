import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { removeCoupon } from '../../../redux/ducks/payments/payments';
import './appliedCoupon.scss';
const AppliedCoupon = props => {
  const dispatch = useDispatch();

  return (
    <div className="coupons-applied">
      <div className="coupons-applied__coupon">
        <i className="icon-ic_discount coupons-applied__discount-icon" />
        <div>
          <div className="coupon-applied__code">{props.couponCode}</div>
          <div className="coupon-applied__subtitle">
            Offer / Coupon Code applied
          </div>
        </div>
      </div>
      <i
        className="icon-ic_cross_solid coupons-applied__cross"
        onClick={() => dispatch(removeCoupon())}
      />
    </div>
  );
};

AppliedCoupon.propTypes = {
  couponCode: PropTypes.string,
};

export default AppliedCoupon;
