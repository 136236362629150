import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { getImageUrl } from '../../../utilities/assetsHelper.js';
import Link from '../../common/link/Link.js';
import LazyImg from '../../common/LazyImg.js';

function OfferCard({ badge, heading, cta, imageName, color, url, onClick }) {
  const className = classnames('offer-card', color);

  return (
    <Link className={className} href={url} onClick={onClick}>
      <div className="content">
        <span className="badge">{badge}</span>
        <h4 className="heading">{heading}</h4>
        <p className="cta">
          {cta} <span className="icon-ic_next_cheveron" />
        </p>
      </div>
      <LazyImg
        src={getImageUrl(imageName)}
        width="100px"
        height="90px"
        layout="responsive"
        alt="practo offer card"
      />
    </Link>
  );
}

OfferCard.propTypes = {
  badge: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  cta: PropTypes.string.isRequired,
  imageName: PropTypes.string.isRequired,
  color: PropTypes.oneOf(['pink', 'green', 'yellow']),
  url: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default OfferCard;
