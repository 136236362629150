import React, { useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  SectionWrapper,
  SectionHeading,
} from '../../common/section/Section.js';
import HealthQuery from './HealthQuery';
import './healthQueries.scss';

const HealthQueries = ({ recentQuestions }) => {
  const [showMore, setShowMore] = useState(true);
  const toggleShowMore = () => setShowMore(!showMore);

  if (recentQuestions) {
    const updated_at = moment
      .utc(recentQuestions[0].answered_at)
      .local()
      .format('DD MMM YYYY | h:mm A z'); // Format: '29 May 2020 | 3:00 PM'

    return (
      <SectionWrapper type="horizontal-scroll">
        <SectionHeading type="horizontal-scroll">Health Queries</SectionHeading>

        <div className="health-queries">
          {recentQuestions.slice(0, showMore ? 3 : 6).map((post, i) => (
            <HealthQuery key={i} {...post} />
          ))}

          <div className="health-queries__footer">
            <p>
              <small>Last updated: {updated_at}</small>
            </p>
            <span className="health-queries__link" onClick={toggleShowMore}>
              View {showMore ? 'more' : 'less'}
            </span>
          </div>
        </div>
      </SectionWrapper>
    );
  }

  return null;
};

HealthQueries.propTypes = {
  recentQuestions: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      subject: PropTypes.string.isRequired,
      view_count: PropTypes.number.isRequired,
      answered_at: PropTypes.string.isRequired,
    })
  ),
};

export default HealthQueries;
