import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import Input from '../../common/input/Input';
import PaymentSection from '../payment-section/PaymentSection';
import {
  updateUserName,
  updateUserMobile,
  updateUserNameValid,
  populateUserName,
} from '../../../redux/ducks/new-consult/newConsult.js';
import {
  initiateOtpLogin,
  closeLoginModal,
} from '../../../redux/ducks/login-modal/loginModal.js';
import { doAfterCardsLoad } from '../../../redux/ducks/payments/payments.js';
import './patientDetailsForm.scss';

const mobileValidation = {
  pattern: {
    value: /\d{10}/,
    error: 'Mobile number is not valid',
  },
};

const nameValidation = {
  minlength: {
    value: 2,
    error: 'Min 2 characters',
  },
  maxlength: {
    value: 50,
    error: 'Max 50 characters',
  },
};

function PatientDetailsContainer({
  isVisible,
  trackPaymentInteracted,
  scrollToPaymentCards,
}) {
  const dispatch = useDispatch();
  const {
    userName,
    userMobile,
    isLoggedIn,
    loggedInUserMobile,
    consultUserName,
  } = useSelector(
    state => ({
      userName: state.newConsult.userName,
      userMobile: state.newConsult.userMobile,
      isLoggedIn: state.user.isLoggedIn,
      loggedInUserMobile: state.user.mobile,
      consultUserName: state.consultUserProfile.name,
    }),
    shallowEqual
  );

  const dispatchUpdateUserMobile = (...props) =>
    dispatch(updateUserMobile(...props));
  const dispatchUpdateUserName = (...props) =>
    dispatch(updateUserName(...props));
  const dispatchUpdateUserNameValid = (...props) =>
    dispatch(updateUserNameValid(...props));

  // Inject OTP everytime for a non logged in user with valid mobile number
  // The check is on userMobile store value instead of onChange listener
  // to handle cases when the model might get prepopulated
  useEffect(() => {
    if (!isLoggedIn & (userMobile.length === 10)) {
      dispatch(
        initiateOtpLogin({
          name: 'User',
          mobile: userMobile,
          onDone: () => {
            dispatch(
              doAfterCardsLoad(() => {
                setTimeout(scrollToPaymentCards, 500);
              })
            );
          },
        })
      );
      trackPaymentInteracted({
        'Interaction Type': 'Mobile Number Valid',
      });

      // Since the inject otp is global we should clean up
      // if the user tries to navigate away & this components
      // unmounts
      return () => {
        dispatch(closeLoginModal());
      };
    }
  }, [
    dispatch,
    isLoggedIn,
    trackPaymentInteracted,
    userMobile,
    scrollToPaymentCards,
  ]);

  // When user logs in, prepopulate the user name field if empty
  useEffect(() => {
    if (consultUserName) {
      dispatch(populateUserName(consultUserName));
    }
  }, [dispatch, consultUserName]);

  if (!isVisible) {
    return null;
  }

  return (
    <PaymentSection title="PATIENT DETAILS">
      <section className="patient-details-form">
        <Input
          type="tel"
          autoFocus={true}
          fixedText={isLoggedIn ? null : '+91'}
          placeholder="Mobile Number *"
          name="mobile-number"
          value={loggedInUserMobile || userMobile}
          onChange={dispatchUpdateUserMobile}
          disabled={isLoggedIn || userMobile.length === 10}
          validation={mobileValidation}
          message={
            isLoggedIn
              ? null
              : 'A verification code will be sent to this number.'
          }
        />
        {isLoggedIn && (
          <Input
            type="text"
            placeholder="Patient Name *"
            name="patient-name"
            value={userName}
            onChange={dispatchUpdateUserName}
            validation={nameValidation}
            onValidationChange={dispatchUpdateUserNameValid}
          />
        )}
      </section>
    </PaymentSection>
  );
}

PatientDetailsContainer.defaultProps = {
  isVisible: true,
};

PatientDetailsContainer.propTypes = {
  isVisible: PropTypes.bool,
  trackPaymentInteracted: PropTypes.func.isRequired,
  scrollToPaymentCards: PropTypes.func.isRequired,
};

export default PatientDetailsContainer;
