import React from 'react';
import { SectionWrapper } from '../section/Section.js';
import { getImageUrl } from '../../../utilities/assetsHelper';
import LazyImg from '../LazyImg';
import './navStatic.scss';

const NavStatic = () => (
  <nav className="navbar">
    <SectionWrapper className="navbar__nav">
      <LazyImg
        src={getImageUrl('practo-logo-v2.svg')}
        width="86"
        height="20"
        alt="practo"
        layout="fixed"
      />
    </SectionWrapper>
  </nav>
);

export default NavStatic;
