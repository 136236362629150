import { useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';

const DeviceContainer = ({ children }) => {
  const device = useSelector(state => state.device, shallowEqual);
  return children(device);
};

DeviceContainer.propTypes = {
  children: PropTypes.func.isRequired,
};

export default DeviceContainer;
