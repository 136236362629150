import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { useSelector, shallowEqual } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import { Title, MetaDescription } from '../common/Head/Head.js';
import NavWrapper from '../common/Nav/NavWrapper.js';
import HowItWorksSection from '../common/how-it-works-section/HowItWorksSection.js';
import ConcernSection from './concern-section/ConcernSection.js';
import Footer from '../common/footer/Footer.js';
import { PEL_OBJECTS } from '../../constants/PELConstants.js';
import { getDirectConsultUrl } from '../../utilities/urlHelper.js';
import { fetchAvailableDoctors } from '../../redux/ducks/available-doctors/availableDoctors';
import { getImageUrl } from '../../utilities/assetsHelper.js';
import {
  specialityLandingConstants,
  defaultSpecialityConstants,
} from '../../constants/specialityLandingConstants';
import TestimonialCards from '../common/testimonial-cards/TestimonialCards.js';
import FaqSection from '../common/faq-section/FaqSection.js';
import SeoSection from '../common/seo-section/SeoSection';
import SemBanner from './sem-banner/SemBanner';
import AvailableDoctorsContainer from '../available-doctors/AvailableDoctorsContainer.js';
import TrackSectionView from '../common/track-section-view/TrackSectionView';
import {
  SectionHeading,
  SectionParagraph,
  SectionWrapper,
} from '../common/section/Section.js';

import LoginModalContainer from '../common/login-modal/LoginModalContainer.js';

const SpecialityLanding = ({ routeId }) => {
  const location = useLocation();
  const { type } = useParams();
  const { isDesktop } = useSelector(
    state => ({ isDesktop: state.device.isDesktop }),
    shallowEqual
  );

  const { sem } = queryString.parse(location.search);
  const landingPage = specialityLandingConstants[type];
  const topDoctors = defaultSpecialityConstants.topDoctors;
  const device = isDesktop ? 'pc' : 'mobile';
  const pageContext = { 'Page Name': type };

  return (
    <div className="homepage">
      <Title>{landingPage.title}</Title>
      <MetaDescription>{landingPage.meta}</MetaDescription>

      <LoginModalContainer key="LoginModalContainer" />
      <NavWrapper
        key="NavContainer"
        routeId={routeId}
        isStatic={Boolean(sem)}
      />
      <TrackSectionView
        commonContext={pageContext}
        objectName={PEL_OBJECTS.consultHome}
      >
        {[
          <SemBanner
            cta={landingPage.cta}
            key="FirstFoldBanner"
            sectionType="FirstFold Banner"
            heading={landingPage.heading}
            subHeading={landingPage.subHeading}
            imageUrl={getImageUrl(`${type}-${device}.jpg`)}
            ctaLink={getDirectConsultUrl(landingPage.problemAreaId)}
          />,
          <AvailableDoctorsContainer
            disableCta={true}
            key="AvailableDoctorsContainer"
            sectionType="Doctors card"
            disableInfiniteScroll={true}
            problemAreaId={landingPage.problemAreaId}
          >
            {({ cards }) => (
              <SectionWrapper type="horizontal-scroll">
                <SectionHeading type="horizontal-scroll">
                  {topDoctors.heading}
                </SectionHeading>
                <SectionParagraph type="horizontal-scroll">
                  {topDoctors.subtext}
                </SectionParagraph>
                {cards}
              </SectionWrapper>
            )}
          </AvailableDoctorsContainer>,
          <HowItWorksSection
            key="HowItWorksSection"
            sectionType="How it works"
            {...landingPage.howItWorks}
          />,
          <ConcernSection
            key="ConcernSection"
            sectionType="Concerns section"
            isSemTargeted={Boolean(sem)}
            {...landingPage.concern}
          />,
          <TestimonialCards
            key="TestimonialCards"
            sectionType="Testimonials"
            type="slider"
          />,
          <FaqSection key="FaqSection" sectionType="FAQ" />,
          <SeoSection
            key="SeoSection"
            sectionType="SEO"
            content={landingPage.seoTags}
          />,
        ]}
      </TrackSectionView>
      <Footer isStatic={Boolean(sem)} />
    </div>
  );
};

SpecialityLanding.serverFetch = (store, req) => {
  const { type } = req.params;
  const { problemAreaId } = specialityLandingConstants[type];
  store.dispatch(fetchAvailableDoctors([problemAreaId], 10, 0));
};

SpecialityLanding.propTypes = {
  routeId: PropTypes.string,
};

export default SpecialityLanding;
