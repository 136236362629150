import urlConstants from './constants/urlConstants.js';
import { specialityLandingConstants } from './constants/specialityLandingConstants';

export const HOMEPAGE_URL = urlConstants.WEBSITE_PREFIX;
export const AMP_HOMEPAGE_URL = HOMEPAGE_URL + '/amp';
export const NEW_CONSULT_URL =
  urlConstants.WEBSITE_PREFIX + '/direct/new_consultation';
export const BASIC_DETAILS_URL =
  urlConstants.WEBSITE_PREFIX + '/direct/basic_details';
export const BASIC_DETAILS_URL_ALIAS =
  urlConstants.WEBSITE_PREFIX_ALIAS + '/direct/basic_details';
export const PAYMENT_URL = urlConstants.WEBSITE_PREFIX + '/direct/payment';

const specialities = Object.keys(specialityLandingConstants).join('|');

const cloudflareCacheTags = {
  landingPage: 'landing-page,consult',
};

export default [
  /* Format: (sorted in ascending order of priority)
  {
    id: 'unique identifier for the route',
    path: 'relative path for the route',
    component: 'component to render for this route'
    redirectIfNoSessionStorage: 'component that completely rely on session storage to work should set this as true'
  }
  */
  {
    id: 'status',
    path: '/consult-ui-status',
    component: require('./components/Status.js'),
  },
  {
    id: 'speciality-landing',
    cacheTag: cloudflareCacheTags.landingPage,
    path: HOMEPAGE_URL + `/:type(${specialities})-online`,
    component: require('./components/speciality-landing/SpecialityLanding.js'),
  },
  {
    id: 'ampHomePage',
    path: AMP_HOMEPAGE_URL,
    type: 'amp',
    component: require('./components/homepage/Homepage.js'),
  },
  {
    id: 'homepage',
    path: HOMEPAGE_URL,
    component: require('./components/homepage/Homepage.js'),
  },
  {
    id: 'new-consultation',
    path: NEW_CONSULT_URL,
    component: require('./components/newconsultpage/NewConsultHistory.js'),
    redirectIfNoSessionStorage: true,
    redirectIfInternational: true,
  },
  {
    id: 'basic-details',
    path: BASIC_DETAILS_URL,
    component: require('./components/newconsultpage/NewConsultHistory.js'),
    redirectIfNoSessionStorage: true,
    redirectIfInternational: true,
  },
  {
    id: 'basic-details-alias',
    path: BASIC_DETAILS_URL_ALIAS,
    component: require('./components/newconsultpage/NewConsultHistory.js'),
    redirectIfNoSessionStorage: true,
    redirectIfInternational: true,
  },
  {
    id: 'new-payment',
    path: PAYMENT_URL,
    component: require('./components/newconsultpage/NewConsultHistory.js'),
    redirectIfNoSessionStorage: true,
    redirectIfInternational: true,
  },
];
