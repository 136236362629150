import axios from 'axios';
import getConsultAuthData from '../utilities/getConsultAuthData.js';

const consultRequestCreator = axios.create({
  baseURL: '/consult/api/v1/',
  headers: { source: 'web' },
});

consultRequestCreator.interceptors.request.use(
  function requestSuccess(config) {
    const consultAuthData = getConsultAuthData();

    if (consultAuthData.SOURCE) {
      // if microapp flow, set is_web to true. Added to get correct FURL and SURL
      config.headers['is-web'] = true;
      config.headers.source = consultAuthData.SOURCE;
    }
    if (consultAuthData.PROFILE_TOKEN) {
      config.headers['X-Profile-Token'] = consultAuthData.PROFILE_TOKEN;
    }
    if (consultAuthData.CONSULT_TOKEN) {
      config.headers['X-Consult-Token'] = consultAuthData.CONSULT_TOKEN;
    }
    if (consultAuthData.PRACTO_ACCOUNT_ID) {
      config.headers['practo-account-id'] = consultAuthData.PRACTO_ACCOUNT_ID;
    }

    return config;
  },

  function requestFailure(error) {
    return Promise.reject(error);
  }
);

export default consultRequestCreator;
