import React from 'react';
import PropTypes from 'prop-types';

const CouponButton = props => {
  return (
    <div className="coupons-button" onClick={props.openCouponModal}>
      <div className="coupons-button__coupon">
        <i className="icon-ic_discount coupons-button__discount-icon" />
        <span>Apply Coupon Code</span>
      </div>
      <i className="icon-ic_next_cheveron coupons-button__next-icon" />
    </div>
  );
};

CouponButton.propTypes = {
  openCouponModal: PropTypes.func,
};

export default CouponButton;
