export const FETCH_BANNER_PROPS = 'FETCH_BANNER_PROPS';
export const FETCH_BANNER_PROPS_LOADING = 'FETCH_BANNER_PROPS_LOADING';
export const FETCH_BANNER_PROPS_SUCCESS = 'FETCH_BANNER_PROPS_SUCCESS';
export const FETCH_BANNER_PROPS_FAILURE = 'FETCH_BANNER_PROPS_FAILURE';

const initialState = {
  loading: false,
  urls: [],
};

export default function bannerPropsReducer(state = initialState, action = {}) {
  switch (action.type) {
    case FETCH_BANNER_PROPS_LOADING:
      return {
        ...state,
        loading: true,
        urls: [],
      };

    case FETCH_BANNER_PROPS_SUCCESS:
    case FETCH_BANNER_PROPS_FAILURE:
      return {
        ...state,
        loading: false,
        urls: action.payload.urls,
      };

    default:
      return { ...state };
  }
}
export const fetchBannerProps = () => ({
  type: FETCH_BANNER_PROPS,
});
