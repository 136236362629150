import React, {
  Fragment,
  useEffect,
  useState,
  useRef,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import './newPaymentPage.scss';

import {
  refreshFee,
  toggleCouponModal,
  calculateFinalFee,
  notifyPaymentFailure,
  makePaymentRequest,
  applyCoupon,
  removeCoupon,
  getSelectedCard,
  updateCoupon,
} from '../../redux/ducks/payments/payments.js';
import {
  fetchSelectedProblemAreaIds,
  selectSpeciality,
  updateUserNameValid,
} from '../../redux/ducks/new-consult/newConsult.js';

import SelectedFormValue from './selected-form-value/SelectedFormValue.js';
import PatientDetailsContainer from './patient-details/PatientDetailsContainer';
import LanguagePreferenceSection from './language-preference/LanguagePreferenceSection';
import CouponsSection from './Coupons/CouponsSection';
import NewConsultNav from '../newconsultpage/new-consult-nav/NewConsultNav';
import SpecialityCardsContainer from '../newconsultpage/speciality-cards/SpecialityCardsContainer';
import PaymentSection from './payment-section/PaymentSection';
import FeeSplitContainer from './fee-split/FeeSplitContainer';
import PaymentFooter from './payment-footer/PaymentFooter.js';
import queryString from 'query-string';
import PaymentRequestForm from '../common/payment-request-form/PaymentRequestForm';
import SelectedDoctorContainer from './selected-doctor/SelectedDoctorContainer.js';
import CouponModal from './Coupons/CouponModal';
import useTrackEvent from '../../hooks/useTrackEvent.js';
import { useHistory } from 'react-router-dom';
import DoctorNotAvailable from '../doctor-not-available/DoctorNotAvailable';
import PaymentCardsContainer from './payment-cards/PaymentCardsContainer';
import attribution from '../../utilities/attribution';
import StickyFooter from '../common/sticky-footer/StickyFooter';
import Modal from '../common/modal/Modal';
import AvailableDoctorsContainer from '../available-doctors/AvailableDoctorsContainer.js';
import { scrollRefIntoView } from '../../utilities/utilities';

import PrePaymentDNF from './pre-payment-dnf/PrePaymentDNF';
import { fetchIsTransactionPossible, createConsultUser } from './payment.api';
import {
  getServiceId,
  getSubscriptionPlanId,
} from '../../utilities/consultHelper';

import {
  PEL_ACTIONS,
  PEL_OBJECTS,
  PEL_PAGE_NAMES,
} from '../../constants/PELConstants';
import { sendPELEventAction } from '../../redux/ducks/analytics/analyticsSaga';
import LazyImg from '../common/LazyImg';
import { getImageUrl } from '../../utilities/assetsHelper';
import CobrandBanner from '../common/cobrand-banner/CobrandBanner';
import { partnerConfig } from '../../constants/partnerConfig.js';

export default function NewPaymentPage({ location }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const paymentCardsRef = useRef(null);
  const { referrer = '' } = queryString.parse(location.search);

  const lifebuoyCampaignInfo = {
    imageName: 'lifebuoy-campaign-banner-mobile.png',
    imageAlt: 'LIFEBUOY_CAMPAIGN',
    referrer: 'LifebuoyProductCampaign',
    problemID: 22,
  };

  const practoSevaCampaignInfo = {
    referrer: 'PractoSevaProductCampaign',
    problemId: 22,
  };

  const tcsCampaignInfo = {
    referrer: 'TCSProductCampaign',
    problemID: 22,
  };

  const productCampaignsInfoMap = {
    [practoSevaCampaignInfo.referrer]: practoSevaCampaignInfo,
    [lifebuoyCampaignInfo.referrer]: lifebuoyCampaignInfo,
    [tcsCampaignInfo.referrer]: tcsCampaignInfo,
  };

  let currentCampaignInfo = {};

  if (referrer) {
    currentCampaignInfo = productCampaignsInfoMap[referrer];
  }

  const queryParams = queryString.parse(location.search);
  const partnerId =
    queryParams.partner_id || sessionStorage.getItem('partnerId') || '';
  const partnerName = partnerConfig[partnerId]
    ? partnerConfig[partnerId].partnerName
    : '';

  const {
    isLoggedIn,
    formValues,
    inMicroApp,
    isExternalService,
    couponCode,
    isCouponModalOpen,
    selectedProblemAreaIds,
    topDoctorsSilderEnabled,
    isProblemAreaPreselected,
    serviceDetails,
    selectedProblemAreaId,
    doctorNotAvailable,
    appliedCouponCode,
    selectedCard,
    selectedCardIndex,
    paymentCards,
    userName,
    isProblemAreaIdsFetched,
    totalAmount,
  } = useSelector(
    state => ({
      formValues: state.payments.formValues,
      isLoggedIn: state.user.isLoggedIn,
      inMicroApp: Boolean(state.featureMap.app),
      isExternalService: !!state.newConsult.serviceDetails,
      couponCode: state.payments.appliedCoupon,
      appliedCouponCode:
        state.payments.couponAmount > 0 ? state.payments.appliedCoupon : null,
      isCouponModalOpen: state.payments.showCouponModal,
      selectedProblemAreaIds: state.newConsult.selectedProblemAreaIds,
      isProblemAreaPreselected: state.newConsult.isProblemAreaPreselected,
      topDoctorsSilderEnabled: state.featureMap.top_doctors_silder_enabled,
      serviceDetails: state.newConsult.serviceDetails,
      selectedProblemAreaId: state.newConsult.selectedProblemAreaId,
      doctorNotAvailable: state.doctorProfile.apiStatus === 'FAILURE',
      selectedCard: getSelectedCard(state),
      selectedCardIndex: state.payments.selectedCardIndex,
      paymentCards: state.payments.cards,
      userName: state.newConsult.userName,
      isProblemAreaIdsFetched: !!state.problemAreas.problemAreaIds.length,
      totalAmount: state.payments.totalAmount,
    }),
    shallowEqual
  );

  // Notify if loaded after payment failure
  useEffect(() => {
    const { failed } = queryString.parse(location.search);
    if (failed) {
      dispatch(notifyPaymentFailure());
    }
  }, [dispatch, location.search]);

  useEffect(() => {
    const isProductCampaign = productCampaignsInfoMap[referrer] ? true : false;

    if (!isProductCampaign && !selectedProblemAreaIds && !isExternalService) {
      dispatch(fetchSelectedProblemAreaIds());
    }
  }, [selectedProblemAreaIds, isExternalService, dispatch, location.search]);

  // Fetch flow based fee after login state has been obtained
  useEffect(() => {
    if (isLoggedIn !== null) {
      dispatch(calculateFinalFee());
    }
  }, [dispatch, isLoggedIn]);

  const [previousHistoryCount] = useState(window.history.length);
  const [isFeeBreakVisible, markFeeBreakupVisible] = useState(false);
  const [isDNFModalOpen, setIsDNFModalOpen] = useState(false);
  const [isFetchingDNF, setIsFetchingDNF] = useState(false);
  const [isProductCampaign, setIsProductCampaign] = useState(false);
  const [isCouponCodeApplied, updateIsCouponCodeApplied] = useState(false);

  const validateUserName = (name = '') => {
    const minLength = 2;
    const maxLength = 50;
    const isValid = name.length <= maxLength && name.length >= minLength;
    return isValid;
  };

  /**
   *  1. Check the flow for LifebuoyProductCampaign
   *  2. Set the specialty (GP)
   *  3. Apply LIFEBUOY Coupon code for FREE consultation.
   */
  useEffect(() => {
    const { couponCode: campaignCode, force_web_consult } = queryString.parse(
      location.search
    );
    const campaignCouponCode = campaignCode || '';

    // If Force web consult query is set , add it to the local Storage to Force open on the Chat Page(Payments Redirect)
    force_web_consult && localStorage.setItem('force_web_consult', true);

    if (referrer === currentCampaignInfo.referrer) {
      if (isLoggedIn && userName) {
        let isValid = validateUserName(userName);
        dispatch(updateUserNameValid(isValid));
      }
      if (!isProductCampaign) {
        dispatch(
          selectSpeciality(parseInt(lifebuoyCampaignInfo.problemID), true)
        );
        setIsProductCampaign(true);
      }
      /*
          Apply coupon code only 
            1. if totalAmount > 0
            2. isCouponCodeApplied = false
          Once autoApplied coupon code is removed, BE should send those coupons in API.
      */
      if (
        isProductCampaign &&
        isProblemAreaIdsFetched &&
        !!totalAmount &&
        !isCouponCodeApplied
      ) {
        dispatch(updateCoupon(campaignCouponCode));
        dispatch(applyCoupon());
        updateIsCouponCodeApplied(true);
      }
    }
  }, [
    dispatch,
    isProductCampaign,
    userName,
    isLoggedIn,
    isProblemAreaIdsFetched,
    totalAmount,
    location.search,
  ]);

  // A change in src of iframe changes the window.history count
  // Skip the modal histories when pressed back
  // Example:
  // previous history: [new_consultation, payment]
  // after login history adds (2): [new_consultation, payment, iframe1, iframe2]
  // to go back to 'new_consultation' we'll need to do goBack(-3) i.e -iframeCount -1
  function goBack() {
    if (window.history.length !== previousHistoryCount) {
      const iframeGobackCount = previousHistoryCount - window.history.length;
      history.go(iframeGobackCount - 1);
    } else {
      history.goBack();
    }
  }

  function onStickyCtaClick() {
    trackPaymentInteracted({
      'Interaction Type': 'Pay button',
      Selected:
        selectedCard && selectedCard.type === 'consult'
          ? 'Consult Card'
          : 'Upsell Card',
      'Selected Index': selectedCardIndex,
    });

    if (selectedCard && selectedCard.redirect_url) {
      window.location = attribution.appendToUrl(selectedCard.redirect_url);
    } else {
      setIsFetchingDNF(true);

      // create consult user before checking transaction eligibility
      createConsultUser()
        .then(() =>
          fetchIsTransactionPossible({
            problem_area_id: selectedProblemAreaIds[0],
            service_id: getServiceId(serviceDetails),
            subscription_plan_id: getSubscriptionPlanId(serviceDetails),
          })
        )
        .then(response => {
          setIsFetchingDNF(false);
          if (response.is_transaction_possible) {
            dispatch(makePaymentRequest());
          } else {
            setIsDNFModalOpen(true);

            dispatch(
              sendPELEventAction({
                action: PEL_ACTIONS.viewed,
                object: PEL_OBJECTS.consultAsk,
                page: PEL_PAGE_NAMES.doctorNotFound,
              })
            );
          }
        })
        .catch(error => {
          setIsFetchingDNF(false);
          console.error('Error: ', error);
        });
    }
  }

  const onDNFModalClose = () => {
    setIsDNFModalOpen(false);
  };

  const scrollToPaymentCards = useCallback(() => {
    scrollRefIntoView(paymentCardsRef);
  }, [paymentCardsRef]);

  const problemAreaIds = selectedProblemAreaIds;

  const [trackPaymentView, trackPaymentInteracted] = useTrackEvent(
    PEL_OBJECTS.consultAsk,
    {
      'Form Type': 'Unified',
      'Page Name': 'Payment Summary',
      'Is Problem Area Preselected': isProblemAreaPreselected,
      'Problem Area Id': serviceDetails
        ? serviceDetails.problemAreaId
        : selectedProblemAreaId,
      'Doctor Account Id': serviceDetails
        ? serviceDetails.doctorAccountId
        : null,
      Service: serviceDetails ? serviceDetails.service : null,
      Partner: inMicroApp ? window.pma.vendor : partnerName,
    }
  );
  useEffect(() => {
    // After cards are loaded
    if (paymentCards.length > 0) {
      trackPaymentView({
        'Upsell Card Visible': paymentCards.length > 1,
      });
    }
  }, [trackPaymentView, paymentCards.length]);

  function getNewConsultNavTitle() {
    if (isProductCampaign) {
      return '';
    }

    if (isExternalService) {
      return 'Consult a Doctor';
    }

    if (problemAreaIds && problemAreaIds.length > 1) {
      return 'Select Speciality & Enter Details';
    }

    return 'Enter Details';
  }

  let showBackIcon = !(isProblemAreaPreselected || isExternalService);
  if (inMicroApp || isProductCampaign) {
    // always display back button for microapp flow or LifebuoyProductCampaignFlow
    showBackIcon = true;
  }

  if (doctorNotAvailable) {
    return <DoctorNotAvailable />;
  }

  let footerText = '';
  if (paymentCards && paymentCards.length > 0) {
    footerText = paymentCards[0].footer_text;
  }

  return (
    <Fragment>
      {isDNFModalOpen ? (
        <Modal header="" onClose={onDNFModalClose} prePayment={'pre-payment'}>
          <PrePaymentDNF />
        </Modal>
      ) : null}
      <div className="new-payment-page">
        <NewConsultNav
          title={getNewConsultNavTitle()}
          onBack={showBackIcon ? goBack : null}
          onCross={showBackIcon ? null : goBack}
        />
        {partnerId && <CobrandBanner partnerId={partnerId} />}
        {isProductCampaign && currentCampaignInfo.imageName ? (
          <div className="u-cushion__8x-right u-cushion__8x-left u-cushion__2x-top u-cushion__2x-bottom u-background-color">
            <LazyImg
              width="100%"
              height="120"
              layout="fixed"
              alt={currentCampaignInfo.imageAlt}
              src={getImageUrl(currentCampaignInfo.imageName)}
            />
          </div>
        ) : null}
        {isExternalService ? (
          <SelectedDoctorContainer />
        ) : (
          <Fragment>
            {topDoctorsSilderEnabled && (
              <AvailableDoctorsContainer problemAreaId={selectedProblemAreaId}>
                {({ heading, subtext, cards }) => (
                  <PaymentSection
                    title={heading}
                    subtitle={subtext}
                    type="NO-SIDE-PADDING"
                  >
                    {cards}
                  </PaymentSection>
                )}
              </AvailableDoctorsContainer>
            )}

            {!isProductCampaign ? <SelectedFormValue /> : null}

            {problemAreaIds && (
              <PaymentSection
                title={
                  problemAreaIds.length > 1
                    ? 'SELECT SPECIALITY'
                    : 'SELECTED SPECIALITY'
                }
              >
                <SpecialityCardsContainer
                  disableApiFetch={true}
                  enableForm={true}
                  problemAreaIds={problemAreaIds}
                  onChange={() => {
                    dispatch(removeCoupon());
                    dispatch(refreshFee());
                  }}
                />
              </PaymentSection>
            )}
          </Fragment>
        )}
        <PatientDetailsContainer
          isVisible={!isProductCampaign}
          trackPaymentInteracted={trackPaymentInteracted}
          scrollToPaymentCards={scrollToPaymentCards}
        />
        {isLoggedIn && <LanguagePreferenceSection />}
        {isLoggedIn && selectedCard && selectedCard.is_coupon_applicable ? (
          <CouponsSection
            openCouponModal={() => dispatch(toggleCouponModal(true))}
            appliedCouponCode={appliedCouponCode}
          >
            <CouponModal
              isCouponModalOpen={isCouponModalOpen}
              closeCouponModal={() => dispatch(toggleCouponModal(false))}
              couponCode={couponCode}
              applyCoupon={payload => dispatch(applyCoupon(payload))}
            />
          </CouponsSection>
        ) : null}
        <PaymentCardsContainer
          isVisible={!isProductCampaign}
          ref={paymentCardsRef}
        />
        {footerText && (
          <div className="new-payment-page__disclaimer">{footerText}</div>
        )}
        {isFeeBreakVisible && (
          <Modal
            className="payment-breakup-modal"
            header="PAYMENT BREAKUP"
            onClose={() => markFeeBreakupVisible(false)}
          >
            <div className="payment-breakup-modal__content">
              <FeeSplitContainer />
              <PaymentFooter
                onClick={onStickyCtaClick}
                showViewBreakupButton={true}
                disableViewBreakupButton={true}
              />
            </div>
          </Modal>
        )}
        <StickyFooter height="66px" isVisible={true}>
          <PaymentFooter
            showLoader={isFetchingDNF}
            onClick={onStickyCtaClick}
            showViewBreakupButton={true}
            onViewBreakupClick={() => markFeeBreakupVisible(true)}
          />
        </StickyFooter>
        {formValues && (
          <PaymentRequestForm
            values={formValues}
            inMicroapp={inMicroApp}
            goBack={() => history.goBack()}
            onRetryCtaClick={onStickyCtaClick}
          />
        )}
      </div>
    </Fragment>
  );
}

NewPaymentPage.propTypes = {
  location: PropTypes.object.isRequired,
};
