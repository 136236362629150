export const LOAD_SEO_RECENT_QUESTIONS = 'LOAD_SEO_RECENT_QUESTIONS';
export const UPDATE_SEO_RECENT_QUESTIONS = 'UPDATE_SEO_RECENT_QUESTIONS';

export const loadSeoRecentQuestions = () => ({
  type: LOAD_SEO_RECENT_QUESTIONS,
  payload: {
    problemAreaIds: [2, 16, 5, 14, 18, 6, 28, 8],
    perProblemAreaLimit: 1,
  },
});

export const updateSeoRecentQuestions = recentQuestions => ({
  type: UPDATE_SEO_RECENT_QUESTIONS,
  payload: {
    recentQuestions,
  },
});

const initialState = {
  recentQuestions: null,
};

export default function seoReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_SEO_RECENT_QUESTIONS:
      return {
        ...state,
        recentQuestions: action.payload.recentQuestions,
      };

    default:
      return state;
  }
}
