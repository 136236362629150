import React from 'react';
import PropTypes from 'prop-types';
import SearchSuggestion from './SearchSuggestion';
import './searchSuggestions.scss';

const SearchSuggestions = ({ suggestions, highlightWord, onClick }) => {
  return (
    <div className="search-suggestions">
      {suggestions.map(suggestion => (
        <SearchSuggestion
          highlightWord={highlightWord}
          key={suggestion.id}
          suggestion={suggestion}
          onClick={() => onClick(suggestion)}
        />
      ))}
    </div>
  );
};

SearchSuggestions.propTypes = {
  suggestions: PropTypes.array,
  highlightWord: PropTypes.string,
  onClick: PropTypes.func,
};

export default SearchSuggestions;
