import sourceAttribution from '../../utilities/attribution.js';

export const createActorData = user => {
  if (!user.isLoggedIn) {
    return {};
  }

  return {
    actor: user.id,
    actorContext: {
      Name: user.user_name,
      Gender: user.gender,
      Age: user.age,
      'Is Doctor': user.user_role === 'DOCTOR',
      'Is Test': user.is_test_account || false,
      'Paid Consultations': user.direct_consultation_count || 0,
      'Free Consult Questions Asked': user.free_question_count || 0,
    },
  };
};

export const createActionContext = () => {
  let attribution = sourceAttribution.get().split;
  if (!attribution) {
    return {};
  }

  return {
    'Attribution Source': attribution['source'],
    'Attribution Medium': attribution['medium'],
    'Attribution Campaign': attribution['campaign'],
  };
};

export function createExperimentTrackingParams(
  experimentName,
  experimentGroup
) {
  return {
    'Experiment Name': experimentName,
    'Experiment Group': experimentGroup,
  };
}
