import { call, put, takeLatest } from 'redux-saga/effects';

import { SEARCH, SEARCH_SUCCESS, SEARCH_FAILURE } from './search.js';
import { fetchSearchResults } from './searchApi.js';

export function* search(action) {
  const { query } = action.payload;
  // Remove search results for empty search
  if (!query.trim()) {
    yield put({
      type: SEARCH_SUCCESS,
      payload: {
        response: [],
        query: '',
      },
    });
    return;
  }

  try {
    const response = yield call(fetchSearchResults, query);

    yield put({
      type: SEARCH_SUCCESS,
      payload: {
        response,
        query,
      },
    });
  } catch (err) {
    yield put({
      type: SEARCH_FAILURE,
    });
  }
}

export default function* searchSaga() {
  yield takeLatest(SEARCH, search);
}
