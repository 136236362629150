import SendAppLink from './SendAppLink.js';
import { sendAppLink } from '../../../redux/ducks/app-link/appLink.js';
import { connect } from 'react-redux';

const mapDispatchToProps = dispatch => ({
  sendAppLink: mobile => dispatch(sendAppLink(mobile)),
});

const mapStateToProps = state => ({
  sendAppLinkStatus: state.appLink.status,
});

export default connect(mapStateToProps, mapDispatchToProps)(SendAppLink);
