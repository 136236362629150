// Unflattened Data: [ {object1}, [{object2}, {object3}]]
// Flattened: [{ object1 }, { object2 }, { object 3 }]
export function flattenArrayOfArrays(data) {
  return data.reduce((accumulator, eachData) => {
    if (eachData instanceof Array) {
      accumulator.push(...eachData);
    } else {
      accumulator.push(eachData);
    }

    return accumulator;
  }, []);
}

// https://stackoverflow.com/questions/3446170/escape-string-for-use-in-javascript-regex
export function escapeStringForRegExp(str) {
  return str.replace(/[-[\]/{}()*+?.\\^$|]/g, '\\$&');
}

// ask a doctor => Ask A Doctor
export function titleCase(str) {
  return str
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

export function scrollRefIntoView(ref, options) {
  return (
    ref &&
    ref.current &&
    ref.current.scrollIntoView &&
    ref.current.scrollIntoView(options)
  );
}

export function parseTextFromTextNode(textNode) {
  return textNode.replace(/<\/?[^>]+(>|$)/g, '');
}
