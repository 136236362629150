import React from 'react';
import PropTypes from 'prop-types';
import { SectionWrapper } from '../section/Section.js';
import {
  getTopicPageUrlBySlug,
  getQuestionPageUrlBySlug,
  getSpecialityPageUrlBySlug,
} from '../../../utilities/urlHelper.js';
import './seoSection.scss';
import { specialityLandingConstants } from '../../../constants/specialityLandingConstants.js';
import { titleCase } from '../../../utilities/utilities.js';

class SeoSection extends React.Component {
  render() {
    const { content } = this.props;

    return (
      <SectionWrapper className="seo-section">
        {content ? (
          <p className="seo-section__content">{content}</p>
        ) : (
          <React.Fragment>
            <p dangerouslySetInnerHTML={{ __html: this.getSeoParagraph1() }} />
            {this.getRecentlyAnsweredQuestionLinks()}
            <p>Topics: {this.getTopicPageLinks()}</p>
            <p>Specialities: {this.getSpecialityPagesLinks()}</p>
          </React.Fragment>
        )}
      </SectionWrapper>
    );
  }

  getRecentlyAnsweredQuestionLinks() {
    if (!this.props.recentQuestions) {
      return;
    }

    const recentQuestionToLinkMapper = ({ subject, slug }) => ({
      title: subject,
      url: getQuestionPageUrlBySlug(slug),
    });
    const recentQuestionLinksWithSeparator = this.getLinksWithSeparator(
      this.props.recentQuestions.map(recentQuestionToLinkMapper)
    );

    return (
      <p>Recently Answered Questions: {recentQuestionLinksWithSeparator}</p>
    );
  }

  // each link to be of format: { title, url }
  getLinksWithSeparator(links) {
    return links.map(({ title, url }, index) => {
      const isLastLink = index === links.length - 1;

      return (
        <React.Fragment key={title}>
          <a href={url}>{title}</a>
          {!isLastLink && <span> | </span>}
        </React.Fragment>
      );
    });
  }

  getTopicPageLinks() {
    const topicPageMappings = this.getTopicPageMappings();
    const topicPageToLinkMapper = ({ name, slug }) => ({
      title: name,
      url: getTopicPageUrlBySlug(slug),
    });

    return this.getLinksWithSeparator(
      topicPageMappings.map(topicPageToLinkMapper)
    );
  }

  getSpecialityPagesLinks() {
    const specialityPagesSlugs = Object.keys(specialityLandingConstants);

    const specialityPagesData = specialityPagesSlugs.map(slug => {
      return {
        // ask-a-dermatologist => Ask A Dermatologist
        title: titleCase(slug.replace(/-/g, ' ')),
        url: getSpecialityPageUrlBySlug(slug),
      };
    });

    return this.getLinksWithSeparator(specialityPagesData);
  }

  getTopicPageMappings() {
    return [
      {
        slug: 'diet-plan-to-increase-weight',
        name: 'Looking for weight gain tips?',
      },
      {
        slug: 'chest-pain',
        name: 'Worried about chest pain?',
      },
      {
        slug: 'dark-circles',
        name: 'Want to get rid of dark circles?',
      },
      {
        slug: 'how-to-use-prega-news-kit',
        name: 'How to use prega news kit?',
      },
      {
        name: 'Need some weight loss advice?',
        slug: 'weight-loss',
      },
      {
        name: 'Work, family or relationship stress?',
        slug: 'depression',
      },
      {
        name: 'Looking to improve digestion?',
        slug: 'constipation',
      },
      {
        name: 'Painful kidney stones?',
        slug: 'kidney-stone',
      },
      {
        name: 'Need some sexual advice?',
        slug: 'how-to-control-quick-sperm-release',
      },
      {
        name: 'Want to learn how to last longer?',
        slug: 'premature-ejaculation',
      },
      {
        name: 'Seek help for lower back pain',
        slug: 'lower-back-pain',
      },
      {
        name: 'Need some advice for irregular periods?',
        slug: 'irregular-periods',
      },
      {
        name: 'Ask how you can relieve throat pain',
        slug: 'throat-pain',
      },
      {
        name: 'Need help with frequent urination?',
        slug: 'frequent-urination',
      },
      {
        name: 'Have trouble breathing?',
        slug: 'breathing-problem',
      },
      {
        name: 'Need some dental advice?',
        slug: 'tooth-pain',
      },
      {
        name: 'Want to get rid of acne scars?',
        slug: 'acne-scars',
      },
      {
        name: 'Stomach pain bothering you?',
        slug: 'abdomen-pain',
      },
      {
        name: 'Doubts about periods?',
        slug: 'how-much-delay-in-periods-is-normal',
      },
    ];
  }

  getSeoParagraph1() {
    return `Looking for a trusted & secured online dr consultation in India? 
    Consult a doctor online on Practo for any health concern. Our pool of over 20,000 trusted doctors across 25+ specialties will ensure all your health queries are answered. You can get online dr advice from a Psychiatrist, Dermatologist, Gynecologist, Sexologist, Pediatrician, Cardiologist, Neurologist, Gastroenterologist, Urologist, Endocrinologist, Dentist, Psychologist, and many more. Video consultation with a doctor is available only on Practo's online doctor app.
    Practo serves customers in the following cities: Bangalore, Mumbai, Delhi, Kolkata, Hyderabad, Chennai, Pune, Ahmedabad, Gurgaon and Ghaziabad.`;
  }
}

SeoSection.propTypes = {
  content: PropTypes.string,
  recentQuestions: PropTypes.array,
};

export default SeoSection;
