import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Link from '../../common/link/Link.js';
import {
  SectionHeading,
  SectionParagraph,
  SectionWrapper,
} from '../../common/section/Section.js';
import './healthProblemCards.scss';
import HorizontalCardsList from '../horizontal-cards-list/HorizontalCardsList.js';
import HealthProblemCard from './HealthProblemCard.js';
import DeviceContainer from '../../common/DeviceContainer.js';
import TrackOnceInViewContainer from '../../common/TrackOnceInViewContainer.js';
import { PEL_OBJECTS, PEL_ACTIONS } from '../../../constants/PELConstants.js';
import { sendPELEventAction } from '../../../redux/ducks/analytics/analyticsSaga.js';

class HealthProblemCardsContainer extends React.Component {
  render() {
    const healthProblemsData = this.getHealthProblemsData();
    const cards = healthProblemsData.map(
      ({ imageName, heading, problemAreaId }, index) => {
        const problemArea = this.props.problemAreasById[problemAreaId];
        const objectContext = {
          'Card Title': heading,
          'Card Position': index + 1,
          'Problem Area Id': problemAreaId,
          ...this.props.getSectionObjectContext(this.props.sectionType),
        };
        const viewTrackingParams = {
          object: PEL_OBJECTS.consultHomeCard,
          action: PEL_ACTIONS.viewed,
          objectContext,
        };
        return (
          <TrackOnceInViewContainer key={index} {...viewTrackingParams}>
            <HealthProblemCard
              imageName={imageName}
              heading={heading}
              problemAreaId={problemAreaId}
              currencySymbol={problemArea.fee_split.currency_symbol}
              price={problemArea.fee_split.projected_fee.toString()}
              onClick={() =>
                this.props.sendPELEventAction(
                  PEL_OBJECTS.consultHomeCard,
                  PEL_ACTIONS.interacted,
                  objectContext
                )
              }
            />
          </TrackOnceInViewContainer>
        );
      }
    );

    return (
      <SectionWrapper type="horizontal-scroll" className="health-problem-cards">
        <SectionHeading
          type="horizontal-scroll"
          className="health-problem-cards__heading"
        >
          Common Health Concerns
        </SectionHeading>
        <SectionParagraph>
          Consult a doctor online for any health issue
        </SectionParagraph>

        <Link
          className="health-problem-cards__button"
          href="/consult/direct/new_consultation#Symptom"
        >
          See All Symptoms
        </Link>

        <DeviceContainer>
          {device => (
            <HorizontalCardsList
              type={device.isAmp ? 'amp' : device.isDesktop ? 'slider' : null}
              sliderConfig={{ name: 'health-problems-slider', perView: 4 }}
            >
              {cards}
            </HorizontalCardsList>
          )}
        </DeviceContainer>
      </SectionWrapper>
    );
  }

  getHealthProblemsData() {
    return [
      {
        heading: 'Cough & Cold?',
        imageName: 'cough-cold-v1.jpg',
        problemAreaId: 22,
      },
      {
        heading: 'Period problems?',
        imageName: 'period-problems-v1.jpg',
        problemAreaId: 16,
      },
      {
        heading: 'Performance issues in bed?',
        imageName: 'performance-issues-bed-v1.jpg',
        problemAreaId: 14,
      },
      {
        heading: 'Skin problems?',
        imageName: 'skin-problems-v1.jpg',
        problemAreaId: 5,
      },
      {
        heading: 'Depression or anxiety?',
        imageName: 'depression-anxiety-v1.jpg',
        problemAreaId: 2,
      },
      {
        heading: 'Want to lose weight?',
        imageName: 'lose-weight-v1.jpg',
        problemAreaId: 18,
      },
      {
        heading: 'Stomach issues?',
        imageName: 'stomach-issues-v1.jpg',
        problemAreaId: 22,
      },
      {
        heading: 'Vaginal infections?',
        imageName: 'vaginal-infections-v1.jpg',
        problemAreaId: 16,
      },
      {
        heading: 'Sick kid?',
        imageName: 'sick-kid-v1.jpg',
        problemAreaId: 17,
      },
    ];
  }
}

HealthProblemCardsContainer.propTypes = {
  sectionType: PropTypes.string,
  problemAreasById: PropTypes.object,
  sendPELEventAction: PropTypes.func,
  getSectionObjectContext: PropTypes.func,
};

const mapStateToProps = state => ({
  problemAreasById: state.problemAreas.problemAreasById,
});

const mapDispatchToProps = dispatch => ({
  sendPELEventAction: (...args) => dispatch(sendPELEventAction(...args)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HealthProblemCardsContainer);
