export const FETCH_AVAILABLE_DOCTORS = 'FETCH_AVAILABLE_DOCTORS';
export const FETCH_AVAILABLE_DOCTORS_SUCCESS =
  'FETCH_AVAILABLE_DOCTORS_SUCCESS';
export const FETCH_AVAILABLE_DOCTORS_FAILURE =
  'FETCH_AVAILABLE_DOCTORS_FAILURE';

export const initialState = {
  apiStatus: null,
  heading: null,
  subtext: null,
  doctors: [],
  count: null,
};

export default function availableDoctorsReducer(
  state = initialState,
  action = {}
) {
  switch (action.type) {
    case FETCH_AVAILABLE_DOCTORS:
      return {
        // Reset state if a fresh set is being loaded
        // This will usually happen when doctors for different problemAreaId
        // is requested
        ...(action.payload.offset === 0 ? initialState : state),
        apiStatus: 'PENDING',
      };

    case FETCH_AVAILABLE_DOCTORS_SUCCESS: {
      const { heading, subtext, doctors, count } = action.payload;

      return {
        heading,
        subtext,
        count,
        doctors: [...state.doctors, ...doctors],
        apiStatus: 'SUCCESS',
      };
    }

    case FETCH_AVAILABLE_DOCTORS_FAILURE:
      return {
        ...initialState,
        apiStatus: 'FAILURE',
      };

    default:
      return { ...state };
  }
}

export const fetchAvailableDoctors = (problemAreaId, limit, offset) => ({
  type: FETCH_AVAILABLE_DOCTORS,
  payload: {
    problemAreaId,
    limit,
    offset,
  },
});
