/**
  The script should be loaded on every single page where attribution need to be captured from.
  It saves the attribution on first page load & maintains it in Cookie/Session storage on subsequent page loads

  For UTM Campaigns there is a conversion window hence a cookie based system is used
  For all other attributions immediate attribution suffice hence a session storage is used

  Populates the following in the window object:
  ```
    window.sourceAttribution = {
      split: Object({
        source,
        medium,
        campaign
      }),
    };
  ```
  Examples here: https://github.com/practo/consult-web/wiki/Source-Attribution
  */

import cookies from 'js-cookie';
import queryString from 'query-string';

function getSourceAttribution() {
  /**
   * Code execution
   */
  var attributeFromSession = (function () {
    var attributionKey = 'consult-attribution';

    return {
      get: function () {
        return JSON.parse(sessionStorage.getItem(attributionKey));
      },
      set: function (attributionObj) {
        if (!attributionObj) {
          return;
        }
        sessionStorage.setItem(attributionKey, JSON.stringify(attributionObj));
      },
    };
  })();

  var attributeFromCookie = (function () {
    var attributionKey = 'consult-attribution';

    return {
      get: function () {
        var cookieValue = cookies.get(attributionKey);
        if (!cookieValue) {
          return null;
        }

        return JSON.parse(cookieValue);
      },
      set: function (attributionObj) {
        if (!attributionObj) {
          return;
        }
        var TIME_TO_EXPIRE_DAYS = 30;
        cookies.set(attributionKey, JSON.stringify(attributionObj), {
          expires: TIME_TO_EXPIRE_DAYS,
          path: '/',
        });
      },
    };
  })();

  var urlAttributionObj = getAttributionFromUrl();
  if (isEligibleForCookieAttribution()) {
    attributeFromCookie.set(urlAttributionObj);
  } else {
    if (!attributeFromSession.get()) {
      attributeFromSession.set(urlAttributionObj);
    }
  }

  var attributionSplit =
    attributeFromCookie.get() || attributeFromSession.get();
  return {
    split: attributionSplit,
  };

  // Helpers
  function getAttributionFromUrl() {
    var queryParams = getQueryParamsFromUrl();
    if (queryParams.utm_source) {
      return createAttributionResponse(
        queryParams.utm_source,
        queryParams.utm_medium,
        queryParams.utm_campaign
      );
    }
    if (queryParams.product) {
      return createAttributionResponse(
        'cross product',
        queryParams.product,
        queryParams.attribution
      );
    }
    if (
      queryParams.source &&
      ['sms', 'mail', 'whatsapp'].indexOf(queryParams.source.toLowerCase()) !==
        -1
    ) {
      return createAttributionResponse(
        'notification',
        queryParams.source,
        queryParams.type
      );
    }
    if (document.referrer) {
      if (!doesReferrerContains('practo')) {
        return createAttributionResponse(
          'organic',
          getHostForUrl(document.referrer)
        );
      } else {
        if (doesReferrerContains('consult')) {
          return createAttributionResponse('direct');
        }

        return createAttributionResponse(
          'cross product referrer',
          getHostForUrl(document.referrer)
        );
      }
    }

    return createAttributionResponse('direct');
  }

  function isEligibleForCookieAttribution() {
    var queryParams = getQueryParamsFromUrl();

    return !!queryParams.utm_source;
  }

  function getQueryParamsFromUrl() {
    return queryString.parse(window.location.search);
  }

  function createAttributionResponse(source, medium, campaign) {
    return {
      source: source,
      medium: medium || '',
      campaign: campaign || '',
    };
  }

  function doesReferrerContains(word) {
    return document.referrer.indexOf(word) !== -1;
  }

  function getHostForUrl(url) {
    var regex = /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:/\n]+)/im;
    var matchedResults = url.match(regex);

    // matchedResults will be in this format ['https://xyz.com', 'xyz.com']
    return matchedResults[1];
  }
}

// Evaluate once, return cached on multiple calls
var sourceAttribution;
function get() {
  if (sourceAttribution) {
    return sourceAttribution;
  }

  sourceAttribution = getSourceAttribution();
  return sourceAttribution;
}

function appendToUrl(url) {
  const attribution = get().split;
  if (!url || !attribution) {
    return url;
  }

  const parsedUrl = queryString.parseUrl(url);
  parsedUrl.query = {
    ...parsedUrl.query,
    utm_source: attribution.source,
    utm_medium: attribution.medium,
    utm_campaign: attribution.campaign,
  };

  return parsedUrl.url + '?' + queryString.stringify(parsedUrl.query);
}

export default {
  get: get,
  appendToUrl: appendToUrl,
};
