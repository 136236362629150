import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import '../Grid/grid.scss';
import './card.scss';

const Card = ({ children, paper }) => (
  <div
    data-testid="base-card"
    className={classnames('card', { 'card-paper': paper })}
  >
    {children}
  </div>
);

Card.propTypes = {
  paper: PropTypes.bool,
  children: PropTypes.any,
};
export default Card;
