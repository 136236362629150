import React, { useState, useCallback, useMemo, Fragment } from 'react';
import PropTypes from 'prop-types';
import DoctorCardSmall from '../doctor-card/DoctorCardSmall';
import HorizontalCardsList from '../homepage/horizontal-cards-list/HorizontalCardsList.js';
import InfiniteScrollLoader from '../common/InfiniteScrollLoader.js';
import Loader from '../common/loader/Loader.js';
import DoctorCardLarge from '../doctor-card/DoctorCardLarge.js';
import Modal from '../common/modal/Modal.js';
import './availableDoctors.scss';

import DeviceContainer from '../common/DeviceContainer.js';

function AvailableDoctors({
  heading,
  subtext,
  doctors,
  count,
  loadMore,
  perPageCount,
  disableCta,
  disableInfiniteScroll,
  trackAvailableDoctorsInteracted,
}) {
  const [tappedDoctorId, setTappedDoctorId] = useState(null);

  // If a loadMore handler is provided, the component takes
  // care of loading more doctors. Else just renders whatever
  // data it receives
  const getLoader = useCallback(
    key =>
      loadMore &&
      !disableInfiniteScroll &&
      doctors.length !== count && (
        <InfiniteScrollLoader
          className="available-doctors__loader"
          key={key}
          dataCount={doctors.length}
          perPageCount={perPageCount}
          loadMore={loadMore}
          loader={<Loader type="inline" />}
        />
      ),
    [doctors.length, loadMore, count, perPageCount, disableInfiniteScroll]
  );

  const previewCards = useMemo(
    () => [
      ...doctors.map((doctor, index) => (
        <DoctorCardSmall
          key={'available-doctor-sm-' + doctor.id}
          onCtaClick={
            disableCta
              ? undefined
              : () => {
                  setTappedDoctorId(doctor.id);
                  trackAvailableDoctorsInteracted({
                    'Interaction Type': 'Know More',
                    'Card Position': index + 1,
                  });
                }
          }
          {...doctor}
        />
      )),
      getLoader('available-doctor-sm-loader'),
    ],
    [trackAvailableDoctorsInteracted, doctors, getLoader, disableCta]
  );

  const getExpandedCards = useCallback(
    () => [
      ...doctors.map(doctor => (
        <DoctorCardLarge
          key={'available-doctor-lg-' + doctor.id}
          {...doctor}
          autoScroll={doctor.id === tappedDoctorId}
        />
      )),
      getLoader('available-doctor-lg-loader'),
    ],
    [doctors, getLoader, tappedDoctorId]
  );

  return (
    <Fragment>
      <DeviceContainer>
        {device => (
          <HorizontalCardsList
            type={device.isDesktop ? 'slider' : null}
            sliderConfig={{ name: 'available-doctors__slider', perView: 3 }}
          >
            {previewCards}
          </HorizontalCardsList>
        )}
      </DeviceContainer>

      {tappedDoctorId && (
        <Modal
          header={heading}
          description={subtext}
          onClose={() => setTappedDoctorId(null)}
        >
          <HorizontalCardsList>{getExpandedCards()}</HorizontalCardsList>
        </Modal>
      )}
    </Fragment>
  );
}

AvailableDoctors.propTypes = {
  heading: PropTypes.string.isRequired,
  subtext: PropTypes.string.isRequired,
  doctors: PropTypes.array.isRequired,
  count: PropTypes.number.isRequired,
  loadMore: PropTypes.func,
  perPageCount: PropTypes.number.isRequired,
  disableInfiniteScroll: PropTypes.bool,
  disableCta: PropTypes.bool,
  trackAvailableDoctorsInteracted: PropTypes.func.isRequired,
};

export default AvailableDoctors;
