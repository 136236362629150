export const serviceIdMap = {
  on_demand: 3,
  fabric: 4,
  qna: 1,
  qnaAsChat: 5,
  follow_up_to_paid: 6,
  healthPlan: 14,
  subscription: 7,
};

export const subscriptionPlansMap = {
  paid: 1,
  ray_follow_up: 2,
  basic: 3,
  auto_allocation: 4,
  free_follow_up: 5,
};

export const flowMap = {
  on_demand: 'on_demand',
  the_doctor: 'the_doctor',
};

export const experimentsConstants = {
  namespaces: {
    CONSULT: 5,
  },
  experiments: {},
};

export const chatBotConstants = {
  messageDelayMilliSecs: 3000,
};

export const NEW_CONSULT_STORAGE_KEY = 'newConsultHistory';

export const GENERAL_PHYSICIAN_ID = 22;
export const MIN_CONSULTATION_FEE = '₹199';
