import { all, call, put, takeLatest, take, delay } from 'redux-saga/effects';
import fingerprint from '../../../utilities/fingerprint.js';

import {
  LOAD_PROBLEM_AREAS,
  LOAD_PROBLEM_AREAS_SUCCESS,
  PREDICT_PROBLEM_AREAS,
  PREDICT_PROBLEM_AREAS_FAILED,
  PREDICT_PROBLEM_AREAS_SUCCESS,
} from './problemAreas.js';
import * as problemAreasApi from './problemAreasApi.js';

export function* fetchProblemAreas() {
  const response = yield call(problemAreasApi.fetchProblemAreas);
  yield put({
    type: LOAD_PROBLEM_AREAS_SUCCESS,
    payload: response,
  });
}

export function* predictProblemAreas(action) {
  const fingerprintHash = yield fingerprint.generate();

  yield put({
    type: LOAD_PROBLEM_AREAS,
  });

  const { minDelayMilliSecs, text } = action.payload;
  const iterators = [
    call(problemAreasApi.predictProblemAreas, text, fingerprintHash),
    take(LOAD_PROBLEM_AREAS_SUCCESS),
  ];

  try {
    if (minDelayMilliSecs) {
      iterators.push(call(delay, minDelayMilliSecs));
    }

    const [response] = yield all(iterators);

    if (response.problem_area_ids.length > 0) {
      yield put({
        type: PREDICT_PROBLEM_AREAS_SUCCESS,
        payload: response,
      });
    } else {
      yield put({
        type: PREDICT_PROBLEM_AREAS_FAILED,
      });
    }
  } catch (err) {
    yield put({
      type: PREDICT_PROBLEM_AREAS_FAILED,
    });
  }
}

export default function* defaultSaga() {
  yield all([
    takeLatest(LOAD_PROBLEM_AREAS, fetchProblemAreas),
    takeLatest(PREDICT_PROBLEM_AREAS, predictProblemAreas),
  ]);
}
