import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { PEL_OBJECTS, PEL_ACTIONS } from '../../../constants/PELConstants.js';
import { sendPELEventAction } from '../../../redux/ducks/analytics/analyticsSaga.js';
import HomeStickyFooter from './HomeStickyFooter.js';
import DeviceContainer from '../../common/DeviceContainer';

class HomeStickyFooterContainer extends React.Component {
  render() {
    const objectContext = {
      'Section Type': 'Sticky CTA',
    };
    const viewTrackingParams = {
      object: PEL_OBJECTS.consultHomeSection,
      action: PEL_ACTIONS.viewed,
      objectContext,
    };
    return (
      <DeviceContainer>
        {device => (
          <HomeStickyFooter
            isVisible={device.isAmp || this.props.isVisible}
            isAmp={device.isAmp}
            onCtaClick={() =>
              this.props.sendPELEventAction(
                PEL_OBJECTS.consultHomeCard,
                PEL_ACTIONS.interacted,
                objectContext
              )
            }
            viewTrackingParams={viewTrackingParams}
          />
        )}
      </DeviceContainer>
    );
  }
}

HomeStickyFooterContainer.propTypes = {
  isVisible: PropTypes.bool,
  sendPELEventAction: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  sendPELEventAction: (...args) => dispatch(sendPELEventAction(...args)),
});

export default connect(null, mapDispatchToProps)(HomeStickyFooterContainer);
