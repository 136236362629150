import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import Button from '../../common/button/Button.js';
import Link from '../../common/link/Link.js';
import Loader from '../../common/loader/Loader.js';
import './paymentFooter.scss';
import { useSelector, shallowEqual } from 'react-redux';
import { getAppSvgUrl } from '../../../utilities/assetsHelper';
import { checkFormValidity } from '../../../redux/ducks/new-consult/newConsult.js';
import { getSelectedCard } from '../../../redux/ducks/payments/payments.js';

function PaymentFooter({
  showLoader,
  onClick,
  showViewBreakupButton,
  onViewBreakupClick,
  disableViewBreakupButton,
}) {
  const {
    isLoggedIn,
    isFormValid,
    totalAmount,
    selectedCard,
    thirdPartyApp,
    currencySymbol,
    paymentRequestInProgress,
  } = useSelector(
    state => ({
      thirdPartyApp: state.featureMap.app,
      totalAmount: state.payments.totalAmount,
      currencySymbol: state.payments.currencySymbol,
      isFormValid: checkFormValidity(state),
      isLoggedIn: state.user.isLoggedIn,
      paymentRequestInProgress:
        ['PENDING', 'SUCCESS'].indexOf(state.payments.paymentRequestStatus) !==
        -1,
      selectedCard: getSelectedCard(state),
    }),
    shallowEqual
  );

  const LIFEBUOY_CAMPAIGN_REFERER = 'LifebuoyProductCampaign';
  const PRACTO_SEVA_CAMPAIGN_REFERRER = 'PractoSevaProductCampaign';

  const isCampaignFlow = () => {
    if (location && location.search) {
      const { referrer = '' } = queryString.parse(location.search);
      return (
        referrer === LIFEBUOY_CAMPAIGN_REFERER ||
        referrer === PRACTO_SEVA_CAMPAIGN_REFERRER
      );
    }
    return false;
  };

  const getTotalAmount = () => {
    if (isCampaignFlow() && !totalAmount) {
      return 'FREE';
    }
    return !currencySymbol ? '...' : currencySymbol + totalAmount;
  };

  const getCtaText = useCallback(() => {
    if (selectedCard && selectedCard.type !== 'consult') {
      return 'Continue';
    }

    return totalAmount === 0 ? 'Start Consultation' : 'Pay and Consult';
  }, [selectedCard, totalAmount]);

  const isInProgress = paymentRequestInProgress || showLoader;
  return (
    <div className="payment-footer">
      {!(selectedCard && selectedCard.type !== 'consult') && (
        <div className="payment-footer__pricings">
          <p className="payment-footer__price">{getTotalAmount()}</p>
          {showViewBreakupButton && (
            <Link
              type="primary"
              tagName="span"
              className="payment-footer__link"
              onClick={onViewBreakupClick}
              disabled={disableViewBreakupButton}
            >
              View Breakup
            </Link>
          )}
        </div>
      )}

      {thirdPartyApp && (
        <div className="payment-footer__branding">
          <img
            alt={thirdPartyApp}
            src={getAppSvgUrl(thirdPartyApp)}
            className={`payment-footer__branding-${thirdPartyApp}`}
          />
        </div>
      )}

      <Button
        className="payment-footer__cta"
        type="primary"
        onClick={() => !isInProgress && onClick()}
        disabled={!isLoggedIn || !isFormValid}
      >
        {isInProgress ? <Loader type="inline" /> : <span>{getCtaText()}</span>}
      </Button>
    </div>
  );
}

PaymentFooter.propTypes = {
  onClick: PropTypes.func.isRequired,
  showViewBreakupButton: PropTypes.bool,
  onViewBreakupClick: PropTypes.func,
  disableViewBreakupButton: PropTypes.bool,
  showLoader: PropTypes.bool,
};

export default PaymentFooter;
