import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

const CanonicalLink = props => (
  <Helmet>
    <link rel="amphtml" href={props.url} />
  </Helmet>
);

CanonicalLink.propTypes = {
  url: PropTypes.string.isRequired,
};

export default CanonicalLink;
