import React from 'react';
import PropTypes from 'prop-types';
import SpecialityCardsContainer from '../speciality-cards/SpecialityCardsContainer';
import PaymentSection from '../../newpaymentpage/payment-section/PaymentSection';
import './searchNoResults.scss';

function SearchNoResults({ searchQuery, onClick }) {
  const noResultsCopy = `We can't find anything matching with "${searchQuery}", please try searching for a different word. Or you can choose a speciality from below to start a consultation.`;

  return (
    <div className="search-no-results">
      <p className="search-no-results__text">{noResultsCopy}</p>
      <PaymentSection title="SELECT SPECIALITY">
        <SpecialityCardsContainer onClick={onClick} />
      </PaymentSection>
    </div>
  );
}

SearchNoResults.propTypes = {
  searchQuery: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default SearchNoResults;
