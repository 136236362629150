import React from 'react';
import PropTypes from 'prop-types';
import { SectionHeading, SectionWrapper } from '../../common/section/Section';
import DoctorCardSmall from '../../doctor-card/DoctorCardSmall';
import HorizontalCardsList from '../horizontal-cards-list/HorizontalCardsList.js';
import DeviceContainer from '../../common/DeviceContainer.js';

const OurDoctors = ({ doctors = [] }) => {
  if (doctors.length === 0) {
    return null;
  }
  return (
    <SectionWrapper type="horizontal-scroll">
      <SectionHeading type="horizontal-scroll">Our Doctors</SectionHeading>
      <DeviceContainer>
        {device => (
          <HorizontalCardsList
            type={device.isAmp ? 'amp' : device.isDesktop ? 'slider' : null}
            sliderConfig={{ name: 'our-doctor-slider', perView: 4 }}
          >
            {doctors.map((doctor, id) => (
              <DoctorCardSmall {...doctor} key={`doctors_${id}`} />
            ))}
          </HorizontalCardsList>
        )}
      </DeviceContainer>
    </SectionWrapper>
  );
};

OurDoctors.propTypes = {
  doctors: PropTypes.arrayOf(PropTypes.object),
};

export default OurDoctors;
