import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { flattenArrayOfArrays } from '../../../utilities/utilities.js';
import DeviceContainer from '../../common/DeviceContainer.js';
import getFooterData from './getFooterData.js';
import FooterColumn from './FooterColumn.js';
import './footer.scss';

const Footer = ({ isStatic }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = index => {
    // When toggling an already opened accordion, close it
    return setActiveIndex(activeIndex === index ? null : index);
  };

  const FooterColumns = ({ device, data }) => {
    const structuredData = device.isDesktop ? data : flattenArrayOfArrays(data);
    const footerColumns = structuredData.map((eachColumnData, key) => {
      if (eachColumnData instanceof Array) {
        return (
          <div className="g-footer-column" key={key}>
            <FooterColumns device={device} data={eachColumnData} />
          </div>
        );
      }

      return (
        <FooterColumn
          key={key}
          {...eachColumnData}
          isAmp={device.isAmp}
          opened={key === activeIndex}
          toggleAccordion={() => toggleAccordion(key)}
        />
      );
    });

    if (device.isAmp) return <amp-accordion>{footerColumns}</amp-accordion>;
    else return footerColumns;
  };

  return (
    <footer className="practo_GlobalFooter bg-dark-blue">
      <div className="bg-dark-blue footer-block clearfix">
        {!isStatic && (
          <div className="g-footer-new clearfix">
            <div className="footer-container">
              <DeviceContainer>
                {device => (
                  <FooterColumns device={device} data={getFooterData()} />
                )}
              </DeviceContainer>
            </div>
          </div>
        )}
        <div className="copy-rights-wrap clearfix">
          <div className="clearfix">
            <div className="center">
              <i className="practo_GlobalFooter practo-logo" />
            </div>
            <div className="center copyright">
              Copyright &copy; 2017, Practo. All rights reserved.
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

Footer.propTypes = {
  isStatic: PropTypes.bool,
};

export default Footer;
