import React, { Fragment } from 'react';
import './doctorNotAvailable.scss';
import NewConsultNav from '../newconsultpage/new-consult-nav/NewConsultNav';
import { getImageUrl } from '../../utilities/assetsHelper';
import { useHistory } from 'react-router-dom';

function DoctorNotAvailable() {
  const history = useHistory();

  return (
    <Fragment>
      <NewConsultNav title="No Doctors Available" onCross={history.goBack} />
      <div className="doctor-not-available">
        <img
          alt="no doctor available"
          src={getImageUrl('no-doctor-available-v1.png')}
        />
        <p>We apologise for the inconvenience.</p>
      </div>
    </Fragment>
  );
}

export default DoctorNotAvailable;
