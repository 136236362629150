import { useState } from 'react';

/*
    This hook handles when to show an error to the user.

    Scenario: For an untouched field & the user entering the values
    for the first time, showing error as the user types aka liveErrorDetection
    mayn't be right as the user hasn't finished typing.

    Rather show the error for the first time when the user moves away from
    the field i.e 'onBlur' & toggle error as user is typing i.e 'onChange'
    post that when user tries to fix it.

    Returns:
    [
      // Use this to render error to the user
      isErrorVisible,

      // Just attach the event handlers to the input
      {
        onChange,
        onBlur,
      }
    ]
*/
function useFormErrorHandler(isFieldValid) {
  const [liveErrorDetection, setLiveErrorDetection] = useState(false);
  const [isErrorVisible, setIsErrorVisible] = useState(false);

  if (isFieldValid === null) {
    return [null, {}];
  }

  function onChange() {
    if (liveErrorDetection) {
      setIsErrorVisible(!isFieldValid);
    }
  }

  function onBlur() {
    if (!liveErrorDetection) {
      setLiveErrorDetection(true);
    }
    setIsErrorVisible(!isFieldValid);
  }

  return [
    isErrorVisible,
    {
      onChange,
      onBlur,
    },
  ];
}

export default useFormErrorHandler;
