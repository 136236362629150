export const UPDATE_EXPERIMENTS = 'UPDATE_EXPERIMENTS';

export const updateExperiments = payload => {
  return {
    type: UPDATE_EXPERIMENTS,
    payload: payload,
  };
};

export default function experimentsReducer(state = {}, action) {
  switch (action.type) {
    case UPDATE_EXPERIMENTS:
      return {
        ...state,
        [action.payload.id]: action.payload,
      };
    default:
      return state;
  }
}
