import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import SearchModal from './SearchModal';
import SearchBar from './search-bar/SearchBar';
import NewConsultHeader from './new-consult-header/NewConsultHeader.js';
import './searchSection.scss';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { toggleSearchModal } from '../../redux/ducks/search/search';
import FullScreenModal from '../common/modal/FullScreenModal.js';

const SearchSection = ({ trackProblemInteracted, ...props }) => {
  const dispatch = useDispatch();
  const searchModalIsOpen = useSelector(
    state => state.search.searchModalIsOpen || !!state.newConsult.searchQuery,
    shallowEqual
  );

  function handleOnClick() {
    dispatch(toggleSearchModal(true));
    trackProblemInteracted({ 'Interaction Type': 'Search' });
  }

  function closeSearchModal() {
    dispatch(toggleSearchModal(false));
  }

  return (
    <Fragment>
      <section className="search__wrapper">
        <NewConsultHeader title="Search Symptom / Speciality" />
        <SearchBar type="static" onClick={handleOnClick} />
      </section>
      <FullScreenModal
        isOpen={searchModalIsOpen}
        onRequestClose={closeSearchModal}
        contentLabel="Main Search Modal"
      >
        <SearchModal closeSearchModal={closeSearchModal} {...props} />
      </FullScreenModal>
    </Fragment>
  );
};

SearchSection.propTypes = {
  trackProblemInteracted: PropTypes.func.isRequired,
};

export default SearchSection;
