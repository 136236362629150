import React, { useCallback, useEffect, Fragment } from 'react';
var debounce = require('lodash.debounce');
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import SearchSuggestions from './search-suggestions/SearchSuggestions';
import NewConsultHeader from './new-consult-header/NewConsultHeader';
import SearchBar from './search-bar/SearchBar';
import SearchNoResults from './search-no-results/SearchNoResults';
import { search, resetSearch } from '../../redux/ducks/search/search.js';
import {
  updateSearchQuery,
  searchSelect,
  selectSpeciality,
  SELECTED_TYPES,
} from '../../redux/ducks/new-consult/newConsult.js';
import useTrackEvent from '../../hooks/useTrackEvent';
import { PEL_OBJECTS } from '../../constants/PELConstants';
import './searchModal.scss';
import Loader from '../common/loader/Loader';

function SearchModal({ closeSearchModal, redirectToPayment }) {
  const dispatch = useDispatch();
  const {
    suggestions,
    searchQuery,
    searchApiStatus,
    savedSearchQuery,
  } = useSelector(
    state => ({
      suggestions: state.search.results,
      searchApiStatus: state.search.apiStatus,
      searchQuery: state.search.query,
      savedSearchQuery: state.newConsult.searchQuery,
    }),
    shallowEqual
  );
  const showNoResults =
    searchQuery && searchApiStatus === 'SUCCESS' && suggestions.length === 0;

  const onInputChange = useCallback(
    debounce(value => {
      dispatch(search(value));
    }, 500),
    []
  );

  const [trackSearchModalView, trackSearchModalInteracted] = useTrackEvent(
    PEL_OBJECTS.consultAsk,
    {
      'Page Name': 'Search Modal',
    }
  );
  useEffect(() => {
    trackSearchModalView();
  }, [trackSearchModalView]);

  const [trackSearchResultsView, trackSearchResultsInteracted] = useTrackEvent(
    PEL_OBJECTS.consultAsk,
    {
      'Page Name': 'Search Results',
    }
  );
  useEffect(() => {
    if (suggestions.length > 0) {
      trackSearchResultsView();
    }
  }, [trackSearchResultsView, suggestions.length]);

  const [
    trackNoSearchResultsView,
    trackNoSearchResultsInteracted,
  ] = useTrackEvent(PEL_OBJECTS.consultAsk, {
    'Page Name': 'Search No Results',
  });
  useEffect(() => {
    if (showNoResults) {
      trackNoSearchResultsView();
    }
  }, [showNoResults, trackNoSearchResultsView]);

  // styles usage repeated to minimise duplicated css for performance - pranjalk 2019-11-20
  return (
    <div className="search-modal">
      <div className="search-modal__top">
        <NewConsultHeader title="Search Symptom / Speciality" />
        <i
          onClick={() => {
            trackSearchModalInteracted({ 'Interaction Type': 'Cross' });
            dispatch(resetSearch());
            dispatch(updateSearchQuery(''));
            closeSearchModal();
          }}
          className="icon-ic_cross search-modal__cross"
        />
        <SearchBar
          initialValue={savedSearchQuery}
          onChange={onInputChange}
          autoFocus={true}
        />
      </div>
      {searchApiStatus === 'PENDING' ? (
        <div className="search-modal__loader">
          <Loader type="inline" />
        </div>
      ) : (
        <Fragment>
          {suggestions.length > 0 && (
            <SearchSuggestions
              onClick={selected => {
                trackSearchResultsInteracted({
                  'Interaction Type': selected.type,
                  'Interaction Value': selected.name,
                });
                dispatch(updateSearchQuery(searchQuery));
                if (selected.type === SELECTED_TYPES.SPECIALITY) {
                  dispatch(selectSpeciality(selected.id));
                } else {
                  dispatch(searchSelect(selected));
                }
                redirectToPayment();
              }}
              suggestions={suggestions}
              highlightWord={searchQuery}
            />
          )}

          {showNoResults && (
            <SearchNoResults
              onClick={({ id }) => {
                trackNoSearchResultsInteracted({
                  'Interaction Type': 'Speciality',
                  'Problem Area Id': id,
                });
                dispatch(updateSearchQuery(searchQuery));
                dispatch(selectSpeciality(id));
                redirectToPayment();
              }}
              redirectToPayment={redirectToPayment}
              searchQuery={searchQuery}
            />
          )}
        </Fragment>
      )}
    </div>
  );
}

export default SearchModal;

SearchModal.propTypes = {
  closeSearchModal: PropTypes.func.isRequired,
  redirectToPayment: PropTypes.func.isRequired,
};
