export const SEND_APP_LINK = 'SEND_APP_LINK';
export const SEND_APP_LINK_SUCCESS = 'SEND_APP_LINK_SUCCESS';
export const SEND_APP_LINK_ERROR = 'SEND_APP_LINK_ERROR';

export const sendAppLink = mobileNumber => {
  return {
    type: SEND_APP_LINK,
    payload: {
      mobileNumber,
    },
  };
};

const initialState = {
  status: null,
};

export default function appLinkReducer(state = initialState, action) {
  switch (action.type) {
    case SEND_APP_LINK:
      return {
        status: 'pending',
      };
    case SEND_APP_LINK_SUCCESS:
      return {
        status: 'success',
      };
    case SEND_APP_LINK_ERROR:
      return {
        status: 'error',
      };
    default:
      return state;
  }
}
