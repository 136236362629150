import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './paymentCards.scss';
import { getImageUrl } from '../../../utilities/assetsHelper.js';

function PaymentCards({
  cards,
  selectedCardIndex,
  consultFee,
  onChange,
  inMicroApp,
}) {
  const cardsRef = useRef(null);
  const [radioStyle, setRadioStyle] = useState({});
  const [feeStyle, setFeeStyle] = useState({});

  useEffect(() => {
    const cardWidth = cardsRef.current.getBoundingClientRect().width;
    setRadioStyle(calculteRadioStyle(cardWidth));
    setFeeStyle(calculateFeeStyle(cardWidth));
  }, []);

  return (
    <section className="payment-cards" ref={cardsRef}>
      {cards.map(({ type, image_url }, index) => (
        <article key={index} className="payment-cards__each">
          <input
            type="radio"
            name="payment-cards"
            id={'payment-cards-' + index}
            onChange={() => onChange(index)}
            checked={index === selectedCardIndex}
            className="hidden"
          />
          <label htmlFor={'payment-cards-' + index}>
            <i
              style={radioStyle}
              className="icon-ic_radio_system payment-cards__radio"
            />
            <i
              style={radioStyle}
              className="icon-ic_done_solid_system payment-cards__radio payment-cards__radio--checked"
            />
            <img alt="consultation offers" src={image_url} />
            {type === 'consult' && (
              <span style={feeStyle} className="payment-cards__consult-fee">
                {consultFee}
              </span>
            )}
          </label>
        </article>
      ))}
      {!inMicroApp && (
        <div className="payment-cards__app-only u-cushion__8x">
          <img
            alt="consultation offers"
            src={getImageUrl('icon_phone.png')}
            className="u-spacer__5x-right"
          />
          <span>
            Consultation will be available on the Practo app. Download the app
            after booking
          </span>
        </div>
      )}
    </section>
  );
}

/*
  At 360px card the radio's
  'top' should be 14px
  'fontSize' should be 22px
  Calculating radio style w.r.t the above ratio
*/
function calculteRadioStyle(cardWidth) {
  const radioTopRatio = 14 / 360;
  const radioFontSizeRatio = 22 / 360;

  return {
    top: cardWidth * radioTopRatio + 'px',
    fontSize: cardWidth * radioFontSizeRatio + 'px',
  };
}

function calculateFeeStyle(cardWidth) {
  const feeTopRatio = 19 / 360;
  const feeFontSizeRatio = 16 / 360;

  return {
    top: cardWidth * feeTopRatio + 'px',
    fontSize: cardWidth * feeFontSizeRatio + 'px',
  };
}

PaymentCards.propTypes = {
  cards: PropTypes.array.isRequired,
  selectedCardIndex: PropTypes.number,
  consultFee: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  inMicroApp: PropTypes.bool.isRequired,
};

export default PaymentCards;
