export const LOAD_DOCTOR_PROFILE = 'LOAD_DOCTOR_PROFILE';
export const LOAD_DOCTOR_PROFILE_SUCCESS = 'LOAD_DOCTOR_PROFILE_SUCCESS';
export const LOAD_DOCTOR_PROFILE_FAILURE = 'LOAD_DOCTOR_PROFILE_FAILURE';

const initialState = {
  data: null,
  apiStatus: null,
};

export default function doctorProfileReducer(
  state = initialState,
  action = {}
) {
  switch (action.type) {
    case LOAD_DOCTOR_PROFILE:
      return {
        data: null,
        apiStatus: 'PENDING',
      };

    case LOAD_DOCTOR_PROFILE_SUCCESS:
      return {
        data: action.payload || null,
        apiStatus: 'SUCCESS',
      };

    case LOAD_DOCTOR_PROFILE_FAILURE:
      return {
        apiStatus: 'FAILURE',
      };

    default:
      return { ...state };
  }
}

export function loadDoctorProfile(doctorAccountId) {
  return {
    type: LOAD_DOCTOR_PROFILE,
    payload: {
      doctorAccountId,
    },
  };
}
