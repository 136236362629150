import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

function FooterColumn({ heading, listItems, opened, toggleAccordion, isAmp }) {
  const footerColumnClassName = classnames('g-footer-column', { open: opened });

  return (
    <section className={footerColumnClassName}>
      <h6 className="g-footer-column-head" onClick={toggleAccordion}>
        {heading}
      </h6>
      <div>
        {listItems.map(({ title, href, target = null, rel = null }) => (
          <div
            key={title}
            className={`g-footer-column-links ${
              !isAmp && !opened ? 'responsive-hide' : ''
            }`}
          >
            <div>
              <a href={href} rel={rel} target={target} title={title}>
                {title}
              </a>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

FooterColumn.propTypes = {
  heading: PropTypes.string.isRequired,
  listItems: PropTypes.array.isRequired,
  opened: PropTypes.bool.isRequired,
  toggleAccordion: PropTypes.func.isRequired,
  isAmp: PropTypes.bool,
};

export default FooterColumn;
