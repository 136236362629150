import React from 'react';
import PropTypes from 'prop-types';
import Loader from '../loader/Loader.js';
import { getSubdomainUrl } from '../../../utilities/urlHelper.js';
import urlConstants from '../../../constants/urlConstants.js';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import classnames from 'classnames';

class ConsultLoginModal extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  componentDidMount() {
    this.enableListener();
    disableBodyScroll(this.ref.current);
  }

  componentWillUnmount() {
    this.disableListener();
    enableBodyScroll(this.ref.current);
  }

  enableListener = () => {
    window.addEventListener('message', this.iframeMessageEventListener);
  };

  disableListener = () => {
    window.removeEventListener('message', this.iframeMessageEventListener);
  };

  iframeMessageEventListener = event => {
    if (event.origin === getSubdomainUrl('www')) {
      const { status } = event.data;
      if (status === 'success') {
        // Server sets the following in cookie at this point:
        // consult_auth_data: {
        //    consult_token,
        //    practo_account_id
        // }
        this.props.onDone();
      }
    }
  };

  render() {
    const iframeUrl =
      window.location.origin + urlConstants.CONSULT_IFRAME_LOGIN;
    const className = classnames('iframe-overlay', {
      hidden: this.props.silent,
    });

    return (
      <div className={className}>
        <div className="iframe" ref={this.ref}>
          <iframe src={iframeUrl} id="login-iframe-form" />
          <Loader type="overlay" />
        </div>
      </div>
    );
  }
}

ConsultLoginModal.propTypes = {
  onDone: PropTypes.func.isRequired,
  silent: PropTypes.bool,
};

export default ConsultLoginModal;
