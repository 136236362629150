import { useDispatch } from 'react-redux';
import { sendPELEventAction } from '../redux/ducks/analytics/analyticsSaga';
import { useState, useCallback } from 'react';
import { PEL_ACTIONS } from '../constants/PELConstants';

function useTrackEvent(object, objectContext) {
  const [savedContext] = useState(objectContext);
  const dispatch = useDispatch();

  const trackView = useCallback(
    (properties = {}) => {
      dispatch(
        sendPELEventAction(object, PEL_ACTIONS.viewed, {
          ...savedContext,
          ...properties,
        })
      );
    },
    [dispatch, object, savedContext]
  );

  const trackInteracted = useCallback(
    properties => {
      dispatch(
        sendPELEventAction(object, PEL_ACTIONS.interacted, {
          ...savedContext,
          ...properties,
        })
      );
    },
    [dispatch, object, savedContext]
  );

  return [trackView, trackInteracted];
}

export default useTrackEvent;
