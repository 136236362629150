import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

const Title = props => (
  <Helmet>
    <title>{props.children}</title>
    <meta property="og:title" content={props.children} />
    <meta name="twitter:title" content={props.children} />
  </Helmet>
);

Title.propTypes = {
  children: PropTypes.string.isRequired,
};

export default Title;
