import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './slider.scss';
import Glide from '@glidejs/glide';
import ArrowDisabler from './ArrowDisabler.js';

class Slider extends React.Component {
  constructor(props) {
    super(props);
    const { perView, name } = props;
    const gapPx = 32;
    this.glide = new Glide('.' + name, {
      perView,
      gap: gapPx,
      keyboard: false,
      rewind: false,
      // To avoid empty space after the last slide
      bound: true,
    });
  }

  componentDidMount() {
    this.glide.mount({ ArrowDisabler });
  }

  componentWillUnmount() {
    this.glide.destroy();
  }

  render() {
    const { slides, name, enableBullets, perView, type } = this.props;
    const sliderClassName = classnames('glide slider', name);
    const bulletsLength = Math.max(0, slides.length - perView) + 1;

    return (
      <div className={sliderClassName}>
        <div className="glide__track" data-glide-el="track">
          <ul className="glide__slides">
            {slides.map((slide, index) => (
              <li className="glide__slide" key={index}>
                {slide}
              </li>
            ))}
          </ul>
        </div>

        <div className="slider-controls" data-glide-el="controls">
          <button className="slider-back" data-glide-dir="<">
            <i className="icon-ic_back_cheveron" />
          </button>
          <button className="slider-next" data-glide-dir=">">
            <i className="icon-ic_next_cheveron" />
          </button>
        </div>

        {type && enableBullets && (
          <div className="glide__bullets" data-glide-el="controls[nav]">
            {slides &&
              Array(bulletsLength)
                .fill(1)
                .map((slide, index) => (
                  <button
                    key={index}
                    className="glide__bullet"
                    data-glide-dir={'=' + index}
                  />
                ))}
          </div>
        )}
      </div>
    );
  }
}

Slider.propTypes = {
  slides: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  enableBullets: PropTypes.bool,
  perView: PropTypes.number.isRequired,
  type: PropTypes.string,
};

export default Slider;
