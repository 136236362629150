import React from 'react';
import Slider from './Slider';
import SliderAmp from './SliderAmp';
import DeviceContainer from '../DeviceContainer';

const SliderWrapper = props => {
  return (
    <DeviceContainer>
      {device =>
        device.isAmp ? <SliderAmp {...props} /> : <Slider {...props} />
      }
    </DeviceContainer>
  );
};

export default SliderWrapper;
