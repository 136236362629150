import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import './paymentSection.scss';

const typeToClassMapping = {
  'NO-SIDE-PADDING': 'payment-section--no-side-padding',
};

export default function PaymentSection({
  title,
  subtitle,
  children,
  type,
  className,
}) {
  const paymentSectionClass = classnames(
    'payment-section',
    typeToClassMapping[type],
    className
  );

  return (
    <div className={paymentSectionClass}>
      {title && <h2 className="payment-section__title">{title}</h2>}
      {subtitle && <p className="payment-section__subtitle">{subtitle}</p>}
      {children}
    </div>
  );
}

PaymentSection.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.any.isRequired,
  type: PropTypes.oneOf(Object.keys(typeToClassMapping)),
  className: PropTypes.string,
};
