import {
  subscriptionPlansMap,
  serviceIdMap,
} from '../constants/consultConstants';

export const getSubscriptionPlanId = serviceDetails =>
  serviceDetails
    ? subscriptionPlansMap.paid
    : subscriptionPlansMap.auto_allocation;

export const getServiceId = serviceDetails => {
  if (serviceDetails) {
    return serviceIdMap[serviceDetails.service.toLowerCase()];
  }

  return serviceIdMap.on_demand;
};

export const getCleanedText = text =>
  text ? text.replace(/ +/g, ' ').trim() : '';

export const getFlowByParams = () => {
  return 'on_demand';
};

export const getPELFormTypeByParams = () => {
  return 'chat';
};

export const getServiceForPEL = () => {
  return 'fabric';
};

export function getFlowProblemAreaId(newConsultState) {
  return newConsultState.serviceDetails
    ? newConsultState.serviceDetails.problemAreaId
    : newConsultState.selectedProblemAreaId;
}

export function getFlowDoctorAccountId(newConsultState) {
  return (
    newConsultState.serviceDetails &&
    newConsultState.serviceDetails.doctorAccountId
  );
}

export default {
  getCleanedText,
  getFlowByParams,
  getPELFormTypeByParams,
  getServiceForPEL,
  getServiceId,
  getSubscriptionPlanId,
  getFlowProblemAreaId,
  getFlowDoctorAccountId,
};
