import React from 'react';
import PropTypes from 'prop-types';
import './newConsultHeader.scss';

export default function NewConsultHeader({ title, subtitle }) {
  return (
    <div className="new-consult-header">
      <h2 className="new-consult-header__title">{title}</h2>
      {subtitle && (
        <h4 className="new-consult-header__sub-title">{subtitle}</h4>
      )}
    </div>
  );
}

NewConsultHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
};
