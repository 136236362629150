import React, { useState } from 'react';
import classnames from 'classnames';
import { useSelector, shallowEqual } from 'react-redux';
import { SectionWrapper, SectionHeading } from '../section/Section.js';
import { Helmet } from 'react-helmet';
import { getJsonldFAQ } from '../Head/Jsonld';
import { Row, Col } from '../Grid/Grid';
import './faqSection.scss';
import './accordion.scss';

const faqData = [
  {
    title: `What is online doctor consultation?`,
    description: `Online doctor consultation or online medical consultation is a method to connect patients and doctors virtually. It is a convenient and easy way to get online doctor advice using doctor apps or telemedicine apps or platforms, and the internet.`,
  },
  {
    title: `How do I start online consultation with doctors on Practo?`,
    description: `Starting an online doctor consultation is very simple on Practo. Follow these 4 simple steps:
      &#8226; Choose your health concern
      &#8226; Connect with a doctor within 2 minutes
      &#8226; Ask your queries to the doctor via audio or video call
      &#8226; Get a valid online doctor prescription and a 3-day free online doctor consultation`,
  },
  {
    title: `Are your online doctors qualified?`,
    description: `We follow a strict verification process for every doctor providing online medical services on Practo. Our team manually verifies necessary documents, registrations, and certifications for every doctor.`,
  },
  {
    title: `Is online doctor consultation safe and secured on Practo?`,
    description: `The privacy of our patients is critical to us, and thus, we are compliant with industry standards like ISO 27001. Rest assured that your online consultation with a doctor is completely safe and secured with 256-bit encryption.`,
  },
  {
    title: `What happens if I don’t get a response from a doctor?`,
    description: `In the unlikely event that an online doctor does not respond, you will be entitled to a 100% refund. `,
  },
  {
    title: `Can I do a free online doctor consultation on Practo?`,
    description: `Avail a free online consultation with top doctors in India during the India Health Hour.
    Click <a href="/coronavirus/indiahealthhour?utm_source=consult_homepage_faqs&utm_campaign=coronavirus&utm_medium=covid19_lp">here</a> for more details.
    We have the free questions service available on our health app only. Ask a question and get free online medical advice within 24 to 48 hours.`,
  },
];

const FaqSection = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const { isAmp } = useSelector(
    state => ({ isAmp: state.device.isAmp }),
    shallowEqual
  );

  const toggleAccordion = index => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const GetAccordions = () => {
    let faqContent = faqData.map((eachFaq, index) => {
      const isOpen = index === activeIndex;
      const description =
        !isOpen && !isAmp
          ? eachFaq.description.substr(0, 70) + '.... '
          : eachFaq.description;

      const eachAccordionClass = classnames('u-spacer__5x', 'each-accordion', {
        open: !isAmp && isOpen,
      });

      let content = (
        <React.Fragment>
          <h3 className={`accordion-text ${isAmp ? 'accordion-text-amp' : ''}`}>
            {eachFaq.title}
          </h3>
          <p className="accordion-content u-font__c-gray3">
            <span dangerouslySetInnerHTML={{ __html: description }} />
            {!isAmp && !isOpen ? (
              <a className="u-font__c-gray3">Read More</a>
            ) : null}
          </p>
        </React.Fragment>
      );

      if (isAmp) {
        return (
          <Col
            md={6}
            sm={12}
            tagName="section"
            key={`faq_${index}`}
            testid={`faq_${index}`}
            className={eachAccordionClass}
            onClick={() => toggleAccordion(index)}
          >
            {content}
          </Col>
        );
      } else {
        return (
          <Col md={6} sm={12} key={`faq_${index}`} tagName="section">
            <div
              data-testid={`faq_${index}`}
              className={eachAccordionClass}
              onClick={() => toggleAccordion(index)}
            >
              {content}
            </div>
          </Col>
        );
      }
    });
    if (isAmp) {
      faqContent = <amp-accordion>{faqContent}</amp-accordion>;
    }
    return faqContent;
  };

  return (
    <SectionWrapper testid="faq-section" className="faq-section">
      <Helmet script={getJsonldFAQ(faqData)} />
      <SectionHeading className="faq-section-heading">FAQs</SectionHeading>
      <div className="ac-container">
        <Row>
          <GetAccordions />
        </Row>
      </div>
    </SectionWrapper>
  );
};

export default FaqSection;
