import React from 'react';
import PropTypes from 'prop-types';
import VisibilitySensor from 'react-visibility-sensor';
import { connect } from 'react-redux';
import { sendPELEventAction as sendPELAction } from '../../redux/ducks/analytics/analyticsSaga.js';

class TrackOnceInViewContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisibilitySensorActive: true,
    };

    this.onChange = this.onChange.bind(this);
  }

  onChange(isVisible) {
    if (isVisible) {
      const { object, action, objectContext, sendPELEventAction } = this.props;
      sendPELEventAction(object, action, objectContext);
      this.setState({
        isVisibilitySensorActive: false,
      });
    }
  }

  render() {
    return (
      <VisibilitySensor
        onChange={this.onChange}
        active={this.state.isVisibilitySensorActive}
        delayedCall={true}
      >
        {this.props.children}
      </VisibilitySensor>
    );
  }
}

TrackOnceInViewContainer.propTypes = {
  object: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  objectContext: PropTypes.object,
  sendPELEventAction: PropTypes.func.isRequired,
  children: PropTypes.any.isRequired,
};

const mapDispatchToProps = dispatch => ({
  sendPELEventAction: (...args) => dispatch(sendPELAction(...args)),
});

export default connect(null, mapDispatchToProps)(TrackOnceInViewContainer);
