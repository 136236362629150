import React, { Fragment } from 'react';
import './prePaymentDNF.scss';
import NewConsultNav from '../../newconsultpage/new-consult-nav/NewConsultNav';

function PrePaymentDNF() {
  return (
    <Fragment>
      <NewConsultNav
        title="All our doctors are busy attending other patients at the moment."
        hideLogo={true}
      />
      <div className="doctor-not-available">
        <p>
          You can try again in a few minutes. We will notify you once the next
          doctor becomes available for consultation.
        </p>
      </div>
    </Fragment>
  );
}

export default PrePaymentDNF;
