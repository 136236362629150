import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Redirect } from 'react-router';
import SearchSection from './SearchSection.js';
import SpecialitiesSection from './SpecialitiesSection';
import SymptomsSection from './SymptomsSection';
import NewConsultNav from './new-consult-nav/NewConsultNav';
import RecentChatsContainer from './recent-chats/RecentChatsContainer.js';
import './newConsultPage.scss';
import { PAYMENT_URL } from '../../routes.js';
import queryString from 'query-string';
import {
  updateServiceDetails,
  selectSpeciality,
} from '../../redux/ducks/new-consult/newConsult.js';
import BannerProps from './banner-props/BannerProps.js';
import { PEL_OBJECTS } from '../../constants/PELConstants.js';
import useTrackEvent from '../../hooks/useTrackEvent.js';
import { useHistory } from 'react-router-dom';
import CobrandBanner from '../common/cobrand-banner/CobrandBanner.js';
import { partnerConfig } from '../../constants/partnerConfig.js';

function NewConsultPage({ location }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [paymentRedirect, setPaymentRedirect] = useState({ redirect: false });
  const redirectToPayment = (isPermanentRedirect = false) => {
    setPaymentRedirect({
      redirect: true,
      push: !isPermanentRedirect,
    });
  };
  const { inMicroApp } = useSelector(
    state => ({
      inMicroApp: Boolean(state.featureMap.app),
    }),
    shallowEqual
  );

  useEffect(() => {
    // Service flow ex: QnA To paid
    // Save service details & redirect to payments
    const {
      problem_area_id: problemAreaId,
      doctor_account_id: doctorAccountId,
      question_id: questionId,
      service,
    } = queryString.parse(location.search);

    if (service) {
      dispatch(
        updateServiceDetails({
          problemAreaId: parseInt(problemAreaId),
          doctorAccountId: parseInt(doctorAccountId),
          questionId: parseInt(questionId),
          service,
        })
      );
      redirectToPayment(true);
      return;
    }

    // Problem area preselected flow
    // Ex: user coming from a speciality targetted banner
    const { id } = queryString.parse(location.search);
    if (id) {
      dispatch(selectSpeciality(parseInt(id), true));
      redirectToPayment(true);
    }
  }, [dispatch, location.search]);

  const queryParams = queryString.parse(location.search);
  if (queryParams.partner_id) {
    sessionStorage.setItem('partnerId', queryParams.partner_id);
  }
  const partnerId =
    queryParams.partner_id || sessionStorage.getItem('partnerId') || '';
  const isPartnerRedirect = partnerId in partnerConfig;
  const partnerName = partnerConfig[partnerId]
    ? partnerConfig[partnerId].partnerName
    : '';

  const [trackProblemView, trackProblemInteracted] = useTrackEvent(
    PEL_OBJECTS.consultAsk,
    {
      'Form Type': 'Unified',
      'Page Name': 'Problem Description',
      Partner: inMicroApp ? window.pma.vendor : partnerName,
    }
  );

  useEffect(() => {
    trackProblemView();
  }, [trackProblemView]);

  return (
    <div className="new-consult-page">
      <NewConsultNav
        title="Consult a Doctor"
        onCross={inMicroApp || isPartnerRedirect ? null : history.goBack}
        onBack={inMicroApp && !isPartnerRedirect ? history.goBack : null}
      />
      {partnerId && (
        <div className="u-spacer__7x-bottom">
          <CobrandBanner partnerId={partnerId} />
        </div>
      )}
      <BannerProps />
      <SearchSection
        trackProblemInteracted={trackProblemInteracted}
        redirectToPayment={redirectToPayment}
      />
      <RecentChatsContainer />
      <SpecialitiesSection
        trackProblemInteracted={trackProblemInteracted}
        redirectToPayment={redirectToPayment}
      />
      <SymptomsSection
        trackProblemInteracted={trackProblemInteracted}
        redirectToPayment={redirectToPayment}
      />
      {paymentRedirect.redirect && (
        <Redirect
          push={paymentRedirect.push}
          to={{
            pathname: PAYMENT_URL,
            search: isPartnerRedirect ? location.search : null,
          }}
        />
      )}
    </div>
  );
}

NewConsultPage.propTypes = {
  location: PropTypes.object.isRequired,
};

export default NewConsultPage;
