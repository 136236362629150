export const LOAD_USER = 'LOAD_USER';
export const LOAD_USER_SUCCESS = 'LOAD_USER_SUCCESS';
export const LOAD_USER_FAILURE = 'LOAD_USER_FAILURE';
export const UPDATE_API_STATUS = 'USER/UPDATE_API_STATUS';
export const UPDATE_USER_LOGGED_IN = 'UPDATE_USER_LOGGED_IN';

export const updateUserLoggedIn = isLoggedIn => ({
  type: UPDATE_USER_LOGGED_IN,
  payload: {
    isLoggedIn,
  },
});
export const loadUser = onDone => ({
  type: LOAD_USER,
  payload: {
    onDone,
  },
});

export const updateApiStatus = apiStatus => ({
  type: UPDATE_API_STATUS,
  payload: {
    apiStatus,
  },
});

const initialState = {
  id: null,
  user_name: null,
  email: null,
  mobile: null,
  dob: null,
  gender: null,
  age: null,
  user_role: null,
  direct_consultation_count: null,
  is_test_account: null,
  free_question_count: null,
  isLoggedIn: null,
  apiStatus: 'PENDING',
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_API_STATUS:
      return {
        ...state,
        apiStatus: action.payload.apiStatus,
      };

    case LOAD_USER_SUCCESS:
      return {
        ...action.payload,
        isLoggedIn: true,
        apiStatus: 'SUCCESS',
      };

    case LOAD_USER_FAILURE:
      return {
        isLoggedIn: false,
        apiStatus: 'FAILURE',
      };

    case UPDATE_USER_LOGGED_IN:
      return {
        ...state,
        isLoggedIn: action.payload.isLoggedIn,
      };

    default:
      return state;
  }
}
