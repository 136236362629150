import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

const MetaDescription = props => (
  <Helmet>
    <meta name="description" content={props.children} />
    <meta name="twitter:description" content={props.children} />
  </Helmet>
);

MetaDescription.propTypes = {
  children: PropTypes.string.isRequired,
};

export default MetaDescription;
