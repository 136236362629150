import React from 'react';
import PropTypes from 'prop-types';
import { getImageUrl } from '../../../utilities/assetsHelper.js';
import LazyImg from '../LazyImg.js';

function TestimonialCard({ name, occupation, testimonial, imageName }) {
  return (
    <div className="testimonial-card">
      <div className="about-user">
        <figure>
          <LazyImg
            alt={name}
            src={getImageUrl(imageName)}
            width="50px"
            height="50px"
            layout="responsive"
          />
        </figure>
        <div className="user-details">
          <h4 className="name">{name}</h4>
          <p className="occupation">{occupation}</p>
        </div>
      </div>

      <p
        className="testimonial"
        dangerouslySetInnerHTML={{ __html: testimonial }}
      />
    </div>
  );
}

TestimonialCard.propTypes = {
  name: PropTypes.string.isRequired,
  occupation: PropTypes.string.isRequired,
  testimonial: PropTypes.string.isRequired,
  imageName: PropTypes.string.isRequired,
};

export default TestimonialCard;
