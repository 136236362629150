import { all, call, put, select, takeLatest, take } from 'redux-saga/effects';
import Notifications from 'react-notification-system-redux';
import {
  getServiceId,
  getFlowProblemAreaId,
} from '../../../utilities/consultHelper';
import {
  notifyLogin,
  FETCH_SELECTED_PROBLEM_AREA_IDS_SUCCESS,
} from '../new-consult/newConsult.js';
import {
  APPLY_COUPON,
  APPLY_COUPON_FAILED,
  APPLY_COUPON_SUCCESS,
  CALCULATE_FINAL_FEE,
  CALCULATE_FINAL_FEE_SUCCESS,
  FETCH_PRACTO_WALLET_BALANCE_SUCCESS,
  FETCH_PRACTO_WALLET_BALANCE_FAILURE,
  MAKE_PAYMENT_REQUEST,
  MAKE_PAYMENT_REQUEST_FAILED,
  MAKE_PAYMENT_REQUEST_SUCCESS,
  UPDATE_FINAL_FEE,
  NOTIFY_PAYMENT_FAILURE,
  updatePaymentRequestStatus,
  FETCH_AVAILABLE_LANGUAGES,
  FETCH_AVAILABLE_LANGUAGES_LOADING,
  FETCH_AVAILABLE_LANGUAGES_SUCCESS,
  FETCH_AVAILABLE_LANGUAGES_FAILURE,
  FETCH_USER_COUPONS,
  FETCH_USER_COUPONS_SUCCESS,
  FETCH_USER_COUPONS_LOADING,
  FETCH_USER_COUPONS_FAILURE,
  REFRESH_FEE,
  REMOVE_COUPON,
  REMOVE_COUPON_SUCCESS,
  FETCH_PAYMENT_CARDS,
  FETCH_PAYMENT_CARDS_SUCCESS,
  FETCH_PAYMENT_CARDS_FAILURE,
  DO_AFTER_CARDS_LOAD,
} from './payments';
import * as paymentsApi from './paymentsApi';
import {
  calculatePaymentInformation,
  createPaymentPayload,
  fetchOrderAmount,
} from '../../../utilities/paymentUtils';
import {
  selectNewConsultState,
  selectPaymentsState,
  selectUserState,
  selectFeatureMapState,
  selectProblemAreasState,
  selectDoctorProfileState,
  selectConsultUserProfileState,
} from '../selectors';
import getConsultAuthData from '../../../utilities/getConsultAuthData';
import { getSubscriptionPlanId } from '../../../utilities/consultHelper.js';
import {
  fetchProblemAreas,
  getById,
  LOAD_PROBLEM_AREAS_SUCCESS,
} from '../problem-areas/problemAreas.js';
import {
  loadDoctorProfile,
  LOAD_DOCTOR_PROFILE_SUCCESS,
} from '../doctor-profile/doctorProfile.js';
import {
  getChatDetailsUrl,
  getDoctorPingingUrl,
} from '../../../utilities/urlHelper.js';
import { LOAD_CONSULT_USER_PROFILE_SUCCESS } from '../consult-user-profile/consultUserProfile.js';

export function* applyCoupon() {
  const paymentsState = yield select(selectPaymentsState);

  // Empty coupon validation
  if (!paymentsState.appliedCoupon.trim()) {
    yield put({
      type: APPLY_COUPON_FAILED,
      payload: {
        couponErrMessage: 'Invalid coupon',
        couponMessage: '',
        couponAmount: 0,
      },
    });
    yield* refreshFinalFee();
    return;
  }

  try {
    const newConsultState = yield select(selectNewConsultState);
    const couponData = {
      appliedCoupon: paymentsState.appliedCoupon,
      problemAreaId: newConsultState.selectedProblemAreaId,
      transactionAmount: paymentsState.fee,
      subscriptionPlanId: getSubscriptionPlanId(),
    };
    const consultAuthData = getConsultAuthData();
    if (consultAuthData.PRACTO_ACCOUNT_ID) {
      couponData.practoAccountId = consultAuthData.PRACTO_ACCOUNT_ID;
    }

    const response = yield call(paymentsApi.applyCoupon, couponData);

    yield put({
      type: APPLY_COUPON_SUCCESS,
      payload: {
        couponMessage: 'Coupon Applied Successfully',
        couponErrMessage: '',
        couponAmount: response.discount_amount || 0,
      },
    });
    yield* refreshFinalFee();
  } catch (err) {
    if (err.response.status === 403) {
      yield put(notifyLogin());
    } else {
      yield put({
        type: APPLY_COUPON_FAILED,
        payload: {
          couponErrMessage: err.response.data.error_message,
          couponMessage: '',
          couponAmount: 0,
        },
      });
      yield* refreshFinalFee();
    }
  }
}

export function* calculateFinalFee() {
  const { serviceDetails } = yield select(selectNewConsultState);
  if (serviceDetails) {
    yield put(loadDoctorProfile(serviceDetails.doctorAccountId));
    yield take(LOAD_DOCTOR_PROFILE_SUCCESS);
  } else {
    yield put(fetchProblemAreas());
    yield take(LOAD_PROBLEM_AREAS_SUCCESS);
  }

  // For on-demand flow, if problem area id is not selected
  // wait for it to be obtained before calculating the fee
  const { selectedProblemAreaId } = yield select(selectNewConsultState);
  if (!serviceDetails && !selectedProblemAreaId) {
    yield take(FETCH_SELECTED_PROBLEM_AREA_IDS_SUCCESS);
  }

  yield* refreshFinalFee();
  yield put({
    type: CALCULATE_FINAL_FEE_SUCCESS,
  });
}

export function* refreshFinalFee() {
  yield* fetchPractoWalletBalance();
  const featureMapState = yield select(selectFeatureMapState);
  const problemAreasState = yield select(selectProblemAreasState);
  const doctorProfileState = yield select(selectDoctorProfileState);
  const newConsultState = yield select(selectNewConsultState);
  const paymentsState = yield select(selectPaymentsState);

  const payload = calculatePaymentInformation(
    paymentsState,
    featureMapState,
    doctorProfileState.data ||
      getById(problemAreasState, newConsultState.selectedProblemAreaId)
        .fee_split
  );

  yield put({
    type: UPDATE_FINAL_FEE,
    payload,
  });
}

export function* fetchPractoWalletBalance() {
  const userState = yield select(selectUserState);
  if (!userState.isLoggedIn) {
    return;
  }

  const problemAreasState = yield select(selectProblemAreasState);
  const newConsultState = yield select(selectNewConsultState);
  const paymentsState = yield select(selectPaymentsState);
  const doctorProfileState = yield select(selectDoctorProfileState);
  const orderAmount = fetchOrderAmount(
    paymentsState,
    doctorProfileState.data ||
      getById(problemAreasState, newConsultState.selectedProblemAreaId)
        .fee_split
  );
  const consultAuthData = getConsultAuthData();
  const fetchPractoWalletBalanceData = {
    orderAmount: orderAmount,
    practoAccountId: consultAuthData.PRACTO_ACCOUNT_ID,
  };

  try {
    const payload = yield call(
      paymentsApi.fetchPractoWalletBalance,
      fetchPractoWalletBalanceData
    );
    yield put({
      type: FETCH_PRACTO_WALLET_BALANCE_SUCCESS,
      payload,
    });
  } catch (e) {
    yield put({
      type: FETCH_PRACTO_WALLET_BALANCE_FAILURE,
    });
  }
}

export function* makePaymentRequest() {
  try {
    const newConsultState = yield select(selectNewConsultState);
    const paymentsState = yield select(selectPaymentsState);
    const userState = yield select(selectUserState);
    const doctorProfileState = yield select(selectDoctorProfileState);
    const paymentPayload = createPaymentPayload(
      newConsultState,
      paymentsState,
      userState,
      doctorProfileState
    );
    yield put(updatePaymentRequestStatus('PENDING'));
    const response = yield call(paymentsApi.makePaymentRequest, paymentPayload);

    // This api might return a:
    // private_thread_id: For a 100% discounted transaction or if a thread exists with THE doctor flow
    // transaction_id: For a 100% discounted transaction
    if (response.private_thread_id) {
      window.location = getChatDetailsUrl(response.private_thread_id);
      return;
    } else if (response.transaction_id) {
      window.location = getDoctorPingingUrl(response.transaction_id);
      return;
    }

    yield put({
      type: MAKE_PAYMENT_REQUEST_SUCCESS,
      payload: response,
    });
    yield put(updatePaymentRequestStatus('SUCCESS'));
  } catch (err) {
    if (err.response.status === 403) {
      yield put(notifyLogin());
    } else {
      const notificationsOpts = {
        message: 'Unable to create a payment request',
        dismissible: false,
      };
      yield put(Notifications.error(notificationsOpts));
      yield put({
        type: MAKE_PAYMENT_REQUEST_FAILED,
      });
    }
    yield put(updatePaymentRequestStatus('FAILURE'));
  }
}

export function* notifyPaymentFailure() {
  yield put(
    Notifications.error({
      message: 'Payment unsuccessful. Please try again.',
      dismissible: false,
    })
  );
}

export function* fetchAvailableLanguages() {
  yield put({
    type: FETCH_AVAILABLE_LANGUAGES_LOADING,
  });
  try {
    const newConsultState = yield select(selectNewConsultState);
    const serviceDetails = newConsultState.serviceDetails;
    const launguagePayload = {
      serviceId: getServiceId(serviceDetails),
      problemAreaId: getFlowProblemAreaId(newConsultState),
    };
    if (serviceDetails) {
      launguagePayload.doctorAccountId = serviceDetails.doctorAccountId;
    }

    const response = yield call(
      paymentsApi.fetchAvailableLanguages,
      launguagePayload
    );

    yield put({
      type: FETCH_AVAILABLE_LANGUAGES_SUCCESS,
      payload: response,
    });
  } catch (err) {
    yield put({
      type: FETCH_AVAILABLE_LANGUAGES_FAILURE,
    });
  }
}

export function* fetchUserCoupons() {
  yield put({
    type: FETCH_USER_COUPONS_LOADING,
  });
  try {
    const response = yield call(paymentsApi.fetchUserCoupons);
    yield put({
      type: FETCH_USER_COUPONS_SUCCESS,
      payload: response,
    });
  } catch (err) {
    yield put({
      type: FETCH_USER_COUPONS_FAILURE,
    });
  }
}

export function* removeCoupon() {
  yield put({
    type: REMOVE_COUPON_SUCCESS,
  });
  yield* refreshFinalFee();
}

export function* fetchPaymentCards(action) {
  try {
    const cards = yield call(
      paymentsApi.getPaymentCards,
      action.payload.problemAreaId,
      action.payload.subscriptionPlanId,
      action.payload.serviceId,
      action.payload.doctorAccountId
    );

    yield put({
      type: FETCH_PAYMENT_CARDS_SUCCESS,
      payload: cards,
    });
  } catch (err) {
    yield put({
      type: FETCH_PAYMENT_CARDS_FAILURE,
    });
  }
}

export function* doAfterCardsLoad(action) {
  yield all([
    take(LOAD_CONSULT_USER_PROFILE_SUCCESS),
    take(FETCH_PAYMENT_CARDS_SUCCESS),
  ]);

  const consultUserProfile = yield select(selectConsultUserProfileState);
  consultUserProfile.name && action.payload.callback();
}

export default function* defaultSaga() {
  yield all([
    takeLatest(APPLY_COUPON, applyCoupon),
    takeLatest(CALCULATE_FINAL_FEE, calculateFinalFee),
    takeLatest(MAKE_PAYMENT_REQUEST, makePaymentRequest),
    takeLatest(NOTIFY_PAYMENT_FAILURE, notifyPaymentFailure),
    takeLatest(FETCH_AVAILABLE_LANGUAGES, fetchAvailableLanguages),
    takeLatest(FETCH_USER_COUPONS, fetchUserCoupons),
    takeLatest(REFRESH_FEE, refreshFinalFee),
    takeLatest(REMOVE_COUPON, removeCoupon),
    takeLatest(FETCH_PAYMENT_CARDS, fetchPaymentCards),
    takeLatest(DO_AFTER_CARDS_LOAD, doAfterCardsLoad),
  ]);
}
