import { GENERAL_PHYSICIAN_ID } from '../../../constants/consultConstants';

export const LOAD_PROBLEM_AREAS = 'LOAD_PROBLEM_AREAS';
export const LOAD_PROBLEM_AREAS_SUCCESS = 'LOAD_PROBLEM_AREAS_SUCCESS';

export const PREDICT_PROBLEM_AREAS = 'PREDICT_PROBLEM_AREAS';
export const PREDICT_PROBLEM_AREAS_FAILED = 'PREDICT_PROBLEM_AREAS_FAILED';
export const PREDICT_PROBLEM_AREAS_SUCCESS = 'PREDICT_PROBLEM_AREAS_SUCCESS';

export const TOGGLE_MODAL = 'PROBLEM_AREAS/TOGGLE/MODAL';

const initialState = {
  problemAreasById: {},
  problemAreaIds: [],
  predictedProblemAreaIds: [],
  predictionFailed: false,
  showModal: false,
};

export function getById(state, id) {
  return state.problemAreasById[id];
}

export function getCoronaProblemArea(state) {
  const problemArea = filterProblemAreas(state.problemAreas.problemAreasById, [
    GENERAL_PHYSICIAN_ID,
  ])[0];
  if (problemArea) {
    return {
      ...problemArea,
      name: 'Coronavirus Related',
      image_url:
        'https://www.practostatic.com/acred/search-assets/2/23-coronavirus.png',
    };
  }
}

// Note: Maintains the order of filterIds passed
export function filterProblemAreas(problemAreasById, filterIds) {
  let filteredProblemAreas = [];
  filterIds.forEach(filterId => {
    if (problemAreasById[filterId]) {
      filteredProblemAreas.push(problemAreasById[filterId]);
    }
  });

  return filteredProblemAreas;
}

export default function problemAreasReducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_PROBLEM_AREAS_SUCCESS: {
      let problemAreas = action.payload.problem_areas;

      let problemAreasById = {};
      problemAreas.forEach(problemArea => {
        problemAreasById[problemArea.id] = problemArea;
      });

      return {
        ...state,
        problemAreaIds: problemAreas.map(problemArea => problemArea.id),
        problemAreasById: problemAreasById,
      };
    }

    case PREDICT_PROBLEM_AREAS_SUCCESS: {
      let problemAreaIds = action.payload.problem_area_ids;
      if (problemAreaIds.indexOf(GENERAL_PHYSICIAN_ID) === -1) {
        problemAreaIds.push(GENERAL_PHYSICIAN_ID);
      }
      return {
        ...state,
        predictedProblemAreaIds: problemAreaIds,
        predictionFailed: false,
      };
    }

    case PREDICT_PROBLEM_AREAS_FAILED:
      return {
        ...state,
        predictedProblemAreaIds: [],
        predictionFailed: true,
      };

    case TOGGLE_MODAL:
      return {
        ...state,
        showModal: !state.showModal,
      };

    default:
      return { ...state };
  }
}

export function fetchProblemAreas() {
  return {
    type: LOAD_PROBLEM_AREAS,
  };
}

export function predictProblemAreas(text, minDelayMilliSecs) {
  return {
    type: PREDICT_PROBLEM_AREAS,
    payload: {
      text,
      minDelayMilliSecs,
    },
  };
}

export function toggleModal() {
  return {
    type: TOGGLE_MODAL,
  };
}
