export const getJsonldFAQ = faqData => {
  const body = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
  };
  const faqJson = faqData.reduce((mainEntity, faq) => {
    mainEntity.push({
      '@type': 'Question',
      name: faq.title,
      acceptedAnswer: {
        '@type': 'Answer',
        text: faq.description,
      },
    });
    return mainEntity;
  }, []);
  const jsonData = { ...body, mainEntity: faqJson };
  return [{ type: 'application/ld+json', innerHTML: JSON.stringify(jsonData) }];
};
