import React from 'react';
import PropTypes from 'prop-types';
import './toolTip.scss';

class ToolTip extends React.Component {
  constructor() {
    super();
    this.state = {
      isVisible: false,
    };
  }

  toggleToolTip = () => {
    this.setState({ isVisible: !this.state.isVisible });
  };

  render() {
    return (
      <div className="consult-tooltip">
        <i
          className="icon-ic_info  tooltip-trigger"
          onClick={this.toggleToolTip}
        />
        {this.state.isVisible && (
          <React.Fragment>
            <div className="overlay" onClick={this.toggleToolTip} />
            <div className="tooltip-content">{this.props.children}</div>
          </React.Fragment>
        )}
      </div>
    );
  }
}

ToolTip.propTypes = {
  children: PropTypes.string,
};

export default ToolTip;
