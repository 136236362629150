import { call, put, takeLatest } from 'redux-saga/effects';
import {
  LOAD_SYMPTOMS,
  LOAD_SYMPTOMS_SUCCESS,
  LOAD_SYMPTOMS_FAILURE,
} from './symptoms.js';
import * as symptomsApi from './symptomsApi.js';

export function* fetchSymptoms() {
  try {
    const response = yield call(symptomsApi.fetchSymptoms);
    yield put({
      type: LOAD_SYMPTOMS_SUCCESS,
      payload: response,
    });
  } catch (err) {
    yield put({
      type: LOAD_SYMPTOMS_FAILURE,
    });
  }
}

export default function* defaultSaga() {
  yield takeLatest(LOAD_SYMPTOMS, fetchSymptoms);
}
