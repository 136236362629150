import React, { useState } from 'react';
import PropTypes from 'prop-types';
import VisibilitySensor from 'react-visibility-sensor';
import StickyFooter from '../../common/sticky-footer/StickyFooter.js';
import { SectionWrapper } from '../../common/section/Section';
import Link from '../../common/link/Link';
import './semBanner.scss';

const SemBanner = ({ heading, subHeading, cta, ctaLink, imageUrl }) => {
  const [isCTAVisibile, setIsCTAVisibile] = useState(false);

  return (
    <React.Fragment>
      <section className="sem-banner">
        <div
          className="sem-banner__bg"
          style={{ backgroundImage: `url('${imageUrl}')` }}
        />
        <SectionWrapper>
          <div className="sem-banner__content">
            <h1>{heading}</h1>
            <h2>{subHeading}</h2>
            <VisibilitySensor onChange={setIsCTAVisibile}>
              <Link className="cta" type="primary-button" href={ctaLink}>
                {cta}
              </Link>
            </VisibilitySensor>
          </div>
          <div className="sem-banner__consult-info">
            <p>
              <i className="icon-ic_heart_filled_system" />
              5000+ online consultations completed in last 24 hours
            </p>
            <p>
              <i className="icon-ic_lock" />
              Your consultations are private, safe and secure
            </p>
          </div>
        </SectionWrapper>
      </section>

      <StickyFooter className="home-sticky-footer" isVisible={!isCTAVisibile}>
        <Link className="cta" type="primary-button" href={ctaLink}>
          {cta}
        </Link>
      </StickyFooter>
    </React.Fragment>
  );
};

SemBanner.propTypes = {
  cta: PropTypes.string.isRequired,
  ctaLink: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  subHeading: PropTypes.string.isRequired,
};

export default SemBanner;
