import { call, put, takeLatest } from 'redux-saga/effects';
import { fetchUserRecentChats } from './recentChatsApi.js';

import {
  LOAD_RECENT_CHATS,
  LOAD_RECENT_CHATS_SUCCESS,
  LOAD_RECENT_CHATS_FAILURE,
} from './recentChats.js';

export function* loadRecentChats() {
  try {
    const response = yield call(fetchUserRecentChats);
    yield put({
      type: LOAD_RECENT_CHATS_SUCCESS,
      payload: response,
    });
  } catch (err) {
    yield put({
      type: LOAD_RECENT_CHATS_FAILURE,
    });
  }
}

export default function* recentChatsSaga() {
  yield takeLatest(LOAD_RECENT_CHATS, loadRecentChats);
}
