export const LOAD_CONSULT_USER_PROFILE = 'LOAD_CONSULT_USER_PROFILE';
export const LOAD_CONSULT_USER_PROFILE_SUCCESS =
  'LOAD_CONSULT_USER_PROFILE_SUCCESS';
export const LOAD_CONSULT_USER_PROFILE_FAILURE =
  'LOAD_CONSULT_USER_PROFILE_FAILURE';
export const UPDATE_API_STATUS = 'CONSULT_USER_PROFILE/UPDATE_API_STATUS';

export const loadConsultUserProfile = () => ({
  type: LOAD_CONSULT_USER_PROFILE,
});

export const updateApiStatus = apiStatus => ({
  type: UPDATE_API_STATUS,
  payload: {
    apiStatus,
  },
});

const initialState = {
  apiStatus: 'PENDING',
};

export default function consultUserProfileReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case UPDATE_API_STATUS:
      return {
        ...state,
        apiStatus: action.payload.apiStatus,
      };

    case LOAD_CONSULT_USER_PROFILE_SUCCESS:
      return {
        ...action.payload,
        apiStatus: 'SUCCESS',
      };

    case LOAD_CONSULT_USER_PROFILE_FAILURE:
      return {
        ...initialState,
        apiStatus: 'FAILURE',
      };

    default:
      return state;
  }
}
