const urlConstants = {
  // Due to the problem in deeplinks redirect for prime consults
  // lead to the wrong app screen, hence a temporary hack to avoid deeplink
  // triggers
  WEBSITE_PREFIX_ALIAS: '/onlineconsult',

  CONSULT_API_PREFIX: '/consult/api/v1/',
  WEBSITE_PREFIX: '/consult',
  WEBSITE_ASSETS_PREFIX: '/consult/static',
  PRACTOSTATIC: '//www.practostatic.com',
  CONSULT_IFRAME_LOGIN: '/consult/login?intent=consult_iframe',
  PAYMENTS_URL: '/payments/request',
  LOGGED_IN_USER: '/consult/logged_in_user',
  PAYMENT_REQUEST: '/payment/request',
  FETCH_BALANCE: 'user/practo_wallet/balance',
  GET_PAID_DOCTORS: '/online/paid/doctors',
  AVAILABLE_DOCTORS: '/available/doctors',
  GET_PROBLEM_AREAS: '/problem_areas',
  GET_OUR_DOCTORS: '/our_doctors',
  DOCTOR_PROFILE: '/paid_consultation_doctors',
  PREDICT_PROBLEM_AREAS: '/predict_problem_areas',
  BROWSER_SUPPORT: '/consult/direct/browser-support',
  USERS_INIT: '/users/init',
  SEND_APP_LINK_SMS: '/marketing/sendapplinksms',
  RECENT_QUESTIONS: '/questions/recent',
  USER_PRIVATE_THREADS: '/user/private_threads',
  SYMPTOMS: '/symptoms',
  SEARCH: '/search',
  USER_COUPONS: '/user/coupons',
  SPECIALITY: '/speciality',
  BANNER_PROPS: '/value_props',
  USER_INFO: '/user/info',
  PAYMENT_CARDS: '/payment/cards',
  PRE_PAYMENT_TRANSACTION_POSSIBLE: '/is_transaction_possible',
  MICROAPP_USER_PROFILE: '/microapp/api/v1/user_details',
  CREATE_CONSULT_USER: '/users/infos',
  AVAILABLE_CONSULTATION_LANG: '/available_consultation_languages',
};

module.exports = urlConstants;
