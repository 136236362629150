import React from 'react';
import PropTypes from 'prop-types';
import DeviceContainer from '../../common/DeviceContainer.js';
import Link from '../../common/link/Link.js';
import { getImageUrl } from '../../../utilities/assetsHelper.js';
import OnlineDoctorsCardContainer from '../../common/online-doctors-card/OnlineDoctorsCardContainer.js';
import SolidBackground from '../../common/solid-background/SolidBackground.js';
import './firstFold.scss';
import LazyImg from '../../common/LazyImg';
import { MIN_CONSULTATION_FEE } from '../../../constants/consultConstants.js';

function FirstFold({ onCtaClick }) {
  return (
    <SolidBackground color="pink">
      <section className="first-fold desktop-wrapper">
        <div className="content">
          <div className="heading">
            Skip the travel!
            <br />
            <h1>Take Online Doctor Consultation</h1>
          </div>
          <p className="description">
            Private consultation + Audio call · Starts at just{' '}
            {MIN_CONSULTATION_FEE}
          </p>
          <OnlineDoctorsCardContainer type="strip" />

          <Link
            className="cta"
            type="primary-button"
            href="/consult/direct/new_consultation"
            onClick={onCtaClick}
          >
            Consult Now
          </Link>
        </div>
        <DeviceContainer>
          {device =>
            device.isDesktop ? (
              <img
                alt="practo banner"
                className="hero-image web"
                src={getImageUrl('homepage-hero-image-web-v1.png')}
              />
            ) : (
              <div className="hero-image mobile">
                <LazyImg
                  loading="eager"
                  alt="practo banner"
                  src={getImageUrl('homepage-hero-image-mweb-v1.png')}
                  width="320px"
                  height="152px"
                  layout="responsive"
                />
              </div>
            )
          }
        </DeviceContainer>

        <div className="additional-value-prop">
          <i className="icon-ic_awards" />
          <p>Verified Doctors</p>

          <i className="icon_prescription" />
          <p>Digital Prescription</p>

          <i className="icon-ic_message" />
          <p>Free Followup</p>
        </div>
      </section>
    </SolidBackground>
  );
}

FirstFold.propTypes = {
  onCtaClick: PropTypes.func.isRequired,
};

export default FirstFold;
