import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import VisibilitySensor from 'react-visibility-sensor';
import { getImageUrl } from '../../../utilities/assetsHelper.js';

const VideoCard = ({ isAmp, title, videoId }) => {
  const [showVideo, setShowVideo] = useState(false);
  const [isIframeLoading, setIframeLoading] = useState(true);
  const img_src = getImageUrl(`youtube/${videoId}.jpg`);
  const embed_video_url = `https://www.youtube.com/embed/${videoId}`;

  function onVisibleChange(isVisible) {
    if (isVisible) setShowVideo(true);
  }

  return (
    <div className="video-card">
      {isAmp ? (
        <amp-youtube
          width="169"
          height="95"
          layout="responsive"
          data-videoid={videoId}
        />
      ) : (
        <VisibilitySensor partialVisibility={true} onChange={onVisibleChange}>
          <>
            {showVideo && (
              <iframe
                src={embed_video_url}
                onLoad={() => setIframeLoading(false)}
                className={classnames('video-card__content', {
                  hidden: isIframeLoading,
                })}
              />
            )}

            {isIframeLoading && (
              <img className="video-card__content" src={img_src} alt={title} />
            )}
          </>
        </VisibilitySensor>
      )}
      <p className="video-card__title">{title}</p>
    </div>
  );
};

VideoCard.propTypes = {
  isAmp: PropTypes.bool,
  title: PropTypes.string.isRequired,
  videoId: PropTypes.string.isRequired,
};

export default VideoCard;
