import React from 'react';
import { Route, Switch } from 'react-router-dom';
import routes from './routes.js';
import { hasSessionStorageSupport } from './utilities/domHelpers.js';
import urlConstants from './constants/urlConstants.js';
import 'delayed-scroll-restoration-polyfill';

const ClientApp = () => (
  <Switch>
    {routes.map(route => (
      <Route
        exact
        key={route.id}
        path={route.path}
        component={props => {
          const Component = route.component.default;

          const redirectIfNoSessionStorage = route.redirectIfNoSessionStorage;
          if (redirectIfNoSessionStorage && !hasSessionStorageSupport()) {
            window.location = urlConstants.BROWSER_SUPPORT;
            return null;
          }

          return <Component {...props} routeId={route.id} />;
        }}
      />
    ))}
  </Switch>
);

export default ClientApp;
