import React from 'react';
import { hydrate } from 'react-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/browser';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter as Router } from 'react-router-dom';

import createAppStore from './redux/createAppStore.js';
import './app.scss';
import { CLIENT_SENTRY_DSN } from '../config/client.js';
import i18n from './i18n';
import BaseApp from './BaseApp';
import ClientApp from './ClientApp.js';
import marketplaceAttribution from './utilities/marketplaceAttribution.js';

Sentry.init({ dsn: CLIENT_SENTRY_DSN });

// Grab the state from a global variable injected into the server-generated HTML
const { store } = createAppStore(window.__INITIAL_STATE__);
// Allow the passed state to be garbage-collected
delete window.__INITIAL_STATE__;

// Save marketplace attribution on first client app load
marketplaceAttribution.get();

hydrate(
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <Router forceRefresh={true}>
        <BaseApp>
          <ClientApp />
        </BaseApp>
      </Router>
    </I18nextProvider>
  </Provider>,
  document.getElementById('root')
);

if (module.hot) {
  module.hot.accept();
}
