import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { initiateLogin } from '../../../redux/ducks/login-modal/loginModal.js';
import Nav from './Nav.js';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

const NavContainer = props => {
  const location = useLocation();
  const query = queryString.parse(location.search);
  useEffect(() => {
    if (!props.isLoggedIn && query.login) {
      props.initiateLogin();
    }
  }, [props, props.isLoggedIn, query.login]);
  return <Nav {...props} />;
};

NavContainer.propTypes = {
  isLoggedIn: PropTypes.bool,
  initiateLogin: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    isLoggedIn: state.user.isLoggedIn,
    userId: state.user.id,
    userName: state.user.user_name,
    userMobile: state.user.mobile,
    userEmail: state.user.email,
    isDoctor: state.user.user_role === 'DOCTOR',
    ...ownProps,
  };
};

const mapDispatchToProps = dispatch => ({
  initiateLogin: () => dispatch(initiateLogin()),
});

export default connect(mapStateToProps, mapDispatchToProps)(NavContainer);
