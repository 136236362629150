export const LOAD_SYMPTOMS = 'SYMPTOMS/LOAD';
export const LOAD_SYMPTOMS_SUCCESS = 'SYMPTOMS/LOAD/SUCCESS';
export const LOAD_SYMPTOMS_FAILURE = 'SYMPTOMS/LOAD/FAILURE';

const initialState = [];

export default function symptomsReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_SYMPTOMS_SUCCESS:
      return action.payload;

    case LOAD_SYMPTOMS_FAILURE:
      return initialState;

    default:
      return state;
  }
}

export function loadSymptoms() {
  return {
    type: LOAD_SYMPTOMS,
  };
}
