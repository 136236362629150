import { selectPaymentsState } from '../selectors';

export const FETCH_AVAILABLE_LANGUAGES = 'PAYMENTS/FETCH_AVAILABLE_LANGUAGES';
export const FETCH_AVAILABLE_LANGUAGES_LOADING =
  'PAYMENTS/FETCH_AVAILABLE_LANGUAGES/LOADING';
export const FETCH_AVAILABLE_LANGUAGES_SUCCESS =
  'PAYMENTS/FETCH_AVAILABLE_LANGUAGES/SUCCESS';
export const FETCH_AVAILABLE_LANGUAGES_FAILURE =
  'PAYMENTS/FETCH_AVAILABLE_LANGUAGES/FAILURE';
export const SET_PREFERRED_LANGUAGE_FABRIC_ID =
  'PAYMENTS/SET_PREFERRED_LANGUAGE_FABRIC_ID';

export const APPLY_COUPON = 'PAYMENTS/COUPON/APPLY';
export const APPLY_COUPON_FAILED = 'PAYMENTS/COUPON/FAILED';
export const APPLY_COUPON_SUCCESS = 'PAYMENTS/COUPON/SUCCESS';

export const REMOVE_COUPON = 'PAYMENTS/COUPON/REMOVE';
export const REMOVE_COUPON_SUCCESS = 'PAYMENTS/COUPON/REMOVE_SUCCESS';

export const CALCULATE_FINAL_FEE = 'PAYMENTS/FINAL_FEE';
export const CALCULATE_FINAL_FEE_SUCCESS = 'PAYMENTS/FINAL_FEE/SUCCESS';
export const UPDATE_FINAL_FEE = 'PAYMENTS/FINAL_FEE/UPDATE';
export const REFRESH_FEE = 'PAYMENTS/REFRESH_FEE';

export const FETCH_PRACTO_WALLET_BALANCE_SUCCESS =
  'PAYMENTS/PRACTO_WALLET_BALANCE/SUCCESS';
export const FETCH_PRACTO_WALLET_BALANCE_FAILURE =
  'PAYMENTS/PRACTO_WALLET_BALANCE/FAILURE';

export const MAKE_PAYMENT_REQUEST = 'PAYMENTS/REQUEST';
export const MAKE_PAYMENT_REQUEST_FAILED = 'PAYMENTS/REQUEST/FAILED';
export const MAKE_PAYMENT_REQUEST_SUCCESS = 'PAYMENTS/REQUEST/SUCCESS';

export const TOGGLE_COUPON_MODAL = 'PAYMENTS/COUPON_MODAL/TOGGLE';
export const TOGGLE_FEE_BREAKUP = 'PAYMENTS/FEE_BREAKUP/TOGGLE';
export const UPDATE_COUPON = 'PAYMENTS/COUPON/UPDATE';
export const UPDATE_PAYMENT_REQUEST_STATUS = 'PAYMENTS/REQUEST/UPDATE_STATUS';
export const NOTIFY_PAYMENT_FAILURE = 'PAYMENTS/NOTIFY_FAILURE';
export const FETCH_USER_COUPONS = 'PAYMENTS/FETCH_USER_COUPONS';
export const FETCH_USER_COUPONS_SUCCESS = 'PAYMENTS/FETCH_USER_COUPONS/SUCCESS';
export const FETCH_USER_COUPONS_LOADING = 'PAYMENTS/FETCH_USER_COUPONS/LOADING';
export const FETCH_USER_COUPONS_FAILURE = 'PAYMENTS/FETCH_USER_COUPONS/FAILURE';

export const FETCH_PAYMENT_CARDS = 'PAYMENTS/CARDS';
export const FETCH_PAYMENT_CARDS_SUCCESS = 'PAYMENTS/CARDS/SUCCESS';
export const FETCH_PAYMENT_CARDS_FAILURE = 'PAYMENTS/CARDS/FAILURE';
export const UPDATE_SELECTED_CARD_INDEX = 'PAYMENTS/CARDS/SELECT';
export const DO_AFTER_CARDS_LOAD = 'PAYMENTS/DO_AFTER_CARDS_LOAD';

const initialState = {
  appliedCoupon: '',
  autoApplyCoupon: '',
  couponAmount: 0,
  couponMessage: '',
  couponErrMessage: '',
  currencySymbol: '',
  currencySymbolPrefix: '',
  formValues: null,
  healthCashAmount: 0,
  practoWalletBalance: 0,
  practoWalletBalanceNote: '',
  showCouponModal: false,
  showFeeBreakup: false,
  fee: 0,
  totalAmount: 0,
  paymentRequestStatus: '',
  preferredLanguageFabricId: null,
  availableLanguages: {
    languages: [],
    loading: false,
    error: false,
  },
  userCoupons: {
    coupons: [],
    loading: false,
    error: false,
  },
  cards: [],
  selectedCardIndex: null,
};

export function getSelectedCard(state) {
  const myState = selectPaymentsState(state);
  return myState.cards[myState.selectedCardIndex];
}

export default function paymentsReducer(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_PAYMENT_REQUEST_STATUS:
      return {
        ...state,
        paymentRequestStatus: action.payload.status,
      };

    case APPLY_COUPON_SUCCESS:
      return {
        ...state,
        showCouponModal: false,
        couponErrMessage: action.payload.couponErrMessage,
        couponMessage: action.payload.couponMessage,
        couponAmount: action.payload.couponAmount,
      };

    case APPLY_COUPON_FAILED:
      return {
        ...state,
        couponErrMessage: action.payload.couponErrMessage,
        couponMessage: action.payload.couponMessage,
        couponAmount: action.payload.couponAmount,
      };

    case REMOVE_COUPON_SUCCESS:
      return {
        ...state,
        appliedCoupon: '',
        couponErrMessage: '',
        couponMessage: '',
        couponAmount: 0,
      };

    case UPDATE_FINAL_FEE:
      return {
        ...state,
        ...action.payload,
      };

    case FETCH_PRACTO_WALLET_BALANCE_SUCCESS:
      return {
        ...state,
        practoWalletBalance: parseFloat(
          action.payload.usablePractoWalletBalance
        ),
        practoWalletBalanceNote: action.payload.note,
      };

    case MAKE_PAYMENT_REQUEST_FAILED:
      return {
        ...state,
        formValues: null,
      };

    case MAKE_PAYMENT_REQUEST_SUCCESS:
      return {
        ...state,
        formValues: action.payload,
      };

    case TOGGLE_COUPON_MODAL:
      return {
        ...state,
        showCouponModal: !state.showCouponModal,
      };

    case TOGGLE_FEE_BREAKUP:
      return {
        ...state,
        showFeeBreakup: true,
      };

    case UPDATE_COUPON:
      return {
        ...state,
        appliedCoupon: action.payload,
      };
    case FETCH_USER_COUPONS_LOADING:
      return {
        ...state,
        userCoupons: {
          loading: true,
          coupons: [],
        },
      };
    case FETCH_USER_COUPONS_SUCCESS:
      return {
        ...state,
        userCoupons: {
          loading: false,
          coupons: action.payload,
        },
      };
    case FETCH_USER_COUPONS_FAILURE:
      return {
        ...state,
        userCoupons: {
          loading: false,
          error: true,
          coupons: [],
        },
      };

    case FETCH_AVAILABLE_LANGUAGES_LOADING:
      return {
        ...state,
        availableLanguages: {
          languages: [],
          loading: true,
          error: false,
        },
      };
    case FETCH_AVAILABLE_LANGUAGES_SUCCESS:
      return {
        ...state,
        availableLanguages: {
          languages: action.payload,
          loading: false,
          error: false,
        },
      };
    case FETCH_AVAILABLE_LANGUAGES_FAILURE:
      return {
        ...state,
        availableLanguages: {
          languages: [],
          loading: false,
          error: true,
        },
      };
    case SET_PREFERRED_LANGUAGE_FABRIC_ID:
      return {
        ...state,
        preferredLanguageFabricId: action.payload.preferredLanguageFabricId,
      };

    case FETCH_PAYMENT_CARDS:
    case FETCH_PAYMENT_CARDS_FAILURE:
      return {
        ...state,
        cards: initialState.cards,
        selectedCardIndex: initialState.selectedCardIndex,
      };

    case FETCH_PAYMENT_CARDS_SUCCESS: {
      let selectedCardIndex = initialState.selectedCardIndex;
      let cards = action.payload;
      cards.forEach((card, index) => {
        if (card.selected) {
          selectedCardIndex = index;
        }
      });

      return {
        ...state,
        cards,
        selectedCardIndex,
      };
    }

    case UPDATE_SELECTED_CARD_INDEX:
      return {
        ...state,
        selectedCardIndex: action.payload.cardIndex,
      };

    default:
      return { ...state };
  }
}

export function applyCoupon(payload) {
  return {
    type: APPLY_COUPON,
    payload,
  };
}

export function calculateFinalFee() {
  return {
    type: CALCULATE_FINAL_FEE,
  };
}

export function makePaymentRequest(payload) {
  return {
    type: MAKE_PAYMENT_REQUEST,
    payload,
  };
}

export function toggleCouponModal() {
  return {
    type: TOGGLE_COUPON_MODAL,
  };
}

export function toggleFeeBreakup() {
  return {
    type: TOGGLE_FEE_BREAKUP,
  };
}

export function updateCoupon(code) {
  return {
    type: UPDATE_COUPON,
    payload: code.toUpperCase(),
  };
}

export function updatePaymentRequestStatus(status) {
  return {
    type: UPDATE_PAYMENT_REQUEST_STATUS,
    payload: {
      status,
    },
  };
}

export function notifyPaymentFailure() {
  return {
    type: NOTIFY_PAYMENT_FAILURE,
  };
}

export function setPreferredLanguage(id) {
  return {
    type: SET_PREFERRED_LANGUAGE_FABRIC_ID,
    payload: {
      preferredLanguageFabricId: id,
    },
  };
}

export function fetchAvailableLanguages() {
  return {
    type: FETCH_AVAILABLE_LANGUAGES,
  };
}

export function fetchUserCoupons() {
  return {
    type: FETCH_USER_COUPONS,
  };
}

export function refreshFee() {
  return {
    type: REFRESH_FEE,
  };
}

export function removeCoupon() {
  return {
    type: REMOVE_COUPON,
  };
}

export const fetchPaymentCards = (
  problemAreaId,
  subscriptionPlanId,
  serviceId,
  doctorAccountId
) => ({
  type: FETCH_PAYMENT_CARDS,
  payload: {
    problemAreaId,
    subscriptionPlanId,
    serviceId,
    doctorAccountId,
  },
});

export const updateSelectedCardIndex = cardIndex => ({
  type: UPDATE_SELECTED_CARD_INDEX,
  payload: {
    cardIndex,
  },
});

export const doAfterCardsLoad = callback => ({
  type: DO_AFTER_CARDS_LOAD,
  payload: {
    callback,
  },
});
