import React, { useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { loadRecentChats } from '../../../redux/ducks/recent-chats/recentChats.js';
import RecentChat from './RecentChat.js';

function RecentChatsContainer() {
  const dispatch = useDispatch();
  const { recentChat, isLoggedIn, showViewAllButton } = useSelector(
    state => ({
      recentChat: state.recentChats[0],
      showViewAllButton: state.recentChats.length > 1,
      isLoggedIn: state.user.isLoggedIn,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (isLoggedIn && !recentChat) {
      dispatch(loadRecentChats());
    }
  }, [dispatch, isLoggedIn, recentChat]);

  if (!recentChat) {
    return null;
  }

  return <RecentChat {...recentChat} showViewAllButton={showViewAllButton} />;
}

export default RecentChatsContainer;
