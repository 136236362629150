import React from 'react';
import PropTypes from 'prop-types';
import CouponCard from './CouponCard';
import Loader from '../../common/loader/Loader';

const AvailableCoupons = ({ userCoupons }) => {
  return (
    <div className="available-offers">
      <div className="available-offers__heading">AVAILABLE OFFERS</div>

      {userCoupons.loading && (
        <div className="no-offer">
          <Loader type="inline" />
        </div>
      )}

      {!userCoupons.loading && !userCoupons.coupons.length && (
        <div className="no-offer">No offers available</div>
      )}

      {userCoupons.coupons.map(coupon => (
        <CouponCard key={coupon.id} coupon={coupon} />
      ))}
    </div>
  );
};

AvailableCoupons.propTypes = {
  userCoupons: PropTypes.object,
};

export default AvailableCoupons;
