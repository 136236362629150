import React from 'react';
import PropTypes from 'prop-types';
import { getImageUrl } from '../../utilities/assetsHelper.js';
import Link from '../common/link/Link';
import './doctorCard.scss';
import LazyImg from '../common/LazyImg.js';

function DoctorCardSmall({
  name,
  profile_picture,
  specialities,
  years_of_experience,
  consults_done,
  onCtaClick,
}) {
  return (
    <section className="doctor-card doctor-card--small" onClick={onCtaClick}>
      <div className="doctor-card__left">
        <figure className="doctor-card__profile-picture">
          <LazyImg
            height="54px"
            width="54px"
            alt={name}
            src={profile_picture || getImageUrl('doctor-profile-v1.png')}
          />
        </figure>
      </div>

      <div className="doctor-card__content ellipsis">
        <h4 className="doctor-card__name ellipsis">{name}</h4>
        <p className="ellipsis">{specialities}</p>
        {years_of_experience > 0 && (
          <p>
            {years_of_experience} year{years_of_experience > 1 ? 's' : ''}{' '}
            experience
          </p>
        )}
        {consults_done > 0 && <p>{consults_done} consults done</p>}
        {onCtaClick && (
          <Link className="doctor-card__cta" type="primary" tagName="span">
            Know more
          </Link>
        )}
      </div>
    </section>
  );
}

DoctorCardSmall.propTypes = {
  name: PropTypes.string.isRequired,
  specialities: PropTypes.string.isRequired,
  profile_picture: PropTypes.string,
  years_of_experience: PropTypes.number,
  consults_done: PropTypes.string,
  onCtaClick: PropTypes.func,
};

export default React.memo(DoctorCardSmall);
