import React, { useRef, useEffect } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import defaultReactModalStyle from './reactModalDefaultStyle.js';
import { isDeviceIOS } from '../../../utilities/domHelpers.js';

function FullScreenModal({ children, isOpen, ...props }) {
  const ref = useRef(null);

  useEffect(() => {
    if (isOpen) {
      const refCurrent = ref.current;
      disableBodyScroll(refCurrent, {
        allowTouchMove: () => {
          if (isDeviceIOS()) {
            return true;
          }
        },
      });

      return () => {
        enableBodyScroll(refCurrent);
      };
    }
  }, [isOpen]);

  return (
    <Modal
      ref={ref}
      {...props}
      isOpen={isOpen}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={true}
      style={{
        ...defaultReactModalStyle,
        content: {
          ...defaultReactModalStyle.content,
          height: '100vh',
          width: '100%',
          overflow: 'scroll',
          display: 'flex',
          flexDirection: 'column',
          background: '#F0F0F5',
        },
      }}
    >
      {children}
    </Modal>
  );
}

FullScreenModal.propTypes = {
  isOpen: PropTypes.bool,
  children: PropTypes.any,
};

export default FullScreenModal;
