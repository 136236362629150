export const UPDATE_DEVICE = 'UPDATE_DEVICE';

export const updateDevice = (isDesktop, isAmp) => ({
  type: UPDATE_DEVICE,
  payload: { isAmp, isDesktop },
});

export const initialState = {
  isDesktop: null,
  isAmp: null,
};

export const deviceReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_DEVICE:
      return {
        isDesktop: action.payload.isDesktop,
        isAmp: action.payload.isAmp,
      };
    default:
      return state;
  }
};

export default deviceReducer;
