import React from 'react';
import PropTypes from 'prop-types';
const BREAK_POINTS = ['sm', 'md', 'lg', 'xl'];

export const Row = ({ children, testid }) => (
  <div className="grid__row" data-testid={testid}>
    {children}
  </div>
);

export const Col = props => {
  let columnClass = props.className || '';
  let TagName = props.tagName || 'div';
  BREAK_POINTS.forEach(breakPoint => {
    if (props[breakPoint]) {
      columnClass += ` grid__col-${breakPoint}-${props[breakPoint]}`;
    }
  });
  return (
    <TagName data-testid={props.testid} className={columnClass}>
      {props.children}
    </TagName>
  );
};

Row.propTypes = {
  children: PropTypes.any,
  testid: PropTypes.string,
};

Col.propTypes = {
  children: PropTypes.any,
  testid: PropTypes.string,
  tagName: PropTypes.string,
  className: PropTypes.string,
  sm: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  md: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  lg: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  xl: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
