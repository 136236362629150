import React from 'react';
import PropTypes from 'prop-types';
import NewConsultHeader from '../new-consult-header/NewConsultHeader.js';
import Link from '../../common/link/Link.js';
import {
  getChatDetailsUrl,
  getChatListUrl,
} from '../../../utilities/urlHelper.js';
import { getImageUrl } from '../../../utilities/assetsHelper.js';
import './recentChat.scss';

function RecentChat({
  id,
  doctor_name,
  doctor_profile_picture,
  status,
  showViewAllButton,
}) {
  return (
    <div className="recent-chat">
      <NewConsultHeader
        title="Consult With Previous Doctor"
        subtitle="Recent doctors you have consulted with"
      />
      <div className="recent-chat__cards">
        <Link
          href={getChatDetailsUrl(id)}
          className="recent-chat__card recent-chat__card--rich"
        >
          <figure>
            <img
              alt="doctor"
              src={
                doctor_profile_picture || getImageUrl('doctor-profile-v1.png')
              }
            />
          </figure>
          <div>
            <p className="recent-chat__doctor-name ellipsis">{doctor_name}</p>
            <p className="recent-chat__status">{status}</p>
          </div>
        </Link>
        {showViewAllButton && (
          <Link
            href={getChatListUrl()}
            className="recent-chat__card recent-chat__view-all"
          >
            View Previous Consultations
          </Link>
        )}
      </div>
    </div>
  );
}

RecentChat.propTypes = {
  id: PropTypes.number.isRequired,
  doctor_name: PropTypes.string.isRequired,
  doctor_profile_picture: PropTypes.string,
  status: PropTypes.string.isRequired,
  showViewAllButton: PropTypes.bool.isRequired,
};

export default RecentChat;
