import consultRequestCreator from '../../consultRequestCreator';
import urlConstants from '../../../constants/urlConstants.js';

export function fetchUserRecentChats() {
  return consultRequestCreator
    .get(urlConstants.USER_PRIVATE_THREADS, {
      params: {
        limit: 2,
      },
    })
    .then(response => response.data.private_threads);
}
