import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import useTrackEvent from '../../../hooks/useTrackEvent.js';
import { PEL_ACTIONS } from '../../../constants/PELConstants.js';
import TrackOnceInViewContainer from '../TrackOnceInViewContainer.js';

const TrackSectionView = ({ children, objectName, commonContext }) => {
  const [trackView] = useTrackEvent(objectName, commonContext);

  useEffect(() => {
    trackView();
  }, [trackView]);

  return children.map((section, index) => {
    const thisObjectContext = {
      ...commonContext,
      'Section Position': index + 1,
      'Section Type': section.props.sectionType,
    };
    return (
      <TrackOnceInViewContainer
        key={section.key}
        object={objectName}
        action={PEL_ACTIONS.viewed}
        objectContext={thisObjectContext}
      >
        {section}
      </TrackOnceInViewContainer>
    );
  });
};

TrackSectionView.propTypes = {
  commonContext: PropTypes.object,
  objectName: PropTypes.string.isRequired,
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
};

export default TrackSectionView;
