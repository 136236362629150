import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';
import NavContainer from './NavContainer';
import NavStatic from './NavStatic';

const NavWrapper = ({ routeId, isStatic }) => {
  const key = 'NavContainer';
  const { isAmp } = useSelector(
    state => ({ isAmp: state.device.isAmp }),
    shallowEqual
  );

  if (isAmp || isStatic) return <NavStatic key={key} />;

  return <NavContainer key={key} routeId={routeId} />;
};

NavWrapper.propTypes = {
  isStatic: PropTypes.bool,
  routeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default NavWrapper;
