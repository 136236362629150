import Fingerprint2 from 'fingerprintjs2';

let fingerprintHash = null;

function generateFingerprint() {
  if (fingerprintHash) {
    return Promise.resolve(fingerprintHash);
  }

  return Fingerprint2.getPromise().then(components => {
    var values = components.map(component => component.value);
    fingerprintHash = Fingerprint2.x64hash128(values.join(''), 31);

    return fingerprintHash;
  });
}

export default {
  generate: generateFingerprint,
};
