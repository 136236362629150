import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  setPreferredLanguage,
  fetchAvailableLanguages,
} from '../../../redux/ducks/payments/payments';
import './languagePreference.scss';

const LanguagePreferenceSection = () => {
  const dispatch = useDispatch();
  const { languages, selectedLang } = useSelector(
    state => ({
      selectedLang: state.payments.preferredLanguageFabricId,
      languages: state.payments.availableLanguages.languages,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(fetchAvailableLanguages());
  }, [dispatch]);

  const [is_language_selected, setLanguageSelected] = useState(false);

  const english_lang = languages.find(lang => lang.language === 'English');

  if (!is_language_selected && english_lang) {
    dispatch(setPreferredLanguage(english_lang.language_fabric_id));
  }

  if (languages.length)
    return (
      <div className="preferred-lang">
        <i className="icon-ic_message" />
        <div className="preferred-lang__container">
          <div className="preferred-lang__title">
            Choose your preferred language
          </div>
          <div className="preferred-lang__text">
            We will try to find doctors who can speak the language which you are
            comfortable with.
          </div>
          <div className="preferred-lang__options">
            {languages.map(
              ({ language, language_display_name, language_fabric_id }) => (
                <span
                  key={language_fabric_id}
                  onClick={() => {
                    dispatch(setPreferredLanguage(language_fabric_id));
                    setLanguageSelected(true);
                  }}
                  className={classnames('preferred-lang__pill', {
                    selected:
                      language_fabric_id === selectedLang ||
                      (language === 'English' && !is_language_selected),
                  })}
                >
                  {language_display_name || language}
                </span>
              )
            )}
          </div>
        </div>
      </div>
    );

  return null;
};

export default LanguagePreferenceSection;
