import React from 'react';
import PropTypes from 'prop-types';
import { getImageUrl } from '../../../utilities/assetsHelper.js';
import Link from '../../common/link/Link.js';
import LazyImg from '../../common/LazyImg.js';

function HealthProblemCard({
  imageName,
  heading,
  problemAreaId,
  onClick,
  price,
  currencySymbol,
}) {
  const url = `/consult/direct/new_consultation?id=${problemAreaId}`;
  return (
    <Link onClick={onClick} className="health-problem-card" href={url}>
      <LazyImg
        src={getImageUrl(imageName)}
        alt={heading}
        width="150px"
        height="100px"
        layout="responsive"
      />
      <div className="content">
        <h4 className="heading">{heading}</h4>
        <p className="price">
          {currencySymbol} {price}
        </p>
        <Link type="primary" className="cta" tagName="span">
          Consult Now <span className="icon-ic_next_cheveron" />
        </Link>
      </div>
    </Link>
  );
}

HealthProblemCard.propTypes = {
  imageName: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  problemAreaId: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  price: PropTypes.string.isRequired,
  currencySymbol: PropTypes.string.isRequired,
};

export default HealthProblemCard;
