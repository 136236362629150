import React from 'react';
import Card from '../../common/Card/Card';
import { Col, Row } from '../../common/Grid/Grid';
import { SectionHeading, SectionWrapper } from '../../common/section/Section';

import { BenefitsSection } from '../../../constants/homepageConstants';
const Benefits = () => (
  <SectionWrapper>
    <SectionHeading>{BenefitsSection.heading}</SectionHeading>
    <Card paper>
      <Row>
        {BenefitsSection.list.map((card_data, idx) => (
          <Col md="4" key={`benefits_${idx}`}>
            <div className="u-spacer__15x">
              <h3 className="u-spacer__7x-bottom u-font__s-subheading">
                <i
                  className="icon-ic_done_open_system u-v_align--middle
                  u-font__s-jumbo u-ic__no-margin"
                />
                {card_data.title}
              </h3>
              <p className="u-font__c-gray3">{card_data.description}</p>
            </div>
          </Col>
        ))}
      </Row>
    </Card>
  </SectionWrapper>
);

Benefits.propTypes = {};
export default Benefits;
