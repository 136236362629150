import config from '../../../config/client';

const initializePELTracker = () => {
  if (!window.pelTracker && typeof window.Tracker !== 'undefined') {
    const enableDebug = config.PEL_URL;
    window.pelTracker = new window.Tracker({ debug: enableDebug });
  }
};

const sendPelEvent = ({
  object,
  action,
  actionContext,
  objectContext,
  actor,
  actorContext,
}) => {
  if (!window.pelTracker) {
    initializePELTracker();
  }

  if (window.pelTracker) {
    const event = window.pelTracker.createEvent({
      object,
      action,
      actor,
    });
    event.setContexts({
      object: objectContext,
      action: actionContext,
      actor: actorContext,
    });
    event.logEvent();
  }
};

export default sendPelEvent;
