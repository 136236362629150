import { combineReducers } from 'redux';
import { all } from 'redux-saga/effects';
import { reducer as notifications } from 'react-notification-system-redux';

import symptomsReducer from './symptoms/symptoms';
import problemAreasReducer from './problem-areas/problemAreas';
import newConsultReducer from './new-consult/newConsult.js';
import paymentsReducer from './payments/payments';
import loginModalReducer from './login-modal/loginModal';
import experimentsReducer from './experiments.js';
import userReducer from './user/user.js';
import onlineDoctorsReducer from './online-doctors/onlineDoctors.js';
import usersInitReducer from './users-init/usersInit.js';
import deviceReducer from './device.js';
import appLinkReducer from './app-link/appLink.js';
import seoReducer from './seo/seo.js';
import doctorProfileReducer from './doctor-profile/doctorProfile.js';
import recentChatsReducer from './recent-chats/recentChats.js';
import searchReducer from './search/search.js';
import featureMapReducer from './feature-map/featureMap.js';
import bannerPropsReducer from './banner-props/bannerProps.js';
import consultUserProfileReducer from './consult-user-profile/consultUserProfile.js';
import availableDoctorsReducer from './available-doctors/availableDoctors.js';

import symptomsSaga from './symptoms/symptomsSaga';
import problemAreasSaga from './problem-areas/problemAreasSaga';
import paymentsSaga from './payments/paymentsSaga';
import loginModalSaga from './login-modal/loginModalSaga.js';
import analyticsSaga from './analytics/analyticsSaga';
import userSaga from './user/userSaga.js';
import newConsultSaga from './new-consult/newConsultSaga.js';
import onlineDoctorsSaga from './online-doctors/onlineDoctorsSaga.js';
import usersInitSaga from './users-init/usersInitSaga.js';
import appLinkSaga from './app-link/appLinkSaga.js';
import seoSaga from './seo/seoSaga.js';
import doctorProfileSaga from './doctor-profile/doctorProfileSaga.js';
import recentChatsSaga from './recent-chats/recentChatsSaga.js';
import searchSaga from './search/searchSaga.js';
import bannerPropsSaga from './banner-props/bannerPropsSaga.js';
import consultUserProfileSaga from './consult-user-profile/consultUserProfileSaga.js';
import availableDoctorsSaga from './available-doctors/availableDoctorsSaga.js';

const reducers = combineReducers({
  newConsult: newConsultReducer,
  loginModal: loginModalReducer,
  notifications,
  payments: paymentsReducer,
  problemAreas: problemAreasReducer,
  symptoms: symptomsReducer,
  experiments: experimentsReducer,
  user: userReducer,
  onlineDoctors: onlineDoctorsReducer,
  usersInit: usersInitReducer,
  device: deviceReducer,
  appLink: appLinkReducer,
  seo: seoReducer,
  doctorProfile: doctorProfileReducer,
  recentChats: recentChatsReducer,
  search: searchReducer,
  featureMap: featureMapReducer,
  bannerProps: bannerPropsReducer,
  consultUserProfile: consultUserProfileReducer,
  availableDoctors: availableDoctorsReducer,
});

const sagas = function* rootSaga() {
  yield all([
    symptomsSaga(),
    problemAreasSaga(),
    paymentsSaga(),
    loginModalSaga(),
    userSaga(),
    newConsultSaga(),
    analyticsSaga(),
    onlineDoctorsSaga(),
    usersInitSaga(),
    appLinkSaga(),
    seoSaga(),
    doctorProfileSaga(),
    recentChatsSaga(),
    searchSaga(),
    bannerPropsSaga(),
    consultUserProfileSaga(),
    availableDoctorsSaga(),
  ]);
};

export default {
  reducers,
  sagas,
};
