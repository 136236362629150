import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';
import {
  SectionWrapper,
  SectionHeading,
} from '../../common/section/Section.js';
import VideoCard from './VideoCard.js';
import './videoCards.scss';

const VideoCardsContainer = ({ heading, videos }) => {
  const { isAmp } = useSelector(
    state => ({ isAmp: state.device.isAmp }),
    shallowEqual
  );

  return (
    <SectionWrapper type="horizontal-scroll">
      <SectionHeading type="horizontal-scroll">{heading}</SectionHeading>

      <div className="video-cards">
        {videos.map((video, index) => (
          <VideoCard
            key={index}
            isAmp={isAmp}
            title={video.title}
            videoId={video.youtubeID}
          />
        ))}
      </div>
    </SectionWrapper>
  );
};

VideoCardsContainer.propTypes = {
  heading: PropTypes.string.isRequired,
  videos: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      youtubeID: PropTypes.string.isRequired,
    })
  ),
};

export default VideoCardsContainer;
