import React from 'react';
import Slider from '../../common/Slider/Slider.js';
import PropTypes from 'prop-types';
import './horizontalCardsList.scss';

const TYPES = {
  AMP: 'amp',
  SLIDER: 'slider',
};

function HorizontalCardsList({ children, type, sliderConfig }) {
  const horizontalCards = children
    .filter(card => !!card)
    .map(card => (
      <div className="horizontal-list-card" key={card.key}>
        {card}
      </div>
    ));

  switch (type) {
    case TYPES.AMP:
      return (
        <amp-carousel height="200" width="410" layout="fixed" type="carousel">
          {horizontalCards}
        </amp-carousel>
      );
    case TYPES.SLIDER:
      return <Slider slides={horizontalCards} {...sliderConfig} />;
    default:
      return (
        <section className="horizontal-cards-list hide-scrollbar">
          {horizontalCards}
        </section>
      );
  }
}

HorizontalCardsList.propTypes = {
  children: PropTypes.array,
  type: PropTypes.oneOf(Object.values(TYPES)),
  sliderConfig: PropTypes.object,
};

export default HorizontalCardsList;
