import React from 'react';
import PropTypes from 'prop-types';
import clientConfig from '../../../../config/client.js';
import { getSubdomainUrl } from '../../../utilities/urlHelper.js';
import './nav.scss';

class Nav extends React.Component {
  constructor(props) {
    super(props);
    this.navSlotRef = React.createRef();
    this.navSlotId = 'practo-global-navigation-slot';

    this.initialiseNav = this.initialiseNav.bind(this);
    this.getNavConfig = this.getNavConfig.bind(this);
    this.isPractoNavLoaded = this.isPractoNavLoaded.bind(this);
    this.getLoggedInUserConfig = this.getLoggedInUserConfig.bind(this);
  }

  componentDidMount() {
    this.loadScript(this.initialiseNav);
  }

  componentDidUpdate(prevProps) {
    // If nav is loaded & the user tries to log in, update the user details on nav
    if (
      !prevProps.isLoggedIn &&
      this.props.isLoggedIn &&
      this.isPractoNavLoaded()
    ) {
      const loggedInUserConfig = this.getLoggedInUserConfig();
      window.$('#' + this.navSlotId).practoNav('updateNav', loggedInUserConfig);
    }
  }

  render() {
    return (
      <React.Fragment>
        {/* After nav is loaded it will be inserted in this slot */}
        <div id={this.navSlotId} ref={this.navSlotRef} />
        {/*
        Static placeholder nav, useful until the nav is not loaded.
        This is removed by the nav itself post load
        */}
        <div className="practo_GlobalNavigation static" />
      </React.Fragment>
    );
  }

  loadScript(callback) {
    const navScriptUrl = `${getSubdomainUrl('nav')}/${
      clientConfig.CONSUMER_NAV_VERSION
    }/consumer/js/practonav.js`;
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.onload = callback.bind(this);
    script.src = navScriptUrl;
    document.body.appendChild(script);
  }

  initialiseNav() {
    if (this.isPractoNavLoaded()) {
      const navbarConfig = this.getNavConfig();
      window.$('#' + this.navSlotId).practoNav(navbarConfig);
    }
  }

  getNavConfig() {
    const isHomepage = this.props.routeId === 'homepage';
    // TODO Modify this when chat page is ported to consult-ui
    const isChatPage = false;

    var navbarConfig = {
      nav: {
        is_loggedin: false,
        login: {
          url: '',
          logouturl: '/consult/logout',
          callback: () => {
            this.props.initiateLogin();
          },
          intent: 'consult',
          source: 'consult',
        },
        selectedNavLabel: isHomepage || isChatPage ? 'Consult a Doctor' : '', // current active nav
        locale: 'en',
        usermenu: {
          mypaymentsEnabled: true,
        },
        provider_url: getSubdomainUrl('www') + '/consult/doctors',
        showProductTabs: true,
        selectedProductTab: isHomepage || isChatPage ? 'chat' : '',
        googleOneTap: {
          enabled: false,
          googleOneTapClientId: clientConfig.GOOGLE_ONE_TAP_CLIENT_ID,
          onLogin: 'clickLogin', // After successful onetap login, click login button to make user login to consult
        },
      },
    };

    // When user is logged in during page load
    if (this.props.isLoggedIn) {
      const loggedInUserConfig = this.getLoggedInUserConfig();
      navbarConfig.nav = {
        ...navbarConfig.nav,
        ...loggedInUserConfig.nav,
      };
    }

    return navbarConfig;
  }

  isPractoNavLoaded() {
    return window.$ && window.$(this.navSlotId).practoNav;
  }

  getLoggedInUserConfig() {
    return {
      nav: {
        is_loggedin: true,
        user: {
          id: this.props.userId,
          name: this.props.userName,
          number: this.props.userMobile,
          email: this.props.userEmail,
          isdoctor: this.props.isDoctor,
        },
      },
    };
  }
}

Nav.propTypes = {
  initiateLogin: PropTypes.func.isRequired,
  routeId: PropTypes.string,
  isLoggedIn: PropTypes.bool,
  userId: PropTypes.number,
  userName: PropTypes.string,
  userMobile: PropTypes.string,
  userEmail: PropTypes.string,
  isDoctor: PropTypes.bool,
};

export default Nav;
