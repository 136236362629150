import { call, put, takeLatest } from 'redux-saga/effects';

import {
  LOAD_DOCTOR_PROFILE,
  LOAD_DOCTOR_PROFILE_SUCCESS,
  LOAD_DOCTOR_PROFILE_FAILURE,
} from './doctorProfile.js';
import { fetchDoctorProfile } from './doctorProfileApi.js';

export function* loadDoctorProfile(action) {
  const { doctorAccountId } = action.payload;
  try {
    const response = yield call(fetchDoctorProfile, doctorAccountId);

    if (!response) {
      yield put({
        type: LOAD_DOCTOR_PROFILE_FAILURE,
      });
      return;
    }

    yield put({
      type: LOAD_DOCTOR_PROFILE_SUCCESS,
      payload: response,
    });
  } catch (err) {
    yield put({
      type: LOAD_DOCTOR_PROFILE_FAILURE,
    });
  }
}

export default function* doctorProfileSaga() {
  yield takeLatest(LOAD_DOCTOR_PROFILE, loadDoctorProfile);
}
