import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Modal from '../modal/Modal';
import Button from '../button/Button';
import urlConstants from '../../../constants/urlConstants';
import { getSubdomainUrl } from '../../../utilities/urlHelper';
import './paymentRequestForm.scss';

class PaymentRequestForm extends PureComponent {
  constructor() {
    super();
    this.form = React.createRef();
    this.state = { openModal: false };
  }

  componentDidMount() {
    const { inMicroapp, values } = this.props;
    if (inMicroapp) {
      this.initMicroappPayments(values);
    }

    if (this.form && this.form.current) {
      this.form.current.submit();
    }
  }

  componentDidUpdate(prevProps) {
    const { values, inMicroapp } = this.props;
    if (inMicroapp && values.signature !== prevProps.values.signature) {
      this.initMicroappPayments(values);
    }
  }

  initMicroappPayments = values => {
    window.pma
      .initTransaction(values)
      .catch(() => this.setState({ openModal: true }));
  };

  retryMicroappPayment = () => {
    this.setState({ openModal: false });
    this.props.onRetryCtaClick();
  };

  render() {
    const { openModal } = this.state;
    const { values, goBack, inMicroapp } = this.props;
    const paymentsUrl = getSubdomainUrl('payments') + urlConstants.PAYMENTS_URL;

    if (inMicroapp) {
      return (
        openModal && (
          <Modal
            header="Payment Failed"
            description="Any amount deducted will be refunded within 4-7 working days. How would you like to proceed ?"
            onClose={goBack}
          >
            <div className="retry-buttons">
              <Button type="primary" onClick={this.retryMicroappPayment}>
                Retry
              </Button>

              <Button type="primary" className="bordered" onClick={goBack}>
                Cancel
              </Button>
            </div>
          </Modal>
        )
      );
    }

    return (
      <form
        ref={this.form}
        action={paymentsUrl}
        method="post"
        name="make_payment"
        style={{ display: 'none' }}
      >
        <input
          name="firstName"
          required
          type="hidden"
          value={values.firstName}
        />
        <input
          name="userPhone"
          required
          type="hidden"
          value={values.userPhone}
        />
        <input
          name="userEmail"
          required
          type="hidden"
          value={values.userEmail}
        />
        <input name="paytm" required type="hidden" value="true" />
        <input name="surl" required type="hidden" value={values.surl} />
        <input name="furl" required type="hidden" value={values.furl} />
        <input name="curl" required type="hidden" value={values.curl} />
        <input name="details" required type="hidden" value={values.details} />
        <input name="currency" required type="hidden" value={values.currency} />
        <input
          name="suborders"
          required
          type="hidden"
          value={JSON.stringify(values.suborders)}
        />
        <input name="userId" required type="hidden" value={values.userId} />
        <input name="key" required type="hidden" value={values.key} />
        <input
          name="signature"
          required
          type="hidden"
          value={values.signature}
        />
        <input
          name="productTransactionId"
          required
          type="hidden"
          value={values.productTransactionId}
        />
        <input
          name="displayCurrency"
          type="hidden"
          value={values.displayCurrency}
        />
        <input
          name="displayAmount"
          type="hidden"
          value={values.displayAmount}
        />
        <input name="aggregator" type="hidden" value={values.aggregator} />
        <input required name="version" type="hidden" value={values.version} />
      </form>
    );
  }
}

PaymentRequestForm.defaultProps = {
  inMicroapp: false,
  goBack: () => null,
  onRetryCtaClick: () => null,
};

PaymentRequestForm.propTypes = {
  goBack: PropTypes.func,
  inMicroapp: PropTypes.bool,
  onRetryCtaClick: PropTypes.func,
  values: PropTypes.object.isRequired,
};

export default PaymentRequestForm;
