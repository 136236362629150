import React, { useEffect } from 'react';
import Slider from '../../common/Slider/Slider';
import { fetchBannerProps } from '../../../redux/ducks/banner-props/bannerProps.js';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import './bannerProps.scss';

const LoadingCard = () => {
  return (
    <div className="new-consult-value-props--loading">
      <i className="icon-ic_image" />
    </div>
  );
};

const BannerProps = () => {
  const dispatch = useDispatch();

  const { bannerProps, recentChat } = useSelector(
    state => ({
      bannerProps: state.bannerProps,
      recentChat: state.recentChats[0],
    }),
    shallowEqual
  );

  useEffect(() => {
    if (!bannerProps.loading && !bannerProps.urls.length)
      dispatch(fetchBannerProps());
  }, [bannerProps.loading, bannerProps.urls.length, dispatch]);

  if (recentChat) {
    return null;
  }

  if (bannerProps.loading) {
    return <LoadingCard />;
  } else if (!bannerProps.urls.length) {
    return null;
  }
  const slides = bannerProps.urls.map(url => (
    <img alt="practo offers" key={url} width="100%" src={url} />
  ));

  return (
    <Slider
      slides={slides}
      name="new-consult-value-props"
      perView={1}
      enableBullets={true}
    />
  );
};

export default BannerProps;
