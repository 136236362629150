import queryString from 'query-string';
import urlConstants from './../constants/urlConstants.js';

// Composes the subdomain url for a provided subdomain w.r.t a base url
// Example: getSubdomainUrl('nav', 'https://www.practo.com') => 'https://nav.practo.com'
export function getSubdomainUrl(subdomain, baseUrl = window.location.origin) {
  return baseUrl.replace('www', subdomain);
}

export function getTopicPageUrlBySlug(slug) {
  return `/consult/${slug}/t`;
}

export function getSpecialityPageUrlBySlug(slug) {
  return `/consult/${slug}-online`;
}

export function getQuestionPageUrlBySlug(slug) {
  return `/consult/${slug}/q`;
}

export function getChatDetailsUrl(chatId) {
  return `/consult/direct/chat/${chatId}`;
}

export function getChatListUrl() {
  return `/consult/direct/chat`;
}

export function getNoInternationalSupportUrl() {
  return `/consult/direct/no-international-support`;
}

export function getDoctorPingingUrl(transactionId) {
  return `/consult/direct/doctor-pinging/${transactionId}`;
}

export function getDirectConsultUrl(problemAreaId) {
  const query = { id: problemAreaId };
  return `/consult/direct/new_consultation?${queryString.stringify(query)}`;
}

export const getNonAmpCanonicalUrl = url => {
  return url.replace(
    `${urlConstants.WEBSITE_PREFIX}/amp`,
    urlConstants.WEBSITE_PREFIX
  );
};
