import getConsultAuthData from './getConsultAuthData';
import sourceAttribution from '../utilities/attribution.js';
import marketplaceAttribution from '../utilities/marketplaceAttribution.js';
import {
  getCleanedText,
  getServiceId,
  getSubscriptionPlanId,
  getFlowProblemAreaId,
} from './consultHelper';

export const createPaymentPayload = (
  newConsultState,
  paymentsState,
  userState,
  doctorProfileState
) => {
  const consultAuthData = getConsultAuthData();
  const userName = newConsultState.userName || userState.user_name;
  let payload = {
    first_name: userName,
    practo_account_id: consultAuthData.PRACTO_ACCOUNT_ID,
    problem_area_id: getFlowProblemAreaId(newConsultState),
    service_id: getServiceId(newConsultState.serviceDetails),
    subscription_plan_id: getSubscriptionPlanId(newConsultState.serviceDetails),
    text: getCleanedText(newConsultState.selectedSymptom),
    user_email: userState.email,
    user_info: {
      email: userState.email,
      hasEmail: !!userState.email,
      is_relative: false,
      mobile: userState.mobile,
      name: userName,
    },
    user_phone: userState.mobile,
  };

  if (newConsultState.serviceDetails) {
    payload.doctor_account_id = newConsultState.serviceDetails.doctorAccountId;
    payload.question_id = newConsultState.serviceDetails.questionId;
    payload.doctor_subscription_plan_id =
      doctorProfileState.data.doctor_subscription_id;

    // When user has already done a consultation with the doctor
    if (doctorProfileState.data.private_thread_id) {
      payload.private_thread_id = doctorProfileState.data.private_thread_id;
      payload.is_retry = true;
    }
  }

  if (paymentsState.appliedCoupon && paymentsState.couponAmount > 0) {
    payload.coupon_code = paymentsState.appliedCoupon;
  }

  if (paymentsState.preferredLanguageFabricId) {
    payload.preferred_language_fabric_id =
      paymentsState.preferredLanguageFabricId;
  }

  const attribution = sourceAttribution.get().split;
  if (attribution) {
    payload['utm_source'] = attribution.source;
    payload['utm_medium'] = attribution.medium;
    payload['utm_campaign'] = attribution.campaign;
  }

  const joinedMarketplaceAttribution = marketplaceAttribution.get().joined;
  if (joinedMarketplaceAttribution) {
    payload['marketplace_attribution'] = joinedMarketplaceAttribution;
  }

  return payload;
};

export const fetchOrderAmount = (paymentsState, { fee }) => {
  const couponAmount = paymentsState.couponAmount;
  const orderAmount = Math.max(fee - couponAmount, 0);

  return orderAmount;
};

export const calculatePaymentInformation = (state, featureMap, feeSplit) => {
  const currencySymbol = feeSplit.currency_symbol;
  const currencySymbolPrefix = feeSplit.currency_symbol_prefix;
  const fee = feeSplit.fee;
  let totalAmount = feeSplit.fee;
  const couponAmount = Math.min(state.couponAmount, totalAmount);
  totalAmount -= couponAmount;

  const healthCashAmount = Math.min(state.practoWalletBalance, totalAmount);
  totalAmount -= featureMap.show_health_cash ? healthCashAmount : 0;

  const paymentInformation = {
    couponAmount,
    currencySymbol,
    currencySymbolPrefix,
    healthCashAmount,
    fee,
    totalAmount,
  };

  return paymentInformation;
};
