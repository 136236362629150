import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import InlineNav from '../../common/InlineNav';
import { resetNewConsult } from '../../../redux/ducks/new-consult/newConsult.js';
import './newConsultNav.scss';

function NewConsultNav({ title, onBack, onCross }) {
  const dispatch = useDispatch();
  return (
    <nav className="new-consult-nav">
      {onBack ? (
        <i onClick={onBack} className="icon-ic_back new-consult-nav__back" />
      ) : (
        <InlineNav />
      )}
      <h2 className="new-consult-nav__text">{title}</h2>
      {onCross && (
        <i
          onClick={() => {
            dispatch(resetNewConsult());
            onCross();
          }}
          className="icon-ic_cross new-consult-nav__cross"
        />
      )}
    </nav>
  );
}

NewConsultNav.propTypes = {
  onBack: PropTypes.func,
  onCross: PropTypes.func,
  title: PropTypes.string,
};

export default NewConsultNav;
