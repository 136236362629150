export const LOAD_USERS_INIT = 'USERSINIT/LOAD';
export const LOAD_USERS_INIT_SUCCESS = 'USERSINIT/LOAD/SUCCESS';
export const REPLACE_USERS_INIT = 'REPLACE_USERS_INIT';

export const replaceUsersInit = usersInit => ({
  type: REPLACE_USERS_INIT,
  payload: {
    usersInit,
  },
});

const initialState = {};
export default function usersInitReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_USERS_INIT_SUCCESS:
      return {
        ...action.payload,
      };

    case REPLACE_USERS_INIT:
      return {
        ...action.payload.usersInit,
      };

    default:
      return { ...state };
  }
}

export const fetchUsersInit = payload => ({
  type: LOAD_USERS_INIT,
  payload,
});
