import consultRequestCreator from '../../consultRequestCreator';
import urlConstants from '../../../constants/urlConstants';

export function applyCoupon(payload) {
  const params = {
    practo_account_id: payload.practoAccountId,
    problem_area_id: payload.problemAreaId,
    transaction_amount: payload.transactionAmount,
  };

  if (payload.subscriptionPlanId) {
    params.subscription_plan_id = payload.subscriptionPlanId;
  }

  return consultRequestCreator
    .get(`/coupons/${payload.appliedCoupon}/validate`, { params })
    .then(response => response.data);
}

export function fetchPractoWalletBalance(payload) {
  const params = {
    practo_account_id: payload.practoAccountId,
    order_amount: payload.orderAmount,
  };
  return consultRequestCreator
    .get(urlConstants.FETCH_BALANCE, { params })
    .then(response => response.data);
}

export function fetchAvailableLanguages(payload) {
  const params = {
    service_id: payload.serviceId,
    problem_area_id: payload.problemAreaId,
  };

  if (payload.doctorAccountId) {
    params.doctor_account_id = payload.doctorAccountId;
  }

  return consultRequestCreator
    .get(urlConstants.AVAILABLE_CONSULTATION_LANG, { params })
    .then(response => response.data.languages || []);
}

export function fetchUserCoupons() {
  return consultRequestCreator
    .get(urlConstants.USER_COUPONS)
    .then(response => response.data.coupons || []);
}

export function makePaymentRequest(payload) {
  return consultRequestCreator
    .post(urlConstants.PAYMENT_REQUEST, payload)
    .then(response => response.data);
}

export function getPaymentCards(
  problemAreaId,
  subscriptionPlanId,
  serviceId,
  doctorAccountId
) {
  const params = {
    platform: 'web',
    service_id: serviceId,
    problem_area_id: problemAreaId,
    doctor_account_id: doctorAccountId,
    subscription_plan_id: subscriptionPlanId,
  };

  return consultRequestCreator
    .get(urlConstants.PAYMENT_CARDS, { params, headers: { version: 'v2' } })
    .then(response => response.data);
}
