const defaultReactModalStyle = {
  content: {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    background: 'rgba(255, 255, 255, 1)',
    padding: '0',
    border: '0',
    borderRadius: '0',
    overflow: 'hidden',
    width: '100%',
  },
  overlay: {
    // $z-index-modal
    zIndex: '101',
    backgroundColor: 'rgba(19, 19, 25, 0.7)',
  },
};

export default defaultReactModalStyle;
