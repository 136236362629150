import consultRequestCreator from '../../consultRequestCreator';
import urlConstants from '../../../constants/urlConstants';

export function fetchProblemAreas() {
  return consultRequestCreator
    .get(urlConstants.GET_PROBLEM_AREAS, {
      params: { paid: true },
      headers: { version: 'v2' },
    })
    .then(response => response.data);
}

export function predictProblemAreas(text, fingerprintHash) {
  return consultRequestCreator
    .post(
      urlConstants.PREDICT_PROBLEM_AREAS,
      { text },
      { headers: { 'x-device-id': fingerprintHash } }
    )
    .then(response => response.data);
}
