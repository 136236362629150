export const FETCH_ONLINE_DOCTORS = 'ONLINE_DOCTORS';
export const FETCH_ONLINE_DOCTORS_SUCCESS = 'ONLINE_DOCTORS/SUCCESS';
export const FETCH_ONLINE_DOCTORS_FAILURE = 'ONLINE_DOCTORS/FAILURE';

export const initialState = {
  additionalDoctorsCount: 0,
  description: '',
  showOnline: false,
  doctors: [],
  heading: '',
};

export default function onlineDoctorsReducer(
  state = initialState,
  action = {}
) {
  switch (action.type) {
    case FETCH_ONLINE_DOCTORS_SUCCESS:
      return {
        ...state,
        additionalDoctorsCount: action.payload.additional_doctor_count,
        description: action.payload.description,
        showOnline: action.payload.show_online,
        doctors: action.payload.doctors,
        heading: action.payload.heading,
      };

    default:
      return state;
  }
}

export function fetchOnlineDoctors(problemAreaId) {
  return {
    type: FETCH_ONLINE_DOCTORS,
    payload: {
      problemAreaId,
    },
  };
}
