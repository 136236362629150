import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from '../../common/button/Button.js';
import './sendAppLink.scss';

class SendAppLink extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mobile: '',
      mobileHasError: false,
      message: '',
    };

    this.handleSendLinkClick = this.handleSendLinkClick.bind(this);
    this.mobileChangeHandler = this.mobileChangeHandler.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.sendAppLinkStatus !== this.props.sendAppLinkStatus &&
      this.props.sendAppLinkStatus === 'success'
    ) {
      this.setState({
        mobile: '',
        message: 'Verification link sent',
        mobileHasError: false,
      });
    }
  }

  render() {
    const { mobile, mobileHasError, message } = this.state;

    return (
      <div className="app-link-wrapper">
        <h4 className="heading">Get the link to download the app</h4>
        <form onSubmit={this.handleSendLinkClick}>
          <div
            className={classnames('input-container', { error: mobileHasError })}
          >
            <div className="country-code">+91</div>
            <input
              type="tel"
              value={mobile}
              placeholder="Enter phone number"
              onChange={this.mobileChangeHandler}
            />
          </div>
          <Button className="send-app-link-button" type="primary">
            Send app link
          </Button>
        </form>
        {message && <p className="success-message">{message}</p>}
      </div>
    );
  }

  mobileValidator(mobile = '') {
    let regx = /^\d{10}$/;

    let isMobileValid = regx.test(mobile);

    return {
      isValid: isMobileValid,
      message: '',
    };
  }

  handleSendLinkClick(event) {
    event.preventDefault();

    let { mobile } = this.state;
    let validationState = this.mobileValidator(mobile);
    let newState = {};

    if (validationState.isValid) {
      this.props.sendAppLink(mobile);
    } else {
      newState.mobileHasError = true;
    }

    this.setState(newState);
  }

  mobileChangeHandler(ev) {
    let mobile = ev.target.value;

    this.setState({ mobile });
  }
}

SendAppLink.propTypes = {
  sendAppLinkStatus: PropTypes.string,
  sendAppLink: PropTypes.func.isRequired,
};

export default SendAppLink;
