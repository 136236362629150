import React from 'react';
import PropTypes from 'prop-types';
import './onlineDoctorsCard.scss';

const OnlineDoctorsCard = props => (
  <article className="instant-doctors-card">
    <p>
      {props.heading} {props.showOnline && <span className="pulse" />}
    </p>
    <div className="doctor-images">
      {props.doctors.map((picUrl, index) => (
        <span className="each-image" key={index}>
          <img alt="doctor" src={picUrl} />
        </span>
      ))}
      <span className="each-image count">+{props.additionalDoctorsCount}</span>
    </div>
    <p>{props.description}</p>
  </article>
);

OnlineDoctorsCard.propTypes = {
  heading: PropTypes.string,
  doctors: PropTypes.array,
  additionalDoctorsCount: PropTypes.number,
  description: PropTypes.string,
  showOnline: PropTypes.bool,
};

export default OnlineDoctorsCard;
