import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { getSubdomainUrl } from '../../../utilities/urlHelper.js';
import Loader from '../loader/Loader.js';
import './loginModal.scss';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import DeviceContainer from '../../common/DeviceContainer.js';
import { isLoggedInAccounts } from '../../../redux/ducks/login-modal/loginModalSaga.js';

class LoginModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      height: 278,
      isLoading: true,
    };
    this.ref = React.createRef();
  }

  componentDidMount() {
    this.enableListener();
    disableBodyScroll(this.ref.current);
  }

  componentWillUnmount() {
    this.disableListener();
    enableBodyScroll(this.ref.current);
  }

  enableListener = () => {
    window.addEventListener('message', this.iframeMessageEventListener);
  };

  disableListener = () => {
    window.removeEventListener('message', this.iframeMessageEventListener);
  };

  iframeMessageEventListener = event => {
    if (event.origin === getSubdomainUrl('accounts')) {
      switch (event.data.type) {
        case 'close-modal':
          this.props.closeModal();
          break;

        case 'change-dimension':
          this.setState({
            height: event.data.payload.height,
          });
          break;
      }
    }
  };

  getIframeUrl = () => {
    const { type, name, mobile } = this.props;
    const urls = {
      BASE: getSubdomainUrl('accounts'),
      LOGIN:
        '/login?intent=consult&view_type=modal&next=consult&source=consult',
      REGISTER:
        '/new_patient_signup?intent=consult&view_type=modal&next=consult&source=consult',
      OTP:
        '/login_signup_otp?view_type=modal&intent=consult&source=consult&hide_header=true',
    };

    switch (type) {
      case 'LOGIN':
        return urls.BASE + urls.LOGIN;

      case 'REGISTER':
        return urls.BASE + urls.REGISTER;

      case 'OTP':
        return (
          urls.BASE +
          urls.OTP +
          '&mobile=' +
          encodeURIComponent(mobile) +
          '&name=' +
          name
        );
    }
  };

  onLoadHandler = () => {
    if (isLoggedInAccounts()) {
      // After user is logged into accounts
      this.props.onDone();
    } else {
      // First load
      this.setState({
        isLoading: false,
      });
    }
  };

  render() {
    const iframeClassName = classnames('iframe', {
      'full-screen': this.props.type !== 'OTP',
    });

    return (
      <div className="iframe-overlay">
        <DeviceContainer>
          {device => (
            <div
              className={iframeClassName}
              style={{
                height:
                  !device.isDesktop && this.props.type !== 'OTP'
                    ? '100%'
                    : this.state.height,
              }}
              ref={this.ref}
            >
              <iframe
                onLoad={this.onLoadHandler}
                src={this.getIframeUrl()}
                height="100%"
                id="login-iframe-form"
                frameBorder="0"
                // No scrolling attribute on phones because it prevent scrolling in andriod phones
                // scrolling="no" attribute on web to hide scrollbars on the iframe for web
                scrolling={device.isDesktop ? 'no' : null}
              />
              {this.state.isLoading && <Loader type="overlay" />}
            </div>
          )}
        </DeviceContainer>
      </div>
    );
  }
}

LoginModal.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  mobile: PropTypes.string,
  onDone: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default LoginModal;
