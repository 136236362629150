import consultRequestCreator from '../../consultRequestCreator';
import urlConstants from '../../../constants/urlConstants.js';

export function fetchSelfProfile() {
  return consultRequestCreator
    .get(urlConstants.USER_INFO, {
      params: {
        self: true,
      },
    })
    .then(response => response.data['user_profiles'].pop() || {});
}
