import { all, fork, put, select, takeEvery } from 'redux-saga/effects';

import sendPelEvent from '../../../utilities/analytics/sendPelEvent.js';
import { selectUserState } from '../selectors';
import {
  createActorData,
  createActionContext,
} from '../../../utilities/analytics/PELUtils.js';

export const SEND_PEL_EVENT = 'ANALYTICS/SEND_PEL_EVENT';
export const SEND_PEL_EVENT_FAILED = 'ANALYTICS/SEND_PEL_EVENT_FAILED';

// Example:
// sendPELEventAction('Consult QnA', 'Viewed', { problemAreaId: 2 })
export function sendPELEventAction(object, action, objectContext) {
  return {
    type: SEND_PEL_EVENT,
    payload: {
      object,
      action,
      objectContext,
    },
  };
}

export function sendPELEventActionFailed(err) {
  return {
    type: SEND_PEL_EVENT_FAILED,
    err,
  };
}

export function* sendPELEventHandler({ payload }) {
  try {
    const { object, action, objectContext } = payload;

    const userState = yield select(selectUserState);
    const { actor, actorContext } = createActorData(userState);
    const actionContext = createActionContext();

    yield fork(sendPelEvent, {
      object,
      action,
      actionContext,
      objectContext,
      actor,
      actorContext,
    });
  } catch (err) {
    yield put({
      type: SEND_PEL_EVENT_FAILED,
      err,
    });
  }
}

export default function* defaultSaga() {
  yield all([takeEvery(SEND_PEL_EVENT, sendPELEventHandler)]);
}
