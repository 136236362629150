module.exports = {
  "HOTJAR_ID": "@@client_HOTJAR_ID@@",
  "CLIENT_SENTRY_DSN": "@@client_CLIENT_SENTRY_DSN@@",
  "PEL_URL": "@@client_PEL_URL@@",
  "GOOGLE_ONE_TAP_CLIENT_ID": "@@client_GOOGLE_ONE_TAP_CLIENT_ID@@",
  "CONSUMER_NAV_VERSION": "@@client_CONSUMER_NAV_VERSION@@",
  "GET_SITE_CONTROL_ID": "@@client_GET_SITE_CONTROL_ID@@",
  "GA_ADWORD_ID": "@@client_GA_ADWORD_ID@@",
  "GA_ADWORD_CONVERSION_VIEW_ID": "@@client_GA_ADWORD_CONVERSION_VIEW_ID@@",
  "CONSULT_GA_TRACKING_ID": "@@client_CONSULT_GA_TRACKING_ID@@",
  "FABRIC_GA_TRACKING_ID": "@@client_FABRIC_GA_TRACKING_ID@@",
  "CONSULT_GA_AMP_TRACKING_ID": "@@client_CONSULT_GA_AMP_TRACKING_ID@@",
  "FABRIC_GA_AMP_TRACKING_ID": "@@client_FABRIC_GA_AMP_TRACKING_ID@@",
  "CORONA_SCRIPT_URL": "@@client_CORONA_SCRIPT_URL@@",
  "BOTTOMSTICKY_SCRIPT_URL": "@@client_BOTTOMSTICKY_SCRIPT_URL@@"
}