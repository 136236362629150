import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  fetchProblemAreas,
  getCoronaProblemArea,
} from '../../../redux/ducks/problem-areas/problemAreas.js';
import { filterProblemAreas } from '../../../redux/ducks/problem-areas/problemAreas';
import { selectProblemArea } from '../../../redux/ducks/new-consult/newConsult.js';
import SpecialityCards from './SpecialityCards';

function SpecialityCardsContainer({
  problemAreaIds,
  enableForm,
  limit,
  onClick,
  disableApiFetch,
  onChange,
}) {
  const dispatch = useDispatch();
  const {
    coronaProblemArea,
    problemAreas,
    selectedProblemAreaId,
  } = useSelector(
    state => ({
      coronaProblemArea: enableForm ? null : getCoronaProblemArea(state),
      problemAreas: filterProblemAreas(
        state.problemAreas.problemAreasById,
        problemAreaIds || state.problemAreas.problemAreaIds
      ),
      selectedProblemAreaId: state.newConsult.selectedProblemAreaId,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (!disableApiFetch && !problemAreas.length) {
      dispatch(fetchProblemAreas());
    }
  }, [problemAreas.length, disableApiFetch, dispatch]);

  if (problemAreas.length === 0) {
    return null;
  }

  const dispatchSelectProblemArea = problemArea =>
    dispatch(selectProblemArea(problemArea.id));
  const onSpecialityCardClick = problemArea => {
    dispatchSelectProblemArea(problemArea);
    onClick && onClick(problemArea);
  };
  const onSpecialityCardChange = problemArea => {
    dispatchSelectProblemArea(problemArea);
    onChange && onChange();
  };

  if (coronaProblemArea) {
    problemAreas.unshift(coronaProblemArea);
  }

  return (
    <SpecialityCards
      selectedId={selectedProblemAreaId}
      problemAreas={problemAreas}
      enableForm={enableForm}
      onChange={enableForm ? onSpecialityCardChange : null}
      onClick={enableForm ? null : onSpecialityCardClick}
      limit={limit}
    />
  );
}

SpecialityCardsContainer.propTypes = {
  limit: PropTypes.number,
  enableForm: PropTypes.bool,
  problemAreaIds: PropTypes.arrayOf(PropTypes.number),
  onClick: PropTypes.func,
  disableApiFetch: PropTypes.bool,
  onChange: PropTypes.func,
};

export default SpecialityCardsContainer;
