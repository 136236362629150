import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  FETCH_ONLINE_DOCTORS,
  FETCH_ONLINE_DOCTORS_SUCCESS,
  FETCH_ONLINE_DOCTORS_FAILURE,
} from './onlineDoctors.js';
import * as onlineDoctorsApi from './onlineDoctorsApi.js';

export function* fetchOnlineDoctors(action) {
  try {
    const params = {
      problem_area_id: action.payload.problemAreaId,
    };

    const payload = yield call(onlineDoctorsApi.fetchOnlineDoctors, params);
    yield put({
      type: FETCH_ONLINE_DOCTORS_SUCCESS,
      payload,
    });
  } catch (err) {
    yield put({
      type: FETCH_ONLINE_DOCTORS_FAILURE,
    });
  }
}

export default function* defaultSaga() {
  yield all([takeLatest(FETCH_ONLINE_DOCTORS, fetchOnlineDoctors)]);
}
