import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import SymptomsCardSection from './SymptomsCardSection.js';
import NewConsultHeader from './new-consult-header/NewConsultHeader.js';
import { loadSymptoms } from '../../redux/ducks/symptoms/symptoms.js';
import { selectSymptom } from '../../redux/ducks/new-consult/newConsult.js';

export default function SymptomsSection({
  redirectToPayment,
  trackProblemInteracted,
}) {
  const dispatch = useDispatch();
  const { symptoms } = useSelector(
    state => ({
      symptoms: state.symptoms,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (!symptoms.length) {
      dispatch(loadSymptoms());
    }
  }, [symptoms.length, dispatch]);

  if (!symptoms.length) {
    return null;
  }

  const onSelectSymptom = ({ id, name, image_url }) => {
    trackProblemInteracted({
      'Interaction Type': 'Symptom',
      'Symptom Id': id,
    });
    dispatch(selectSymptom(id, name, image_url));
    redirectToPayment();
  };

  return (
    <section className="spec-section">
      <NewConsultHeader
        title="Know Your Symptom ?"
        subtitle="Select from top symptoms"
      />
      <SymptomsCardSection
        symptoms={symptoms}
        selectSymptom={onSelectSymptom}
      />
    </section>
  );
}

SymptomsSection.propTypes = {
  redirectToPayment: PropTypes.func.isRequired,
  trackProblemInteracted: PropTypes.func.isRequired,
};
