export const INITIATE_OTP_LOGIN = 'INITIATE_OTP_LOGIN';
export const SHOW_OTP_LOGIN = 'SHOW_OTP_LOGIN';
export const SHOW_CONSULT_LOGIN = 'SHOW_CONSULT_LOGIN';
export const INITIATE_LOGIN = 'INITIATE_LOGIN';
export const SHOW_LOGIN = 'SHOW_LOGIN';
export const INITIATE_REGISTER = 'INITIATE_REGISTER';
export const SHOW_REGISTER = 'SHOW_REGISTER';
export const CLOSE_LOGIN_MODAL = 'CLOSE_LOGIN_MODAL';
export const NOTIFY_LOGIN_SUCCESSFUL = 'NOTIFY_LOGIN_SUCCESSFUL';

export const initiateOtpLogin = payload => ({
  type: INITIATE_OTP_LOGIN,
  payload,
});

export const initiateLogin = () => ({
  type: INITIATE_LOGIN,
});

export const initiateRegister = () => ({
  type: INITIATE_REGISTER,
});

export const notifyLoginSuccessful = () => ({
  type: NOTIFY_LOGIN_SUCCESSFUL,
});

export const showConsultLogin = (silent = false) => ({
  type: SHOW_CONSULT_LOGIN,
  payload: {
    silent,
  },
});

export const closeLoginModal = () => ({ type: CLOSE_LOGIN_MODAL });

let initialState = {
  isVisible: false,
  type: '',
  subtype: '',
  name: '',
  mobile: '',
  silent: false,
};

export default function loginModalReducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_OTP_LOGIN: {
      const { name, mobile } = action.payload;

      return {
        ...initialState,
        isVisible: true,
        type: 'ACCOUNTS',
        subtype: 'OTP',
        name,
        mobile,
      };
    }

    case SHOW_LOGIN: {
      return {
        ...initialState,
        isVisible: true,
        type: 'ACCOUNTS',
        subtype: 'LOGIN',
      };
    }

    case SHOW_REGISTER: {
      return {
        ...initialState,
        isVisible: true,
        type: 'ACCOUNTS',
        subtype: 'REGISTER',
      };
    }

    case SHOW_CONSULT_LOGIN: {
      return {
        ...initialState,
        isVisible: true,
        type: 'CONSULT',
        silent: action.payload.silent,
      };
    }

    case CLOSE_LOGIN_MODAL:
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
