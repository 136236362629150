import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  SectionHeading,
  SectionWrapper,
} from '../../common/section/Section.js';
import './offers.scss';
import HorizontalCardsList from '../horizontal-cards-list/HorizontalCardsList.js';
import OfferCard from './OfferCard.js';
import DeviceContainer from '../../common/DeviceContainer.js';
import TrackOnceInViewContainer from '../../common/TrackOnceInViewContainer.js';
import { PEL_OBJECTS, PEL_ACTIONS } from '../../../constants/PELConstants.js';
import { sendPELEventAction } from '../../../redux/ducks/analytics/analyticsSaga.js';

class OfferCardsContainer extends React.Component {
  render() {
    const offersData = this.getOffersData();
    const cards = offersData.map((props, index) => {
      const objectContext = {
        'Card Title': props.heading,
        'Card Position': index + 1,
        ...this.props.getSectionObjectContext(this.props.sectionType),
      };
      const viewTrackingParams = {
        object: PEL_OBJECTS.consultHomeCard,
        action: PEL_ACTIONS.viewed,
        objectContext,
      };

      return (
        <TrackOnceInViewContainer key={index} {...viewTrackingParams}>
          <OfferCard
            {...props}
            onClick={() =>
              this.props.sendPELEventAction(
                PEL_OBJECTS.consultHomeCard,
                PEL_ACTIONS.interacted,
                objectContext
              )
            }
          />
        </TrackOnceInViewContainer>
      );
    });

    return (
      <SectionWrapper type="horizontal-scroll">
        <SectionHeading type="horizontal-scroll">Offers</SectionHeading>
        <DeviceContainer>
          {device => (
            <HorizontalCardsList
              type={device.isDesktop ? 'slider' : null}
              sliderConfig={{ name: 'offers-slider', perView: 2 }}
            >
              {cards}
            </HorizontalCardsList>
          )}
        </DeviceContainer>
      </SectionWrapper>
    );
  }

  getOffersData() {
    return [
      {
        badge: 'Offer',
        heading: 'Download the App & get ₹200 HealthCash',
        cta: 'Download App',
        imageName: 'offer-app-v1.png',
        color: 'green',
        url:
          'https://practo.onelink.me/1025359428?pid=mweb&c=consult_homepage_v2_offers',
      },
      // {
      //   badge: 'PRACTO PLUS',
      //   heading: 'Free online consultations starting at ₹799/month',
      //   cta: 'Get Membership',
      //   imageName: 'offer-plus-plan-v2.png',
      //   color: 'pink',
      //   url: '/plus?utm_medium=home_offer&utm_source=CONSULT',
      // },
      {
        badge: 'Offer',
        heading: 'Consult with specialists at just ₹199',
        cta: 'Consult Now',
        imageName: 'offer-specialist-v1.png',
        color: 'yellow',
        url: '/consult/direct/new_consultation',
      },
    ];
  }
}

OfferCardsContainer.propTypes = {
  sectionType: PropTypes.string,
  sendPELEventAction: PropTypes.func,
  getSectionObjectContext: PropTypes.func,
};

const mapDispatchToProps = dispatch => ({
  sendPELEventAction: (...args) => dispatch(sendPELEventAction(...args)),
});

export default connect(null, mapDispatchToProps)(OfferCardsContainer);
