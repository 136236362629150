import React, { memo } from 'react';
import classnames from 'classnames';
import './radio-card.scss';
import PropTypes from 'prop-types';

const RadioCard = ({
  checked,
  onClick,
  title,
  subtitle,
  imageUrl,
  enableForm,
  onChange,
  alignCenter,
}) => {
  const cardTextClass = classnames('radio-card__text', {
    'radio-card__text--center': alignCenter,
  });

  return (
    <div className="radio-card-wrapper">
      {enableForm && (
        <input
          checked={checked}
          name={title}
          type="radio"
          id={title}
          onChange={onChange}
          className="hidden"
        />
      )}
      <label
        htmlFor={title}
        className="radio-card"
        onClick={onClick ? onClick : null}
      >
        <img className="radio-card__image" src={imageUrl} alt={title} />
        <div className={cardTextClass}>
          <div className="radio-card__text__title">{title}</div>
          <div className="radio-card__subtitle">
            {subtitle && <span>{subtitle}</span>}
          </div>
        </div>
        {enableForm && (
          <i className="icon-ic_done_solid_system radio-card__checkmark" />
        )}
      </label>
    </div>
  );
};

RadioCard.propTypes = {
  checked: PropTypes.bool,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  imageUrl: PropTypes.string,
  onClick: PropTypes.func,
  alignCenter: PropTypes.bool,

  enableForm: PropTypes.bool,
  onChange: PropTypes.func,
};

RadioCard.defaultProps = {
  subtitle: null,
};

export default memo(RadioCard);
