import React from 'react';
import PropTypes from 'prop-types';
import './cobrandBanner.scss';
import LazyImg from '../LazyImg';
import { getImageUrl } from '../../../utilities/assetsHelper';
import { partnerConfig } from '../../../constants/partnerConfig';

function CobrandBanner({ partnerId }) {
  if (!(partnerId in partnerConfig)) {
    return null;
  }

  const {
    title = '',
    partnerLogo,
    partnerLogoAlt = '',
  } = partnerConfig[partnerId];

  return (
    <React.Fragment>
      <div className="cobrand-banner">
        <div>{title}</div>
        <div className="cobrand-banner__logo-wrapper">
          <LazyImg
            src={getImageUrl(partnerLogo)}
            height="16"
            alt={partnerLogoAlt}
          />
          <LazyImg
            src={getImageUrl('practo-logo-v2.svg')}
            height="12"
            alt="practo"
          />
        </div>
      </div>
    </React.Fragment>
  );
}

CobrandBanner.propTypes = {
  partnerId: PropTypes.string.isRequired,
};

export default CobrandBanner;
