import React from 'react';
import PropTypes from 'prop-types';
import { escapeStringForRegExp } from '../../../utilities/utilities.js';

const SearchSuggestion = ({ suggestion, highlightWord, onClick }) => {
  const caseInsensitiveFirstWordMatch = new RegExp(
    escapeStringForRegExp(highlightWord),
    'i'
  );
  const highlightedSuggestion = suggestion.name.replace(
    caseInsensitiveFirstWordMatch,
    match => `<span class="search-suggestions__highlight">${match}</span>`
  );

  return (
    <div onClick={onClick} className="search-suggestions__each">
      <span dangerouslySetInnerHTML={{ __html: highlightedSuggestion }} />
      <span className="search-suggestions__type">
        {suggestion.display_text}
      </span>
    </div>
  );
};

SearchSuggestion.propTypes = {
  suggestion: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
    display_text: PropTypes.string,
  }).isRequired,
  highlightWord: PropTypes.string,
  onClick: PropTypes.func,
};

export default SearchSuggestion;
