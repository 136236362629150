import { call, put, takeLatest } from 'redux-saga/effects';
import { postAppLink } from './appLinkApi.js';

import {
  SEND_APP_LINK,
  SEND_APP_LINK_SUCCESS,
  SEND_APP_LINK_ERROR,
} from './appLink.js';

export function* sendAppLink(action) {
  try {
    const { mobileNumber } = action.payload;
    yield call(postAppLink, mobileNumber);
    yield put({
      type: SEND_APP_LINK_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: SEND_APP_LINK_ERROR,
    });
  }
}

export default function* appLinkSaga() {
  yield takeLatest(SEND_APP_LINK, sendAppLink);
}
