export const thirdPartyApp = Object.freeze({
  gPay: 'googlepay',
  phonePe: 'phonepe',
  paytm: 'paytmWallet',
});

export const inThirdPartyApp = ua => {
  if (ua.includes('GPay-Microapps')) return thirdPartyApp.gPay;
  if (ua.includes('AppContainer')) return thirdPartyApp.paytm;
  if (ua.includes('phonepe-webview')) return thirdPartyApp.phonePe;

  // default value is falsy empty string
  return '';
};

export const getProfileToken = () => {
  if (typeof window === 'undefined') return ''; // don't look for it on node server
  return localStorage.getItem('profile_token');
};

export const getUserProfile = () => {
  if (typeof window === 'undefined') return ''; // don't look for it on node server
  return { ...JSON.parse(sessionStorage.getItem('user_profile')) };
};

export const getAppSource = () => {
  if (typeof window === 'undefined') return ''; // don't look for it on node server
  if (typeof window.pma === 'undefined') return ''; // don't look if practo microapp is not defined
  return window.pma.source;
};
