// Slug to content mapping for speciality pages
export const defaultSpecialityConstants = {
  topDoctors: {
    heading: 'Consult with verified, top doctors',
    subtext:
      'Here are some doctors from thousands of doctors in the Practo network',
  },
};
// Slug to content mapping for speciality pages
export const specialityLandingConstants = {
  'ask-a-psychiatrist': {
    title:
      'Ask A Psychiatrist Online And Get Answers 24X7 | Ask A Psychologist Free | Practo',
    meta:
      'Ask a Psychiatrist / Psycholgist online and get your health queries answered for free. Now Consult with 2500+ Medical Health Counselors online only on Practo',
    heading: 'Take the first step towards a happier you!',
    subHeading: 'Consult with verified psychiatrists now',
    cta: 'Consult with a psychiatrist',
    problemAreaId: 2,
    howItWorks: {
      heading: 'How does online doctor consultation work?',
      paragraph: 'Connect with a doctor in 3 steps. Its that simple!',
      steps: [
        {
          img: 'chat-now.jpg',
          icon: '',
          heading: "Click on 'Consult with a psychiatrist'",
          description:
            'Start by clicking on the ‘Consult with a psychiatrist’ button above',
        },
        {
          img: 'complete-payment.jpg',
          icon: '',
          heading: 'Complete payment',
          description:
            'Pay and get connected with top doctors in your preferred specialty',
        },
        {
          img: 'doctor-chat.jpg',
          icon: '',
          heading: 'Start your doctor consultation',
          description:
            'Connect with a top doctor in 60 seconds for consultation, audio or video call.',
        },
      ],
    },
    concern: {
      heading: 'Concerns you can consult about',
      description: 'These are the most talked about concerns with psychiatrist',
      topics: [
        {
          name: 'How to improve concentration',
          slug: 'how-to-improve-concentration',
        },
        { name: 'Symptoms of depression', slug: 'symptoms-of-depression' },
        {
          name: 'How to come out of depression',
          slug: 'how-to-come-out-of-depression',
        },
        {
          name: 'How to gain confidence',
          slug: 'how-to-come-out-of-depression',
        },
        {
          name: 'Sleep disorder treatment',
          slug: 'sleep-disorder-treatment',
        },
        {
          name: 'Symptoms of attention deficit hyperactivity disorder',
          slug: 'symptoms-of-attention-deficit-hyperactivity-disorder',
        },
        {
          name: 'Symptoms of panic attack',
          slug: 'symptoms-of-panic-attack',
        },
        { name: 'How to induce sleeping', slug: 'how-to-induce-sleeping' },
        {
          name: 'What to do after retirement',
          slug: 'what-to-do-after-retirement',
        },
        {
          name: 'How to reduce stress in life',
          slug: 'how-to-reduce-stress-in-life',
        },
        {
          name: 'Short term memory loss treatment',
          slug: 'short-term-memory-loss-treatment',
        },
        { name: 'How to stop addiction', slug: 'how-to-stop-addiction' },
        {
          name: 'Symptoms of learning disability',
          slug: 'symptoms-of-learning-disability',
        },
        {
          name: 'What causes anxiety attacks',
          slug: 'what-causes-anxiety-attacks',
        },
        {
          name: 'Mental health diagnostic test',
          slug: 'mental-health-diagnostic-test',
        },
        { name: 'Short temper disorder', slug: 'short-temper-disorder' },
        {
          name: 'Symptoms of autism spectrum disorders',
          slug: 'symptoms-of-autism-spectrum-disorders',
        },
        {
          name: 'Symptoms of post traumatic stress disorder',
          slug: 'symptoms-of-post-traumatic-stress-disorder',
        },
      ],
    },
    seoTags:
      'Psychiatrists and psychologists specialize in the treatment and management of conditions affecting the brain, emotions, feelings and thoughts. Some of the common types of mental health disorders include bipolar disorder, depression, anxiety, schizophrenia, obsessive compulsive disorder (OCD}, personality disorders, anger, panic attacks, borderline personality disorder (BPD}, post-traumatic stress disorder (PTSD}, sleeping problems, paranoia, psychosis and drug addictions. Out of these depression is the most common mental disorder and affects more people than other. Young adults have been known to be at a greater risk of depression and some of the common symptoms of depression can include extreme feelings of fatigue, hopelessness, irritability, pain and sadness. Depression can also cause loss of pleasure in life and suicidal thoughts. Successful treatment of mental health disorders often requires access to mental health care professionals at the right time. Consult with verified psychiatrists and psychologists online right now only on Practo.',
  },
  'ask-a-dermatologist': {
    title:
      'Ask A Dermatologist Online And Get Answers 24X7 | Ask A skin specialist Free | Practo',
    meta:
      'Ask a Dermatologist / Skin Specialist online and get your health queries answered for free. Now Consult with 2500+ Dermatologists online only on Practo',
    heading: 'Have Skin and Hair problems?',
    subHeading: 'Consult with a verified skin specialist now!',
    cta: 'Consult with a dermatologist',
    problemAreaId: 5,
    howItWorks: {
      heading: 'How does online doctor consultation work?',
      paragraph: 'Connect with a doctor in 3 steps. Its that simple!',
      steps: [
        {
          img: 'chat-now.jpg',
          icon: '',
          heading: "Click on 'Consult with a dermatologist'",
          description:
            'Start by clicking on the ‘Consult with a dermatologist’ button above',
        },
        {
          img: 'complete-payment.jpg',
          icon: '',
          heading: 'Complete payment',
          description:
            'Pay and get connected with top doctors in your preferred specialty',
        },
        {
          img: 'doctor-chat.jpg',
          icon: '',
          heading: 'Start your doctor consultation',
          description:
            'Connect with a top doctor in 60 seconds for consultation, audio or video call.',
        },
      ],
    },
    concern: {
      heading: 'Concerns you can consult about',
      description:
        'These are the most talked about concerns with dermatologist',
      topics: [
        {
          name: 'How to remove dark circles',
          slug: 'how-to-remove-dark-circles',
        },
        { name: 'How to remove dandruff', slug: 'how-to-remove-dandruff' },
        { name: 'How to reduce hair fall', slug: 'how-to-reduce-hair-fall' },
        {
          name: 'How to prevent hair fall',
          slug: 'how-to-prevent-hair-fall',
        },
        {
          name: 'Hair removal cream for men',
          slug: 'hair-removal-cream-for-men',
        },
        { name: 'Symptoms of chicken pox', slug: 'symptoms-of-chicken-pox' },
        {
          name: 'How to remove dark spots',
          slug: 'how-to-remove-dark-spots',
        },
        { name: 'Keratin hair treatment', slug: 'keratin-hair-treatment' },
        { name: 'Skin whitening creams', slug: 'skin-whitening-creams' },
        { name: 'How to prevent pimples', slug: 'how-to-prevent-pimples' },
        { name: 'White patches on face', slug: 'white-patches-on-face' },
        { name: 'Hair growth treatment', slug: 'hair-growth-treatment' },
        { name: 'How to remove skin tags', slug: 'how-to-remove-skin-tags' },
        {
          name: 'How to remove pigmentation',
          slug: 'how-to-remove-pigmentation',
        },
        { name: 'Hair regrowth for men', slug: 'hair-regrowth-for-men' },
        {
          name: 'How to get rid of dry skin',
          slug: 'how-to-get-rid-of-dry-skin',
        },
        { name: 'How to clear dandruff', slug: 'how-to-clear-dandruff' },
        { name: 'How to get rid of acne', slug: 'how-to-get-rid-of-acne' },
      ],
    },
    seoTags:
      'Dermatology is the area of medicine that deals with both the medical and surgical treatment of conditions affecting the skin, hair and nails. Dermatologists are specialists trained in the field of dermatology. Some of the conditions treated by dermatologists include acne, psoriasis, eczema, warts, vitiligo, skin cancer, fungal infections, nail problems, and hair disorders such as alopecia, hair loss and dandruff. Dermatologists may also perform procedures such as biopsies, cosmetic injections, chemical peels, laser surgery, radiation therapy, hair removal, hair restoration, allergy testing, excision of lesions etc. Apart from abnormal conditions, dermatologists may also be consulted for cosmetic reasons as well. Depending on their specialization, dermatologists may offer a range of services in the areas of medical dermatology, surgical dermatology, paediatric dermatology, cosmetic dermatology, dermatoimmunology, dermatopathology and/or photomedicine. Consult with some of the top skin specialists/dermatologists on Practo.',
  },
  'ask-a-sexologist': {
    title: 'Ask Sexologist Online & get sexual problems resolved | Practo',
    meta:
      'Get your men’s sexual health issues answered online by the best sexologist. Consult verified Sexologist doctors online only on Practo',
    heading: 'Consult with a Sexologist',
    subHeading:
      'Call + consult with qualified doctors online about sexual issues. Get expert opinion on HIV, sperm count, erectile issues & more in a 1-on-1 secure private consultation.',
    cta: 'Consult with a sexologist',
    problemAreaId: 14,
    howItWorks: {
      heading: 'How does online doctor consultation work?',
      paragraph: 'Connect with a doctor in 3 steps. Its that simple!',
      steps: [
        {
          img: 'chat-now.jpg',
          icon: '',
          heading: "Click on 'Consult with a sexologist'",
          description:
            'Start by clicking on the ‘Consult with a sexologist’ button above',
        },
        {
          img: 'complete-payment.jpg',
          icon: '',
          heading: 'Complete payment',
          description:
            'Pay and get connected with top doctors in your preferred specialty',
        },
        {
          img: 'doctor-chat.jpg',
          icon: '',
          heading: 'Start your doctor consultation',
          description:
            'Connect with a top doctor in 60 seconds for consultation, audio or video call.',
        },
      ],
    },
    concern: {
      heading: 'Concerns you can consult about',
      description: 'These are the most talked about concerns with sexologist',
      topics: [
        {
          name: 'Daily sex is good or bad for health',
          slug: 'daily-sex-is-good-or-bad-for-health',
        },
        {
          name: 'How to control quick sperm release',
          slug: 'how-to-control-quick-sperm-release',
        },
        {
          name: 'How to avoid sperm leak during sleep',
          slug: 'how-to-avoid-sperm-leak-during-sleep',
        },
        { name: 'Vigora 100 side effects', slug: 'vigora-100-side-effects' },
        {
          name: 'How to reduce fsh levels in men',
          slug: 'how-to-reduce-fsh-levels-in-men',
        },
        {
          name: 'Epithelial cells in semen analysis',
          slug: 'epithelial-cells-in-semen-analysis',
        },
        {
          name: 'Sex after laparoscopic hernia surgery',
          slug: 'sex-after-laparoscopic-hernia-surgery',
        },
        {
          name: 'Sex after abdominal myomectomy',
          slug: 'sex-after-abdominal-myomectomy',
        },
        {
          name: 'How to increase the time duration of sex',
          slug: 'how-to-increase-the-time-duration-of-sex',
        },
        { name: 'How to control sex mood', slug: 'how-to-control-sex-mood' },
        {
          name: 'Brown discharge after unprotected sex',
          slug: 'brown-discharge-after-unprotected-sex',
        },
        {
          name: 'Sex after open myomectomy',
          slug: 'sex-after-open-myomectomy',
        },
        { name: 'Erectile dysfunction', slug: 'erectile-dysfunction' },
        {
          name: 'Why is my wife not interested in sex',
          slug: 'why-is-my-wife-not-interested-in-sex',
        },
        { name: 'Mustard oil for penis', slug: 'mustard-oil-for-penis' },
        {
          name: 'Does masturbation prevent growth',
          slug: 'does-masturbation-prevent-growth',
        },
        {
          name: 'Hiv rna pcr test accuracy',
          slug: 'hiv-rna-pcr-test-accuracy',
        },
        {
          name: 'What is grade 1 prostate enlargement',
          slug: 'what-is-grade-1-prostate-enlargement',
        },
      ],
    },
    seoTags:
      'The field of sexology is related to diagnosis and treatments related to reproductive and sexual health of men. Generally, men consult a sexologist for problems like erectile dysfunction, penile deformation, infertility, premature ejaculation, nightfall, infertility (low sperm count}, impotence, testicular deformity or pain, paraphimosis (inability of foreskin to regain its natural position}, etc. Consultation with a sexologist is key in diagnosis of more serious conditions like testicular cancer. Therefore, it’s important that a consultation is sought as soon as you notice abnormality or unusual pain in the genitals. Another important scenario to consult a sexologist is when you are sexually active and often indulge in unprotected sex. An expert sexologist can diagnose, treat or better still, advise caution against prevention of sexual transmitted diseases like HIV, HPV, gonorrhoea, genital herpes, chlamydia, Hepatitis B and C. On Practo, you can book consultations with verified sexologists, who will help in providing reliable, safe and expert treatments. You can consult a sexologist with complete confidentiality, on Practo.',
  },
  'ask-a-gynecologist': {
    title:
      'Online Gynecologist Consultation 24X7 | Ask an Obstetrician Online | Practo',
    meta:
      'Consult with a Gynecologist Online and get advice for your gynec problems. Consult verified Gynecologists online only on Practo',
    heading: 'Consult with a Gynecologist',
    subHeading:
      'Gynecologists can provide advice for period problems, conceiving, pregnancy, breastfeeding, infertility and lots more',
    cta: 'Consult with a Gynecologist',
    problemAreaId: 16,
    howItWorks: {
      heading: 'How does online doctor consultation work?',
      paragraph: 'Connect with a doctor in 3 steps. Its that simple!',
      steps: [
        {
          img: 'chat-now.jpg',
          icon: '',
          heading: "Click on 'Consult with a Gynecologist'",
          description:
            'Start by clicking on the ‘Consult with a Gynecologist’ button above',
        },
        {
          img: 'complete-payment.jpg',
          icon: '',
          heading: 'Complete payment',
          description:
            'Pay and get connected with top doctors in your preferred specialty',
        },
        {
          img: 'doctor-chat.jpg',
          icon: '',
          heading: 'Start your doctor consultation',
          description:
            'Connect with a top doctor in 60 seconds for consultation, audio or video call.',
        },
      ],
    },
    concern: {
      heading: 'Concerns you can consult about',
      description: 'These are the most talked about concerns with gynecologist',
      topics: [
        {
          name: 'Primolut n dosage to delay periods',
          slug: 'primolut-n-dosage-to-delay-periods',
        },
        {
          name: 'Medicine to get periods immediately',
          slug: 'medicine-to-get-periods-immediately',
        },
        {
          name: 'Regestrone tablet dosage to delay periods',
          slug: 'regestrone-tablet-dosage-to-delay-periods',
        },
        {
          name: 'Bleeding after taking ipill means no pregnancy',
          slug: 'bleeding-after-taking-ipill-means-no-pregnancy',
        },
        {
          name: 'Weak positive pregnancy test',
          slug: 'weak-positive-pregnancy-test',
        },
        {
          name: 'How much delay in periods is normal',
          slug: 'how-much-delay-in-periods-is-normal',
        },
        {
          name: 'Periods after stopping duphaston',
          slug: 'periods-after-stopping-duphaston',
        },
        {
          name: 'Minimum sperm count for pregnancy',
          slug: 'minimum-sperm-count-for-pregnancy',
        },
        {
          name: 'Vaginal discharge smells like vinegar',
          slug: 'vaginal-discharge-smells-like-vinegar',
        },
        {
          name: 'Epithelial cells in urine during pregnancy',
          slug: 'epithelial-cells-in-urine-during-pregnancy',
        },
        {
          name: 'Unwanted kit for unwanted pregnancy',
          slug: 'unwanted-kit-for-unwanted-pregnancy',
        },
        {
          name: 'Regestrone tablets for irregular periods',
          slug: 'regestrone-tablets-for-irregular-periods',
        },
        {
          name: 'Primolut n dosage for irregular periods',
          slug: 'primolut-n-dosage-for-irregular-periods',
        },
        {
          name: 'Does unwanted 72 delay periods',
          slug: 'does-unwanted-72-delay-periods',
        },
        {
          name: 'Pregnancy stop tablets names',
          slug: 'pregnancy-stop-tablets-names',
        },
        {
          name: 'Ecosprin 75 during pregnancy',
          slug: 'ecosprin-75-during-pregnancy',
        },
        {
          name: 'Dual marker test in pregnancy',
          slug: 'dual-marker-test-in-pregnancy',
        },
      ],
    },
    seoTags:
      'Gynaecologists specialise in the field of women’s sexual and reproductive health. They are generally consulted for common conditions like irregular periods, missed periods, delayed periods, amenorrhoea, dysmenorrhoea, polycystic ovary syndrome (PCOD}, vaginal or uterine infections, and infertility. Gynaecologists also help in managing prenatal symptoms, pregnancy and post-natal care. Many women also seek gynaecological consultation for contraception (regular or emergency) and abortion (only within legal realm, since abortion based on predetermination of foetal sex is illegal and punishable according to Indian Penal Code). It’s advisable to seek expert guidance only from verified gynaecologists for problems related to women’s sexual health because self-medication is often counterproductive and harmful. If you need to consult a verified gynaecologist then you can search for them on Practo and get recommendations near you. Alternatively, you can also consult a gynaecologist privately or anonymously, on Practo’s online consultation service.',
  },
  'ask-a-general-physician-doctor': {
    title:
      'Ask A General Physician Online And Get Answers 24X7 | Ask A GP Free | Practo',
    meta:
      'Consult with a General Physician Online and get advice for your problems. Consult verified General Physician online only on Practo.',
    heading: 'Consult with a General Physician',
    subHeading:
      'General physicians can provide advice on routine healthcare like immunizations and treat multiple conditions, including injuries and illnesses',
    cta: 'Consult with a General Physician',
    problemAreaId: 22,
    howItWorks: {
      heading: 'How does online doctor consultation work?',
      paragraph: 'Connect with a doctor in 3 steps. Its that simple!',
      steps: [
        {
          img: 'chat-now.jpg',
          icon: '',
          heading: "Click on 'Consult with a General Physician'",
          description:
            'Start by clicking on the ‘Consult with a General Physician’ button above',
        },
        {
          img: 'complete-payment.jpg',
          icon: '',
          heading: 'Complete payment',
          description:
            'Pay and get connected with top doctors in your preferred specialty',
        },
        {
          img: 'doctor-chat.jpg',
          icon: '',
          heading: 'Start your doctor consultation',
          description:
            'Connect with a top doctor in 60 seconds for consultation, audio or video call.',
        },
      ],
    },
    concern: {
      heading: 'Concerns you can consult about',
      description:
        'These are the most talked about concerns with general physicians',
      topics: [
        { name: 'High Fever for Child', slug: 'fever-only-head-is-hot' },
        {
          name: 'How to reduce SGPT levels',
          slug: 'how-to-reduce-sgpt-levels',
        },
        {
          name: 'Weakness after viral fever',
          slug: 'weakness-after-viral-fever',
        },
        {
          name: 'Tetanus injection after injury',
          slug: 'tetanus-injection-after-injury',
        },
        {
          name: 'How to get rid of masturbation addiction',
          slug: 'how-to-get-rid-of-masturbation-addiction',
        },
        {
          name: 'How to get periods immediately home remedies',
          slug: 'how-to-get-periods-immediately-home-remedies',
        },
      ],
    },
    seoTags:
      'General physicians treat all common health issues, including infections, injuries, allergies, colds, flu, immunisation, blood pressure, stomach pains and lots more. You can also ask General physicians about major health conditions like diabetes, heart conditions and asthma. It’s advisable to seek expert guidance only from verified general physicians for your healthcare problems because self-medication is often counterproductive and harmful. If you need to consult a verified general physician near you, you can search on Practo and get recommendations. Alternatively, you can also consult a general physician privately or anonymously on Practo’s online consultation service. Through online consultation service, you can have a consultation with a general physician anytime, anywhere and ask about your health concerns.',
  },
  'ask-a-pediatrician-doctor': {
    title:
      'Ask A Pediatrician Doctor Online And Get Answers 24X7 | Ask A Child Specialist | Practo',
    meta:
      'Consult with a Pediatrician Doctor Online and get advice for your child heallth related problems. Consult verified Pediatrician online only on Practo',
    heading: 'Consult with a Pediatrician',
    subHeading: `Pediatricians can help with your child's health, including mental, behavioural and physical health issues`,
    cta: 'Consult with a Pediatrician',
    problemAreaId: 17,
    howItWorks: {
      heading: 'How does online doctor consultation work?',
      paragraph: 'Connect with a doctor in 3 steps. Its that simple!',
      steps: [
        {
          img: 'chat-now.jpg',
          icon: '',
          heading: "Click on 'Consult with a Pediatrician'",
          description:
            'Start by clicking on the ‘Consult with a Pediatrician’ button above',
        },
        {
          img: 'complete-payment.jpg',
          icon: '',
          heading: 'Complete payment',
          description:
            'Pay and get connected with top doctors in your preferred specialty',
        },
        {
          img: 'doctor-chat.jpg',
          icon: '',
          heading: 'Start your doctor consultation',
          description:
            'Connect with a top doctor in 60 seconds for consultation, audio or video call.',
        },
      ],
    },
    concern: {
      heading: 'Concerns you can consult about',
      description: 'These are the most talked about concerns with pediatrician',
      topics: [
        {
          name: 'Child skill development',
          slug: 'child-development-article-for-parents',
        },
        {
          name: 'Clear mucus discharge from anus',
          slug: 'clear-mucus-discharge-from-anus-in-children',
        },
        {
          name: 'Ibugesic plus syrup dosage for children',
          slug: 'ibugesic-plus-syrup-dosage-for-children',
        },
        {
          name: 'Fever and leg pain in children',
          slug: 'fever-and-leg-pain-in-children',
        },
        {
          name: 'Frequent hiccups in children',
          slug: 'frequent-hiccups-in-children',
        },
        {
          name: 'Head sweating while sleeping in children',
          slug: 'head-sweating-while-sleeping-in-children',
        },
      ],
    },
    seoTags:
      'Pediatricians are experts with special training in the illnesses and diseases that affect the development and health of babies, children and teenagers. They can also provide advice on children’s behaviour and welfare. You can ask a paediatrician about healthcare and development needs of your child and make choices accordingly. It’s important to seek guidance from verified pediatricians for your child’s health because self-medication is often counterproductive and harmful. If you need to consult a verified paediatrician near you, you can search on Practo and get recommendations. Alternatively, you can also consult a paediatrician privately or anonymously on Practo’s online consultation service. Through online consultation service, you can have a consultation with a paediatrician anytime, anywhere and ask about your concerns.',
  },
  'ask-an-ear-nose-throat-doctor': {
    title:
      'Ask An ENT Online And Get Answers 24X7 | Cure ENT Related Problems | Practo',
    meta:
      'Consult with an ENT Doctor Online and get advice for your ENT related problems. Consult verified ENT online only on Practo',
    heading: 'Consult with an ENT Doctor',
    subHeading:
      'ENT doctors are experts in surgical and medical management of your ears, nose, throat, head and neck conditions',
    cta: 'Consult with an ENT Doctor',
    problemAreaId: 3,
    howItWorks: {
      heading: 'How does online doctor consultation work?',
      paragraph: 'Connect with a doctor in 3 steps. Its that simple!',
      steps: [
        {
          img: 'chat-now.jpg',
          icon: '',
          heading: "Click on 'Consult with an ENT Doctor'",
          description:
            'Start by clicking on the ‘Consult with an ENT Doctor’ button above',
        },
        {
          img: 'complete-payment.jpg',
          icon: '',
          heading: 'Complete payment',
          description:
            'Pay and get connected with top doctors in your preferred specialty',
        },
        {
          img: 'doctor-chat.jpg',
          icon: '',
          heading: 'Start your doctor consultation',
          description:
            'Connect with a top doctor in 60 seconds for consultation, audio or video call.',
        },
      ],
    },
    concern: {
      heading: 'Concerns you can consult about',
      description: 'These are the most talked about concerns with ENT doctors',
      topics: [
        {
          name: 'How to remove cotton from ear',
          slug: 'how-to-remove-cotton-from-ear',
        },
        {
          name: 'Clear fluid discharge from anus',
          slug: 'clear-fluid-discharge-from-anus',
        },
        {
          name: 'Coughing up clear gel balls',
          slug: 'coughing-up-clear-gel-balls',
        },
        {
          name: 'Ear bleeding after cleaning',
          slug: 'ear-bleeding-after-cleaning-with-q-tip',
        },
        {
          name: 'I blew my nose and now my ear hurts',
          slug: 'i-blew-my-nose-and-now-my-ear-hurts',
        },
        {
          name: 'How to get rid of dark line on nose',
          slug: 'how-to-get-rid-of-dark-line-on-nose',
        },
      ],
    },
    seoTags:
      'ENT doctors deal with conditions that affect the nose, throat and ears. They also provide advice on the diseases that affect the head and neck. Medical conditions that an ENT doctor treats include deafness, ear infections, eardrum problems, sinusitis, nose bleeding, difficulty swallowing and hoarse voice. You can even ask an ENT doctor about the questions and concerns you may have about cosmetic surgeries. It’s advisable to seek guidance only from verified ENT doctors for your healthcare problems because self-medication is often counterproductive and harmful. If you need to consult a verified ENT doctor near you, you can search on Practo and get recommendations. Alternatively, you can also consult an ENT doctor privately or anonymously on Practo’s online consultation service. Through online consultation service, you can have a consultation with an ENT doctor anytime, anywhere and ask about your health concerns.',
  },
  'ask-a-kidney-urine-doctor': {
    title:
      'Ask A Kidney Urine Doctor Online And Get Answers 24X7 | Cure Kidney Problems  | Practo',
    meta:
      'Consult with a Kidney Urine Doctor Online and get advice for your Kidney Urine related problems. Consult verified Kidney Urine Doctor online only on Practo',
    heading: 'Consult with a Kidney Urine Doctor',
    subHeading:
      'Nephrologists can help treat kidney stones, kidney infections, kidney swelling and lots more',
    cta: 'Consult with a Nephrologist',
    problemAreaId: 9,
    howItWorks: {
      heading: 'How does online doctor consultation work?',
      paragraph: 'Connect with a doctor in 3 steps. Its that simple!',
      steps: [
        {
          img: 'chat-now.jpg',
          icon: '',
          heading: "Click on 'Consult with a Nephrologist'",
          description:
            'Start by clicking on the ‘Consult with a Nephrologist’ button above',
        },
        {
          img: 'complete-payment.jpg',
          icon: '',
          heading: 'Complete payment',
          description:
            'Pay and get connected with top doctors in your preferred specialty',
        },
        {
          img: 'doctor-chat.jpg',
          icon: '',
          heading: 'Start your doctor consultation',
          description:
            'Connect with a top doctor in 60 seconds for consultation, audio or video call.',
        },
      ],
    },
    concern: {
      heading: 'Concerns you can consult about',
      description: 'These are the most talked about concerns with nephrologist',
      topics: [
        {
          name: 'Pus cells range in urine',
          slug: 'pus-cells-range-in-urine',
        },
        {
          name: 'Dangerous creatinine level kidney failure',
          slug: 'dangerous-creatinine-level-kidney-failure',
        },
        {
          name: 'Kidney stones size chart',
          slug: 'kidney-stones-size-chart',
        },
        {
          name: 'What is pelvicalyceal system in kidney',
          slug: 'what-is-pelvicalyceal-system-in-kidney',
        },
        { name: 'Kidney pain after flu', slug: 'kidney-pain-after-flu' },
        {
          name: 'Pain in kidney when walking',
          slug: 'pain-in-kidney-when-walking',
        },
      ],
    },
    seoTags:
      'Nephrologists specialise in the diagnosis and treatment of diseases that affect the kidneys. You can ask a Nephrologist about kidney infections, kidney vascular diseases, tubular defects, abnormal growths, autoimmune conditions, some metabolic disorders and lots more. Nephrologists also focus on the impact of kidney dysfunction on the rest of the body. It’s important to seek guidance only from verified nephrologists for your healthcare problems because self-medication is often counterproductive and harmful. If you need to consult a verified nephrologist near you, you can search on Practo and get recommendations. Alternatively, you can also consult a nephrologist privately or anonymously on Practo’s online consultation service. Through online consultation service, you can have a consultation with a nephrologist anytime, anywhere and ask about your health concerns.',
  },
  'ask-an-orthopedic-doctor': {
    title:
      'Ask An Orthopedic Doctor And Get Answers 24X7 | Ask A Bone Doctor | Practo',
    meta:
      'Consult with an Orthopedic Doctor Online and get advice for your Orthopedic related problems. Consult verified Orthopedic Doctor online only on Practo',
    heading: 'Consult with an Orthopedic Doctor',
    subHeading:
      'Orthopedic doctors can provide advice on bone and joint conditions, including osteoporosis and arthritis',
    cta: 'Consult with an Orthopedic',
    problemAreaId: 13,
    howItWorks: {
      heading: 'How does online doctor consultation work?',
      paragraph: 'Connect with a doctor in 3 steps. Its that simple!',
      steps: [
        {
          img: 'chat-now.jpg',
          icon: '',
          heading: "Click on 'Consult with an Orthopedic'",
          description:
            'Start by clicking on the ‘Consult with an Orthopedic’ button above',
        },
        {
          img: 'complete-payment.jpg',
          icon: '',
          heading: 'Complete payment',
          description:
            'Pay and get connected with top doctors in your preferred specialty',
        },
        {
          img: 'doctor-chat.jpg',
          icon: '',
          heading: 'Start your doctor consultation',
          description:
            'Connect with a top doctor in 60 seconds for consultation, audio or video call.',
        },
      ],
    },
    concern: {
      heading: 'Concerns you can consult about',
      description: 'These are the most talked about concerns with orthopedic',
      topics: [
        {
          name: 'Lump on collar bone near throat',
          slug: 'lump-on-collar-bone-near-throat',
        },
        { name: 'Pelvic bone sticks out', slug: 'pelvic-bone-sticks-out' },
        {
          name: 'Itching inside knee joint',
          slug: 'itching-inside-knee-joint',
        },
        {
          name: 'Ankle swelling after knee injury',
          slug: 'ankle-swelling-after-knee-injury',
        },
        {
          name: 'Femur fracture recovery time',
          slug: 'femur-fracture-recovery-time',
        },
        { name: 'Pimple on hips with pain', slug: 'pimple-on-hips-with-pain' },
      ],
    },
    seoTags:
      'Orthopedic doctors specialise in the diagnosis, treatment and prevention of disorders of bones, ligaments, joints, muscles and tendons. An orthopedic doctor treats broken bones, sports injuries, back pain, ruptured discs, bone tumors, hand arthritis, hand injuries, club foot, hip dysplasia, osteoporosis, orthopedic trauma, arthritis and lots more. You can ask an orthopedic doctor about the hip, knee, ankle, shoulder, elbow, hand and spine injuries or problems. It’s advisable to seek guidance only from verified orthopedic doctors for your healthcare problems because self-medication is often counterproductive and harmful. If you need to consult a verified orthopedic doctor near you, you can search on Practo and get recommendations. Alternatively, you can also consult an orthopedic doctor privately or anonymously on Practo’s online consultation service. Through online consultation service, you can have a consultation with an orthopedic doctor anytime, anywhere and ask about your health concerns.',
  },
  'ask-a-neurologist-doctor': {
    title:
      'Ask A Neurologist Online And Get Answers 24X7 | Cure Neuro Problems | Practo',
    meta:
      'Consult with a Neurologist Doctor Online and get advice for your Neuro related problems. Consult verified Neurologist online only on Practo',
    heading: 'Consult with a Neurologist Doctor',
    subHeading:
      'Neurologists can give advice on headaches, dizziness, tingling or numbness, movement problems, sleep problems and lots more',
    cta: 'Consult with a Neurologist',
    problemAreaId: 1,
    howItWorks: {
      heading: 'How does online doctor consultation work?',
      paragraph: 'Connect with a doctor in 3 steps. Its that simple!',
      steps: [
        {
          img: 'chat-now.jpg',
          icon: '',
          heading: "Click on 'Consult with a Neurologist'",
          description:
            'Start by clicking on the ‘Consult with a Neurologist’ button above',
        },
        {
          img: 'complete-payment.jpg',
          icon: '',
          heading: 'Complete payment',
          description:
            'Pay and get connected with top doctors in your preferred specialty',
        },
        {
          img: 'doctor-chat.jpg',
          icon: '',
          heading: 'Start your doctor consultation',
          description:
            'Connect with a top doctor in 60 seconds for consultation, audio or video call.',
        },
      ],
    },
    concern: {
      heading: 'Concerns you can consult about',
      description: 'These are the most talked about concerns with neurologist',
      topics: [
        {
          name: 'Neurobion forte side effects',
          slug: 'neurobion-forte-side-effects',
        },
        {
          name: 'Diagnosis of neurogenic bladder',
          slug: 'diagnosis-of-neurogenic-bladder',
        },
        {
          name: 'Motor neuron disease causes',
          slug: 'motor-neuron-disease-causes',
        },
        { name: 'Nerve pain in testicle', slug: 'nerve-pain-in-testicle' },
        {
          name: 'Nerve weakness home remedy',
          slug: 'nerve-weakness-home-remedy',
        },
        { name: 'Nerve pulling in foot', slug: 'nerve-pulling-in-foot' },
      ],
    },
    seoTags:
      'Neurologists specialise in the treatment and diagnosis of conditions and disorders that affect the brain, nerves and spinal cord. A neurologist treats stroke, multiple sclerosis, headache disorders, movement disorders, seizure disorders, spinal cord disorders, speech and language disorders and lots more. You can ask a neurologist about such problems and the related symptoms in detail. It’s important to seek guidance only from verified neurologists for your healthcare problems because self-medication is often counterproductive and harmful. If you need to consult a verified neurologist near you, you can search on Practo and get recommendations. Alternatively, you can also consult a neurologist privately or anonymously on Practo’s online consultation service. Through online consultation service, you can have a consultation with a neurologist anytime, anywhere and ask about your health concerns.',
  },
};
