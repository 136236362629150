import urlConstants from '../constants/urlConstants.js';

export function getImageUrl(imageName) {
  return urlConstants.WEBSITE_ASSETS_PREFIX + '/images/' + imageName;
}

export function getAppSvgUrl(app) {
  return urlConstants.WEBSITE_ASSETS_PREFIX + '/images/' + app + '.svg';
}

export const getMicroAppScript = app => {
  return urlConstants.WEBSITE_ASSETS_PREFIX + '/js/microapp/' + app + '.js';
};
