import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import SpecialityCardsContainer from './speciality-cards/SpecialityCardsContainer';
import NewConsultHeader from './new-consult-header/NewConsultHeader.js';
import {
  selectSpeciality,
  setAreAllSpecialitiesVisible,
} from '../../redux/ducks/new-consult/newConsult.js';
import './specialities-section.scss';

export default function SpecialitiesSection({
  redirectToPayment,
  trackProblemInteracted,
}) {
  const dispatch = useDispatch();
  const { areAllSpecialitiesVisible } = useSelector(
    state => ({
      areAllSpecialitiesVisible: state.newConsult.areAllSpecialitiesVisible,
    }),
    shallowEqual
  );

  return (
    <section className="spec-section">
      <NewConsultHeader
        title="Know Speciality ?"
        subtitle="Select from top specialities"
      />
      <SpecialityCardsContainer
        limit={areAllSpecialitiesVisible ? undefined : 4}
        onClick={({ id }) => {
          trackProblemInteracted({
            'Interaction Type': 'Speciality',
            'Problem Area Id': id,
          });
          dispatch(selectSpeciality(id));
          redirectToPayment();
        }}
      />
      {!areAllSpecialitiesVisible && (
        <div className="spec-section__view-more__wrap">
          <div
            className="spec-section__view-more__button"
            onClick={() => {
              trackProblemInteracted({
                'Interaction Type': 'View All Specialities',
              });
              dispatch(setAreAllSpecialitiesVisible(true));
            }}
          >
            View All Specialities
          </div>
        </div>
      )}
    </section>
  );
}

SpecialitiesSection.propTypes = {
  redirectToPayment: PropTypes.func.isRequired,
  trackProblemInteracted: PropTypes.func.isRequired,
};
